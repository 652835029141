import {
  map, prop, compose, props, isEmpty,
} from 'ramda';
import { format } from 'date-fns';

import { columns } from 'components/Financials/Table/Table';

const getStatus = (status) => {
  switch (status) {
    case 'PAYMENT_CLEARED':
      return 'Paid';
    case 'PAYMENT_RETURNED':
      return 'Returned';
    case 'PAYMENT_FAILED':
      return 'Failed';
    case 'PENDING':
    case 'READY_TO_PROCESS':
    case 'PAYMENT_SENT_TO_PROCESSOR':
      return 'Pending';
    default:
      return null;
  }
};

const formatDate = (date) => format(new Date(date), 'MM/dd/yyyy');

const columnsAccessors = map(prop('accessor'))(columns);

const transformProviderPayments = map((item) => ({
  ...item,
  createdAt: compose(formatDate, prop('createdAt'))(item),
  confirmationCode: prop('transactionId', item),
  toBankAccount: prop('toAccount', item),
  fromBankAccount: prop('fromAccount', item),
  receivablesCount: prop('noOfPlans', item),
  status: compose(getStatus, prop('status'))(item),
}));

const columnsNames = map(prop('Header'))(columns);

const getCSVData = (providerPayments) => {
  const newProviderPayments = transformProviderPayments(providerPayments);
  const arrayProviderPayments = map(props(columnsAccessors))(newProviderPayments);
  if (isEmpty(arrayProviderPayments)) return [];
  return [columnsNames, ...arrayProviderPayments];
};

export default getCSVData;
