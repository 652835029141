import { authenticationService, httpMethods, apiUrls } from 'libs';

const { apiCall } = authenticationService;
const { GET, POST } = httpMethods;
const {
  SEARCH_PATIENTS, GET_OFFER_DATA, UPDATE_MRN, CONFIRM_OFFER,
} = apiUrls;

export const reqSearchPatients = ({
  providerId,
  organizationId,
  params,
}) => apiCall(GET, SEARCH_PATIENTS(providerId, organizationId), { params });

export const reqGetOfferData = ({ params }) => apiCall(GET, GET_OFFER_DATA, { params });

export const reqUpdateMrn = (data) => apiCall(POST, UPDATE_MRN, { data });

export const reqConfirmOffer = ({ providerId, offerId, ...data }) =>
  apiCall(POST, CONFIRM_OFFER({ providerId, offerId }), { data });
