import { connect } from 'react-redux';

import { getOfferData, updateMrn } from 'actions/patients';
import { startSpinner, stopSpinner } from 'actions/spinner';

import PatientInfo from './PatientInfo';

const mapStateToProps = ({
  providerForm: {
    bills,
    offer,
    patient,
    user,
    provider,
    patientDocuments,
    providerDocuments,
    billFilesWithUrls,
  },
}) => ({
  bills,
  offer,
  patient,
  user,
  provider,
  patientDocuments,
  providerDocuments,
  billFilesWithUrls,
});

const mapDispatchToProps = {
  getOfferData,
  updateMrn,
  startSpinner,
  stopSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientInfo);
