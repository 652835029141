import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import {
  prop, compose, head, path, omit, equals, pathOr, keys,
} from 'ramda';
import { toast } from 'react-toastify';

import Layout from 'components/Layout';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { Input } from 'components/Fields';
import ProviderFormWrapper from 'components/ProviderFormWrapper';

import { billApprovalForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';

const BillApproval = ({
  startSpinner,
  stopSpinner,
  confirmOffer,
  provider,
  offer,
  providerDocuments,
  patientDocuments,
  paymentMethod,
}) => {
  const history = useHistory();
  const [paymentMethodValidation, setPaymentMethodValidation] = useState('');
  const providerName = prop('providerName')(provider);

  const paymentAgreementDoc = patientDocuments
    .find((d) => d.documentType === 'PAYMENT_PLAN_AGREEMENT');

  const servicingAgreementDoc = providerDocuments
    .find((d) => d.documentType === 'PROVIDER_RECEIVABLE_SERVICING_AGREEMENT');

  const initialValues = {
    contactName: pathOr('', ['contactName'])(provider),
    agree: false,
    paymentMethod: compose(head, keys)(paymentMethod) || 'check',
    ach: {
      accountHolderName: pathOr('', ['ach', 'accountHolderName'])(paymentMethod),
      routingNumber: pathOr('', ['ach', 'routingNumber'])(paymentMethod),
      accountNumber: pathOr('', ['ach', 'accountNumber'])(paymentMethod),
    },
    check: {
      accountHolderName: pathOr('', ['check', 'accountHolderName'])(paymentMethod),
      line1: pathOr('', ['check', 'line1'])(paymentMethod),
      line2: pathOr('', ['check', 'line2'])(paymentMethod),
      locality: pathOr('', ['check', 'locality'])(paymentMethod),
      region: pathOr('', ['check', 'region'])(paymentMethod),
      postalCode: pathOr('', ['check', 'postalCode'])(paymentMethod),
    },
    phone: '',
  };

  const onSubmitSuccess = () => {
    stopSpinner();
    history.push('/provider-form/success');
  };

  const onSubmitFailure = (error) => {
    stopSpinner();
    const message = getErrorMessage(error);
    toast.error(message);
  };

  const onSubmit = async (data) => {
    const paymentMethod = prop('paymentMethod')(data);
    const phone = prop('phone')(data);
    const line2 = path(['check', 'line2'])(data);
    const providerId = prop('providerId')(provider);
    const offerId = prop('offerId')(offer);
    const isCheck = equals(paymentMethod, 'check');

    const newData = isCheck
      ? omit(['ach', 'paymentMethod', 'agree', 'phone'])(data)
      : omit(['check', 'paymentMethod', 'agree', 'phone'])(data);

    const dataPaymentMethod = prop('paymentMethod')(data);

    const dataHasChange = !equals(initialValues[dataPaymentMethod], data[dataPaymentMethod]);

    const line2info = `${line2 || ''} ${phone || ''}`;

    const formData = isCheck
      ? { ...newData, check: { ...newData.check, line2: line2info } }
      : newData;

    const objData = dataHasChange ? formData : omit([dataPaymentMethod])(formData);

    startSpinner();

    confirmOffer({ providerId, offerId, ...objData })
      .then(onSubmitSuccess)
      .catch(onSubmitFailure);
  };

  const hackUseEffect = useEffect;

  const goBack = () => history.push(`/provider-form/patient-info${history.location.search}`);

  return (
    <Layout noLoggedIn providerFrom>
      <ProviderFormWrapper>
        <div className="provider-payment-plan w-100">
          <div className="wrapper">
            <div className="text-center mb-6 position-relative">
              <Button
                type="link"
                onClick={goBack}
                className="position-absolute d-flex align-items-center text-decoration-none provider-payment-plan__back"
              >
                <Icon name="caret-left" className="text-dodger-blue text-larger" />
                <span className="ml-3">Back</span>
              </Button>
              <h1 className="h4 text-tuna mb-5">PayZen Payment Plan Approval</h1>
              <div className="provider-payment-plan__steps text-uppercase text-smaller text-jumbo font-weight-semibold d-flex justify-content-between mx-auto">
                <span>1. Patient info</span>
                <span className="is-active">2. Bill Approval</span>
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={billApprovalForm(paymentMethodValidation)}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ isSubmitting, values }) => {
                hackUseEffect(() => {
                  setPaymentMethodValidation(values.paymentMethod);
                }, [values.paymentMethod]);

                return (
                  <Form>
                    <div>
                      <h2 className="h4 font-weight-semibold text-comet mb-6">2. Bill Approval</h2>
                      <div className="media mb-6">
                        <Icon name="check-approval" className="text-large text-jumbo mr-2" />
                        <div className="media-body">
                          <h3 className="text-large font-weight-light mb-4">Approval</h3>
                          <div className="row smaller-gutters mb-3">
                            <div className="col-8">
                              <Field id="contactName" type="text" name="contactName" label="Contact Full Name" component={Input} />
                            </div>
                          </div>
                          <div className="media">
                            <Field id="agree" type="checkbox" name="agree" component={Input} className="mr-1" />
                            <div className="media-body">
                              <p className="text-small text-trout mb-0">
                                {`I agree to the terms below and confirm ${providerName} will suspend collection efforts and credit reporting as long as payments are received.`}
                              </p>
                              {/* <p className="text-small text-trout mb-0">[for factoring]  I agree to the terms above and confirm [Provider name] will mark this patient balance as paid in full.</p> */}
                              {
                                servicingAgreementDoc ? (
                                  <a
                                    // eslint-disable-next-line react/jsx-no-target-blank
                                    target="_blank"
                                    href={servicingAgreementDoc.url}
                                    className="text-decoration-none"
                                  >
                                    Patient payment plan receivable servicing agreement
                                  </a>
                                ) : null
                              }
                              <br />
                              {
                                paymentAgreementDoc ? (
                                  <a
                                    // eslint-disable-next-line react/jsx-no-target-blank
                                    target="_blank"
                                    href={paymentAgreementDoc.url}
                                    className="text-decoration-none"
                                  >
                                    Payment plan agreement
                                  </a>
                                ) : null
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="media mb-8">
                        <Icon name="get-paid" className="text-large text-jumbo mr-2" />
                        <div className="media-body">
                          <h3 className="text-large font-weight-light mb-4">How do you want to get paid?</h3>
                          <div className="row smaller-gutters mb-5">
                            <div className="col-4">
                              <div className="provider-payment-plan__checkbox text-center h-100">
                                <Field id="check" type="radio" name="paymentMethod" value="check" className="radio" />
                                <label htmlFor="check" className="d-flex flex-column align-items-center mb-0 h-100">
                                  <Icon name="paper-check" className="icon mt-auto" />
                                  <span className="text-large">Paper Check</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="provider-payment-plan__checkbox text-center h-100">
                                <Field id="ach" type="radio" name="paymentMethod" value="ach" className="radio" />
                                <label htmlFor="ach" className="d-flex flex-column align-items-center mb-0 h-100">
                                  <Icon name="e-payment" className="icon mb-1" />
                                  <span className="text-large">Electronic Payment</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <p className="text-small text-black font-weight-light mb-2">More info:</p>
                          {values.paymentMethod === 'check' ? (
                            <div className="row smaller-gutters">
                              <div className="col-4">
                                <Field id="check.accountHolderName" type="text" name="check.accountHolderName" label="Account Holder Name" component={Input} />
                              </div>
                              <div className="col-4">
                                <Field id="check.line1" type="text" name="check.line1" label="Address" component={Input} />
                              </div>
                              <div className="col-4">
                                <Field id="check.locality" type="text" name="check.locality" label="City" component={Input} />
                              </div>
                              <div className="col-4">
                                <Field id="check.region" type="text" name="check.region" label="State" component={Input} />
                              </div>
                              <div className="col-4">
                                <Field id="check.postalCode" type="text" name="check.postalCode" label="Zip" component={Input} />
                              </div>
                              <div className="col-4">
                                <Field id="check.line2" type="text" name="check.line2" label="Reference Number" component={Input} />
                              </div>
                              <div className="col-4">
                                <Field id="phone" type="text" name="phone" label="Phone Number" component={Input} />
                              </div>
                            </div>
                          ) : (
                            <div className="row smaller-gutters">
                              <div className="col-4">
                                <Field id="ach.accountHolderName" type="text" name="ach.accountHolderName" label="Account Holder Name" component={Input} />
                              </div>
                              <div className="col-4">
                                <Field id="ach.routingNumber" type="text" name="ach.routingNumber" label="Routing Number" component={Input} />
                              </div>
                              <div className="col-4">
                                <Field id="ach.accountNumber" type="text" name="ach.accountNumber" label="Account Number" component={Input} />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="provider-payment-plan__button mx-auto">
                        <Button
                          type="primary"
                          className="btn-block"
                          isSubmit
                          disabled={isSubmitting || !values.agree}
                        >
                          Approve
                        </Button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </ProviderFormWrapper>
    </Layout>
  );
};

export default BillApproval;
