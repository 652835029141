import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  compose,
  map,
  isEmpty,
  isNil,
  fromPairs,
  equals,
  toPairs,
  pick,
} from 'ramda';
import queryString from 'qs';
import {
  format,
  parse,
  startOfYear,
} from 'date-fns';
import Select, { createFilter } from 'react-select';

import { formatDate, parseDate } from 'utils/parseAndFormatDates';
import getProviderId from 'utils/getProviderId';
import getOrganizationId from 'utils/getOrganizationId';

import Layout from 'components/Layout';
import Icon from 'components/Icon';
import LineChart from 'components/Reports/LineChart';
import PieChart from 'components/Reports/PieChart';
import DownloadLink from 'components/DownloadLink';

import iconPatients from 'assets/images/provider/icn_patients.svg';
import iconSheet from 'assets/images/provider/icn_sheet.svg';
import iconFinancials from 'assets/images/provider/icn_fiancials.svg';
import { providerSelectCustomStyles, providerSelectFilterConfig } from '../PatientsSearch/PatientsSearch.config';

const DEFAULT_PARAMS = Object.freeze({
  fromDate: format(startOfYear(new Date()), 'MM-dd-yyyy'),
  toDate: format(new Date(), 'MM-dd-yyyy'),
});

// Firefox do not allow 'MM-dd-yyyy' as the input for Date constructor
const parseDateStr = (date) => parse(date, 'MM-dd-yyyy', new Date());

const Reports = ({
  getProviderReports,
  downloadProviderPaymentsCSV,
  patientCount,
  totalAmountEnrolled,
  totalAmountPreFunded,
  trendCategories,
  trendData,
  csvStatus,
  email,
  startSpinner,
  stopSpinner,
  providers,
  adminGetProviders,
}) => {
  const history = useHistory();
  const [params, setParams] = useState({
    ...DEFAULT_PARAMS,
    ...queryString.parse(history.location.search, { ignoreQueryPrefix: true }),
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [providerId, setProviderId] = useState(getProviderId());
  const from = parseDateStr(params.fromDate);
  const to = parseDateStr(params.toDate);
  const modifiers = { start: from, end: to };
  const FORMAT = 'MMM dd, yyy';
  const yieldValue = Number.isNaN(totalAmountPreFunded / totalAmountEnrolled)
    ? 0 : Math.ceil((100 * totalAmountPreFunded) / totalAmountEnrolled);
  const defaultProviderId = sessionStorage.getItem('DEFAULT_PROVIDER_ID');
  const organizationId = defaultProviderId === providerId ? getOrganizationId() : undefined;

  const fetchData = (data) => {
    startSpinner();
    getProviderReports(data)
      .then(stopSpinner)
      .catch(stopSpinner);
  };

  const clearDefaultQueryParams = compose(
    fromPairs,
    map(([key, value]) => (equals(value, DEFAULT_PARAMS[key]) ? [] : [key, value])),
    toPairs,
  );

  const updateParams = (key, value) => {
    const newParams = pick(['fromDate', 'toDate'], {
      ...params,
      [key]: value,
    });

    if (equals(params, newParams)) {
      return null;
    }

    const queryParams = queryString.stringify(clearDefaultQueryParams(newParams));
    history.push(`/admin/reports?${queryParams}`);
    setParams(newParams);

    return fetchData({ providerId, params: newParams });
  };

  const handleFromChange = (fromDate) => {
    updateParams('fromDate', format(fromDate, 'MM-dd-yyyy'));
  };

  const handleToChange = (toDate) => {
    updateParams('toDate', format(toDate, 'MM-dd-yyyy'));
  };

  const downloadAction = () => downloadProviderPaymentsCSV({
    providerId,
    organizationId,
    params,
  });

  useEffect(() => {
    if (!isEmpty(trendData) && !isLoaded) {
      setIsLoaded(true);
    }
  }, [trendData]);

  useEffect(() => {
    if (!isNil(email) && providerId) {
      fetchData({
        providerId,
        organizationId,
        params,
      });
    }
  }, [providerId, email]);

  useEffect(() => {
    startSpinner();
    adminGetProviders().finally(stopSpinner);
  }, []);

  const fromDate = formatDate(parseDateStr(params.fromDate), 'MM/dd/yyyy');
  const toDate = formatDate(parseDateStr(params.toDate), 'MM/dd/yyyy');
  const csvFileName = `Financial Report from ${fromDate} to ${toDate}.csv`;
  const [provider, setProvider] = useState((providers.length && providers[0]) || null);

  useEffect(() => {
    if (provider && provider.providerId) {
      sessionStorage.setItem('PROVIDER_ID', provider.providerId);
      setProviderId(provider.providerId);
    }
  }, [provider]);

  return (
    <Layout>
      <div className="reports">
        <div className="text-center mb-4 pb-1">
          <h1 className="h4 text-tuna mb-4">Reports</h1>
          <p className="text-small text-comet mb-0">
            Here is a snapshot of the program performance.
            You can also download the full CSV with more granular data.
          </p>
        </div>
        <div className="row align-items-center mb-7">
          <div className="col-3">
            <Select
              value={provider}
              onChange={setProvider}
              options={providers}
              getOptionLabel={(option) => option.providerName}
              getOptionValue={(option) => option.providerId}
              styles={providerSelectCustomStyles}
              filterOption={createFilter(providerSelectFilterConfig)}
            />
          </div>
          <div className="col-6 text-center text-small">
            <div className="InputFromTo">
              <span className="text-tuna text-small">From</span>
              <DayPickerInput
                classNames={{
                  container: 'DayPickerInput d-inline-block w-auto mx-2',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper',
                  overlay: 'DayPickerInput-Overlay',
                }}
                inputProps={{
                  className: 'btn btn-sm btn-pattens-blue text-sapphire text-smaller text-uppercase',
                  style: { width: '115px' },
                  readOnly: true,
                }}
                value={from}
                placeholder="From"
                format={FORMAT}
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { after: to },
                  toMonth: to,
                  modifiers,
                  numberOfMonths: 1,
                }}
                onDayChange={handleFromChange}
              />
              <span className="text-tuna text-small">To</span>
              <span className="InputFromTo-to">
                <DayPickerInput
                  classNames={{
                    container: 'DayPickerInput d-inline-block w-auto mx-2',
                    overlayWrapper: 'DayPickerInput-OverlayWrapper',
                    overlay: 'DayPickerInput-Overlay',
                  }}
                  inputProps={{
                    className: 'btn btn-sm btn-pattens-blue text-sapphire text-smaller text-uppercase',
                    style: { width: '115px' },
                    readOnly: true,
                  }}
                  value={to}
                  placeholder="To"
                  format={FORMAT}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from, after: new Date() },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 1,
                  }}
                  onDayChange={handleToChange}
                />
              </span>
            </div>
          </div>
          {!!yieldValue && (
            <div className="col-3 text-right">
              <DownloadLink
                downloadAction={downloadAction}
                disabled={csvStatus === 'pending'}
                filename={csvFileName}
                className="btn btn-sm btn-dodger-blue text-small text-decoration-none"
              >
                <Icon name="doc" className="align-baseline mr-2" />
                Download CSV
              </DownloadLink>
            </div>
          )}
        </div>
        <div>
          <div className="row small-gutters mb-4">
            <div className="col-4">
              <div className="card card-shadow card-rounded">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="mr-6">
                      <img src={iconPatients} alt="" />
                    </div>
                    <div className="media-body">
                      <NumberFormat
                        value={patientCount}
                        decimalScale={2}
                        displayType="text"
                        thousandSeparator
                        className="text-tuna text-xlarger line-height-1 mb-1"
                      />
                      <br />
                      <span className="text-jumbo text-smaller text-uppercase letter-spacing">Total Patients Enrolled</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="card card-shadow card-rounded">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="mr-6">
                      <img src={iconSheet} alt="" />
                    </div>
                    <div className="media-body">
                      <NumberFormat
                        value={totalAmountEnrolled / 100}
                        decimalScale={2}
                        displayType="text"
                        prefix="$"
                        thousandSeparator
                        className="text-tuna text-xlarger line-height-1 mb-1"
                      />
                      <br />
                      <span className="text-jumbo text-smaller text-uppercase letter-spacing">Total Amount Enrolled</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="card card-shadow card-rounded">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="mr-6">
                      <img src={iconFinancials} alt="" />
                    </div>
                    <div className="media-body">
                      <NumberFormat
                        value={totalAmountPreFunded / 100}
                        decimalScale={2}
                        displayType="text"
                        prefix="$"
                        thousandSeparator
                        className="text-tuna text-xlarger line-height-1 mb-1"
                      />
                      <br />
                      <span className="text-jumbo text-smaller text-uppercase letter-spacing">Total Amount Pre-Funded</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row small-gutters">
            <div className="col-6">
              <div className="card card-shadow card-rounded h-100">
                <div className="card-body d-flex flex-column">
                  <div className="flex-fill d-flex">
                    <LineChart
                      categories={trendCategories}
                      data={trendData}
                    />
                  </div>
                  <div className="text-center">
                    <span className="text-jumbo text-smaller text-uppercase letter-spacing">Enrolled Patients - WEEKLY TREND</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card card-shadow card-rounded h-100">
                <div className="card-body">
                  <PieChart value={[yieldValue]} />
                  <div className="text-center">
                    <span className="text-jumbo text-smaller text-uppercase letter-spacing">Yield</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Reports;
