import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import Icon from 'components/Icon';
import isPayzenAdminSite from 'utils/isPayzenAdminSite';

import avatar from 'assets/images/payzen_icon_all-white-blue-bg.jpg';
import logoWhite from 'assets/images/provider/logo_white.svg';

const Navigation = ({
  getManualReleaseAutomationId,
  isLoggedIn,
  manualReleaseAutomationId,
  providerId,
  providerFrom,
  role,
  startSpinner,
  stopSpinner,
}) => {
  const [showContantInfo, setShowContantInfo] = useState(false);
  const hasManualRelease = Boolean(manualReleaseAutomationId);

  useEffect(() => {
    if (providerId) {
      startSpinner();
      getManualReleaseAutomationId({ providerId }).finally(stopSpinner);
    }
  }, [providerId]);

  return (
    <div className="provider-navigation d-flex flex-column">
      <div className="provider-navigation__item bg-dodger-blue text-white">
        <img src={logoWhite} alt="" />
        <span className="font-weight-bold">
          Provider 360
          <sup>TM</sup>
        </span>
      </div>
      {isLoggedIn && !isPayzenAdminSite && (
        <>
          {role !== 'ADVOCATE' && (
            <NavLink className="provider-navigation__item" to="/data-exchange" activeClassName="is-active">
              <Icon name="exchange" className="h1" />
              <span>DATA EXCHANGE</span>
            </NavLink>
          )}
          {role === 'ADMIN' && (
            <NavLink className="provider-navigation__item" to="/invites" activeClassName="is-active">
              <Icon name="add-patient" className="h1" />
              <span>INVITE</span>
            </NavLink>
          )}
          <NavLink className="provider-navigation__item" to="/patients-search" activeClassName="is-active">
            <Icon name="patients" className="h1" />
            <span>SEARCH</span>
          </NavLink>
          {hasManualRelease && (
            <NavLink className="provider-navigation__item" to="/messaging-centre" activeClassName="is-active">
              <Icon name="exchange" className="h1" />
              <span>MESSAGE</span>
            </NavLink>
          )}
          {role !== 'ADVOCATE' && (
            <NavLink className="provider-navigation__item" to="/reports" activeClassName="is-active">
              <Icon name="reports" className="h1" />
              <span>REPORTS</span>
            </NavLink>
          )}
          {['ADMIN', 'MANAGER'].includes(role) && (
            <NavLink className="provider-navigation__item" to="/financials" activeClassName="is-active">
              <Icon name="financials" className="h1" />
              <span>FINANCIALS</span>
            </NavLink>
          )}
        </>
      )}
      {isLoggedIn && isPayzenAdminSite && (
        <>
          <NavLink className="provider-navigation__item" to="/admin" activeClassName="is-active" exact>
            <Icon name="padlock" className="h1" />
            <span>Home</span>
          </NavLink>
          <NavLink className="provider-navigation__item" to="/admin/patients-search" activeClassName="is-active">
            <Icon name="magnifying-glass" className="h1" />
            <span>Patients</span>
          </NavLink>
          <NavLink className="provider-navigation__item" to="/admin/reports" activeClassName="is-active">
            <Icon name="reports" className="h1" />
            <span>REPORTS</span>
          </NavLink>
          <NavLink className="provider-navigation__item" to="/admin/financial" activeClassName="is-active">
            <Icon name="financials" className="h1" />
            <span>FINANCIAL</span>
          </NavLink>
          <NavLink className="provider-navigation__item" to="/admin/create-users" activeClassName="is-active">
            <Icon name="add-patient" className="h1" />
            <span>USERS</span>
          </NavLink>
          <NavLink className="provider-navigation__item" to="/admin/vault-users" activeClassName="is-active">
            <Icon name="magnifying-glass" className="h1" />
            <span>VAULT</span>
          </NavLink>
          {hasManualRelease && (
            <NavLink className="provider-navigation__item" to="/messaging-centre" activeClassName="is-active">
              <Icon name="exchange" className="h1" />
              <span>MESSAGE</span>
            </NavLink>
          )}
        </>
      )}
      {!providerFrom && (
        <div className="mt-auto p-3">
          <div className={`provider-navigation__avatar bg-white ${showContantInfo ? 'is-open' : ''}`}>
            <div className="media align-items-center h-100">
              <button
                type="button"
                onClick={() => setShowContantInfo(true)}
                className="clickable border-0 bg-transparent p-0 provider-navigation__avatar__btn"
              >
                <img src={avatar} alt="" className="provider-navigation__avatar__img" />
              </button>
              {showContantInfo && (
                <div className="media-body letter-spacing pl-4">
                  <Icon
                    name="circle-back"
                    className="provider-navigation__avatar__close text-xlarger text-spun-pearl clickable"
                    onClick={() => setShowContantInfo(false)}
                  />
                  <p className="text-tuna mb-1 line-height-1">
                    Account Manager: Becky Kinsella
                  </p>
                  <p className="text-small mb-0 line-height-0">
                    <a href="tel:8888497647" className="text-decoration-none">
                      888-849-7647
                    </a>
                    {' '}
                    <span className="text-spun-pearl">|</span>
                    {' '}
                    <a href="mailto:support@payzen.com" className="text-decoration-none">support@payzen.com</a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Navigation.propTypes = {
  isLoggedIn: PropTypes.bool,
  providerFrom: PropTypes.bool,
};

Navigation.defaultProps = {
  isLoggedIn: false,
  providerFrom: false,
};

export default Navigation;
