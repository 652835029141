import React, { useEffect, useState } from 'react';
import {
  complement,
  compose,
  filter,
  head,
  isEmpty,
  path,
  pathOr,
  prop,
} from 'ramda';
import { toast } from 'react-toastify';

import Layout from 'components/Layout';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Table from 'components/MessagingCentre/Table/Table';

const alertError = {
  type: 'error',
  message: 'Fetching the messaging users failed. Please try again.',
};

const renderIcon = (type) => {
  switch (type) {
    case 'info':
      return 'circle-check-outline';
    case 'error':
      return 'circle-warning-outline';
    default:
      return '';
  }
};

const MessagingCentre = ({
  allUsersFetched,
  getProviderMessagingUsers,
  sendMessageToUser,
  users,
  providerId,
}) => {
  const [alert, setAlert] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [startIndex, setStartIndex] = useState(0);

  const columns = [
    {
      Header: 'Patient Name',
      accessor: 'fullName',
      id: 'fullName',
    },
    {
      Header: 'Message Sent',
      accessor: 'messageSent',
      id: 'messageSent',
      Cell: ({ value }) => (value ? <Icon name="checkmark" className="align-baseline mb-0 mr-3" /> : ''),
    },
    {
      Header: '',
      accessor: 'sendMessageButton',
      id: 'sendMessageButton',
      Cell: ({ row: { original: user } }) => (
        <Button
          className="mt-4"
          data-cy="messaging-centre-send-message-button"
          disabled={user.messageSent}
          onClick={() => sendMessageToUser({ acUserId: user.acUserId, providerId })}
          type="primary"
        >
          Send Message
        </Button>
      ),
    },
  ];

  const onFailure = (error) => {
    const statusCode = path(['response', 'status'])(error);
    const errorMessage = compose(prop('message'), head, pathOr([], ['response', 'data', 'errors']))(error);
    if (statusCode !== 401) { return setAlert(alertError); }
    return toast.error(errorMessage);
  };

  const fetchData = async () => {
    setIsFetching(true);

    getProviderMessagingUsers({ providerId, params: { startIndex } })
      .catch(onFailure)
      .finally(() => setIsFetching(false));
  };

  const fetchNext = () => {
    if (!allUsersFetched && !isFetching) {
      setStartIndex((previousIndex) => previousIndex + 20);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startIndex, providerId]);

  const usersNotSent = filter(complement(prop('messageSent')))(users);

  return (
    <Layout>
      <div className="messaging-centre d-flex flex-column h-100">
        <div className="financials__header text-center pb-7">
          <div className="mb-4 pb-1">
            <h1 className="h4 text-tuna mb-4">Messaging Centre</h1>
            <p className="text-small text-comet mb-0">
              Send direct SMS messages straight to your patients, one by one.
            </p>
          </div>
        </div>
        {!isEmpty(alert) && (
          <div className={`d-flex justify-content-center messaging-centre ${alert.type}`}>
            <div className="d-inline-flex align-items-center">
              <Icon name={renderIcon(alert.type)} className="h2 align-baseline mb-0 mr-3" />
              <p className="mb-0">
                {alert.message}
              </p>
            </div>
          </div>
        )}
        {isEmpty(alert) && (
          <Table
            columns={columns}
            data={usersNotSent}
            fetchData={fetchNext}
            hasMore={!allUsersFetched}
            isInfiniteLoading={isFetching}
          />
        )}
      </div>
    </Layout>
  );
};

export default MessagingCentre;
