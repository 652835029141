import * as R from 'ramda';

const isObject = (value) => value && typeof value === 'object' && value.constructor === Object;

const isIterable = (value) => isObject(value) || Array.isArray(value);

const exactKey = (value) => R.includes(R.toLower(value),
  [
    'insuranceid',
    'riskgroupnum',
    'totalpayment',
    'confirmationcode',
    'affectedbycoronavirus',
  ]);

const includesKey = (value) => R.any(
  // eslint-disable-next-line no-underscore-dangle
  R.includes(R.__, R.toLower(value)),
  [
    'name',
    'holder',
    'address',
    'number',
    'city',
    'state',
    'postalcode',
    'phone',
    'email',
    'ssn',
    'mrn',
    'ach',
    'initial',
    'ip',
    'token',
    'html',
    'birth',
    'income',
    'information',
    'identifier',
    'age',
    'amount',
    'bank',
    'credential',
    'password',
  ],
);

const hide = (value, key) => (R.or(
  exactKey(key),
  includesKey(key),
) ? '***' : value);

/**
 * Encrypts all PII params in objects|arrays for logging purposes.
 * @param {object|array} data - Data with PII info.
 * @returns {object|array} cleanData - Data with encrypted PII info.
 */
const cleanPIIData = R.when(
  isIterable,
  R.pipe(
    R.when(
      isObject,
      R.mapObjIndexed(hide),
    ),
    R.map((x) => cleanPIIData(x)),
  ),
);

export default cleanPIIData;
