import React from 'react';
import { useTable, useSortBy } from 'react-table';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { propOr } from 'ramda';

import Icon from 'components/Icon';

const columns = [{
  Header: 'First Name',
  accessor: 'firstName',
  hide: true,
}, {
  Header: 'Last Name',
  accessor: 'lastName',
  hide: true,
}, {
  Header: 'MRN',
  accessor: 'medicalRecordNumber',
  hide: true,
}, {
  Header: 'Email',
  accessor: 'email',
  hide: true,
}, {
  Header: 'Mobile',
  accessor: 'phone',
  hide: true,
}, {
  Header: 'Offer Details',
  accessor: 'offerDetails',
}, {
  Header: 'Invoice $',
  accessor: 'totalAmount',
  hide: true,
}, {
  Header: 'Status',
  accessor: 'status',
}];

const Table = ({ data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  }, useSortBy);

  const invoiceAmountCell = (cell) => {
    if (propOr(false, 'value')(cell)) {
      return (
        <NumberFormat
          value={cell.value}
          decimalScale={2}
          displayType="text"
          prefix="$"
          thousandSeparator
        />
      );
    }

    return null;
  };

  const dobCell = (cell) => format(new Date(cell.value), 'mm/dd/yyyy');

  const renderCell = (row, cell) => {
    if (cell.column.id === 'totalAmount') return invoiceAmountCell(cell);
    if (cell.column.id === 'birthDate') return dobCell(cell);
    return <span className="text-break">{cell.render('Cell')}</span>;
  };

  const renderColumnOrderIcon = (column) => {
    if (column.isSorted && column.isSortedDesc) return <Icon name="caret-down" />;
    if (column.isSorted && !column.isSortedDesc) return <Icon name="caret-up" />;
    return null;
  };

  return (
    <div className="table-custom table-responsive table-custom--search-results">
      <div className="table-custom__head">
        <table className="table table-striped mb-0" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-tuna text-small font-weight-normal">
                    <span className="align-middle">{column.render('Header')}</span>
                    <span className="text-xsmaller text-commet align-middle ml-2">
                      {renderColumnOrderIcon(column)}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </table>
      </div>
      <div className="table-custom__body">
        <table className="table table-striped mb-0" {...getTableProps()}>
          <tbody {...getTableBodyProps()}>
            {rows.map(
              (row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      const attrs = {
                        ...(cell.column.hide && { 'data-private': true }),
                      };

                      return (
                        <td {...attrs} {...cell.getCellProps()}>
                          {renderCell(row, cell)}
                        </td>
                      );
                    })}
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
