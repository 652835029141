import React from 'react';

const NoAuthAvailable = ({
  show,
  children,
}) => (show ? (
  <div>
    <h4>Authorization is not available right now</h4>
    <h6>Please contact your manager.</h6>
  </div>
) : children);

export default NoAuthAvailable;
