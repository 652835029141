import { useEffect } from 'react';
import queryString from 'qs';
import { equals } from 'ramda';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { setToken } from 'libs/authenticationService';

import getErrorMessage from 'utils/getErrorMessage';

const ProviderFormWrapper = ({
  children, getOfferData, startSpinner, stopSpinner,
}) => {
  const history = useHistory()
  const qs = queryString.parse(history.location.search, { ignoreQueryPrefix: true });

  const getOfferDataSuccess = () => stopSpinner();

  const getOfferDataFailure = (error) => {
    stopSpinner();
    const message = getErrorMessage(error);

    if (equals(message, 'The Offer has already been approved')) {
      return history.push('/provider-form/success', { offerApproved: true, message });
    }

    return toast.error(message);
  };

  useEffect(() => {
    setToken(qs.token);
    const { offerId } = qs;
    startSpinner();

    getOfferData({ params: { offerId } })
      .then(getOfferDataSuccess)
      .catch(getOfferDataFailure);
  }, []);

  return children;
};

export default ProviderFormWrapper;
