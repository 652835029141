import { connect } from 'react-redux';
import { equals, path } from 'ramda';

import {
  getProviderPayments,
  downloadProviderPaymentsCSV,
} from 'actions/provider';
import { startSpinner, stopSpinner } from 'actions/spinner';

import getCSVData from 'utils/getCSVData';
import getRole from 'utils/getRole';

import { dataFinancials } from 'helpers/mocks';

import Financials from './Financials';

const mapStateToProps = ({ user: { email }, provider }) => {
  const csvData = getCSVData(dataFinancials);
  const role = getRole();
  const isDemoUser = equals(email, 'demo@provider.com');

  const fakeData = {
    providerPayments: dataFinancials,
    totalPages: 1,
    page: 1,
  };

  const realData = path(['payments'])(provider);
  const payments = isDemoUser ? fakeData : realData;
  const { csvStatus } = provider;

  return {
    payments,
    csvStatus,
    role,
    isDemoUser,
    email,
    csvData,
  };
};

const mapDispatchToProps = {
  getProviderPayments,
  downloadProviderPaymentsCSV,
  startSpinner,
  stopSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Financials);
