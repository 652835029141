import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { format } from 'date-fns';

import Icon from 'components/Icon';
import { path, isEmpty } from 'ramda';

const Table = ({ columns, data, reportsUrl = '' }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  }, useSortBy);

  const onClickHandler = () => {
    if (isEmpty(reportsUrl)) {
      return null;
    }

    return window.open(reportsUrl);
  };

  const renderStatusIcon = (cell) => {
    switch (cell.value) {
      case 'Success':
        return (
          <span className="text-dodger-blue">
            <Icon name="circle-check" className="mr-4" />
            <span>{cell.render('Cell')}</span>
          </span>
        );
      case 'Abort':
        return (
          <span className="text-comet">
            <Icon name="circle-equis" className="mr-4" />
            <span>{cell.render('Cell')}</span>
          </span>
        );
      case 'Error':
        return (
          <span className="text-burnt-sienna">
            <Icon name="circle-warning" className="mr-4" />
            <span>{cell.render('Cell')}</span>
          </span>
        );

      case 'Pending':
        return (
          <span className="text-jumbo">
            <Icon name="circle-pending" className="mr-4" />
            <span>{cell.render('Cell')}</span>
          </span>
        );
      default:
        return null;
    }
  };

  const renderCreatedAt = (cell) => (
    <span>
      {format(new Date(cell.value), 'MMM dd, yyyy')}
      {' '}
      at
      {' '}
      {format(new Date(cell.value), 'h:mma z')}
    </span>
  );


  const renderCell = (cell) => {
    const id = path(['column', 'id'])(cell);

    switch (id) {
      case 'status':
        return renderStatusIcon(cell);
      case 'createdAt':
      case 'modifiedAt':
        return renderCreatedAt(cell);
      default:
        return cell.render('Cell');
    }
  };

  const renderColumnOrderIcon = (column) => {
    if (column.isSorted && column.isSortedDesc) return <Icon name="caret-down" />;
    if (column.isSorted && !column.isSortedDesc) return <Icon name="caret-up" />;
    return null;
  };

  return (
    <div className="table-custom table-responsive">
      <div className="table-custom__head">
        <table className="table table-striped mb-0" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-tuna text-small font-weight-normal">
                    <span className="align-middle">{column.render('Header')}</span>
                    <span className="text-xsmaller text-commet align-middle ml-2">
                      {renderColumnOrderIcon(column)}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </table>
      </div>
      <div className="table-custom__body">
        <table className="table table-striped mb-0" {...getTableProps()}>
          <tbody {...getTableBodyProps()}>
            {rows.map(
              (row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} onClick={onClickHandler} className={isEmpty(reportsUrl) ? '' : 'clickable'}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {renderCell(cell)}
                      </td>
                    ))}
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
