import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';

import Root from 'views/Root';
import CreatePassword from 'views/CreatePassword';
import VerificationCode from 'views/VerificationCode';
import Login from 'views/Login';
import PatientsSearch from 'views/Patients/Search';
import DataExchange from 'views/DataExchange';
import Reports from 'views/Reports';
import Financials from 'views/Financials';
import MyAccount from 'views/MyAccount';
import PatientInfo from 'views/ProviderForm/PatientInfo';
import BillApproval from 'views/ProviderForm/BillApproval';
import Success from 'views/ProviderForm/Success';
import Invites from 'views/Invites';
import SSOSignin from 'views/SSOSignin';
import AdminHome from 'AdminSite/pages/Home';
import AdminPatientsSearch from 'AdminSite/pages/PatientsSearch';
import AdminReports from 'AdminSite/pages/Reports';
import AdminFinancial from 'AdminSite/pages/Financial';
import AdminCreateUsers from 'AdminSite/pages/CreateUsers';
import NoMatch from 'views/NoMatch';
import MessagingCentre from 'views/MessagingCentre';
import VaultUsers from 'views/VaultUsers';

const Routes = () => (
  <Switch>
    <Route exact path="/"><Root /></Route>
    <Route exact path="/create-password"><CreatePassword /></Route>
    <Route exact path="/verification-code"><VerificationCode /></Route>
    <Route exact path="/login"><Login /></Route>
    <PrivateRoute exact path="/data-exchange" denyRoles={['ADVOCATE']}><DataExchange /></PrivateRoute>
    <PrivateRoute exact path="/messaging-centre"><MessagingCentre /></PrivateRoute>
    <PrivateRoute exact path="/patients-search"><PatientsSearch /></PrivateRoute>
    <PrivateRoute exact path="/reports" denyRoles={['ADVOCATE']}><Reports /></PrivateRoute>
    <PrivateRoute exact path="/financials" roles={['ADMIN', 'MANAGER']}><Financials /></PrivateRoute>
    <PrivateRoute exact path="/my-account"><MyAccount /></PrivateRoute>
    <PrivateRoute exact path="/invites"><Invites /></PrivateRoute>
    <Route exact path="/provider-form/patient-info"><PatientInfo /></Route>
    <Route exact path="/provider-form/bill-approval"><BillApproval /></Route>
    <Route exact path="/provider-form/success"><Success /></Route>
    <Route exact path="/sso/signin"><SSOSignin /></Route>

    <PrivateRoute exact userTypes={['ADMIN', 'CUSTOMER_SUPPORT']} path="/admin"><AdminHome /></PrivateRoute>
    <PrivateRoute exact userTypes={['ADMIN', 'CUSTOMER_SUPPORT']} path="/admin/patients-search"><AdminPatientsSearch /></PrivateRoute>
    <PrivateRoute exact userTypes={['ADMIN']} path="/admin/reports"><AdminReports /></PrivateRoute>
    <PrivateRoute exact userTypes={['ADMIN']} path="/admin/financial"><AdminFinancial /></PrivateRoute>
    <PrivateRoute exact userTypes={['ADMIN']} path="/admin/create-users"><AdminCreateUsers /></PrivateRoute>
    <PrivateRoute exact userTypes={['ADMIN']} path="/admin/vault-users"><VaultUsers /></PrivateRoute>
    <Route><NoMatch /></Route>
  </Switch>
);

const Router = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);

export default Router;
