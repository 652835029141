import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'qs';
import {
  fromPairs,
  equals,
  map,
  toPairs,
  compose,
  pick,
  isEmpty,
  zipObj,
  isNil,
  path,
  reject,
  omit,
} from 'ramda';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';

// eslint-disable-next-line import/default
import Pagination from 'components/Pagination';
import Table from 'components/Invites/Table';
import Layout from 'components/Layout';
import Button from 'components/Button';
import Modal from 'components/Invites/Modal';
import ConfirmModal from 'components/Invites/Confirm';

import getErrorMessage from 'utils/getErrorMessage';

const DEFAULT_PARAMS = Object.freeze({
  order: { createdAt: 'desc' },
  page: 1,
});

const Invites = ({
  invites,
  getPatientInvites,
  createPatientInvite,
  updatePatientInvite,
  expirePatientInvite,
  prolongPatientInvite,
  getPatientsInvitesStats,
  email,
  startSpinner,
  stopSpinner,
  providerId,
}) => {
  const history = useHistory();
  const [params, setParams] = useState({
    ...DEFAULT_PARAMS,
    ...queryString.parse(history.location.search, { ignoreQueryPrefix: true }),
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentInvite, setCurrentInvite] = useState({});
  const [currentPatientInviteId, setCurrentPatientInviteId] = useState('');
  const {
    patientInvites, totalPages, page, stats,
  } = invites;

  const fetchData = (data) => {
    startSpinner();

    getPatientInvites(data)
      .then(stopSpinner)
      .catch(stopSpinner);
  };

  const clearDefaultQueryParams = compose(
    fromPairs,
    map(([key, value]) => (equals(value, DEFAULT_PARAMS[key]) ? [] : [key, value])),
    toPairs,
  );

  const updateParams = (key, value, forceUpdate = false) => {
    const newParams = pick(['page', 'order'], {
      ...params,
      [key]: value,
    });

    if (equals(params, newParams) && !forceUpdate) {
      return null;
    }

    // if we update anything but page number, we should move to the first page
    if (key !== 'page') {
      newParams.page = 1;
    }

    const queryParams = queryString.stringify(clearDefaultQueryParams(newParams));
    history.push(`/invites?${queryParams}`);
    setParams(newParams);

    return fetchData({ providerId, params: newParams });
  };

  const onSortChange = (sortBy) => {
    const order = sortBy.reduce((r, sortRule) => {
      r[sortRule.id] = sortRule.desc ? 'desc' : 'asc';
      return r;
    }, {});

    updateParams('order', isEmpty(sortBy) ? DEFAULT_PARAMS.order : order);
  };

  const orderObjectToSortByArray = compose(
    map(zipObj(['id', 'desc'])),
    toPairs,
    map(equals('desc')),
  );

  const onPageChange = (val) => { updateParams('page', val + 1); };

  const openCreateInviteModal = () => {
    setIsOpen(true);
    setCurrentInvite({});
  };

  const onFailure = (error) => {
    const message = getErrorMessage(error);
    stopSpinner();
    toast.error(message);
  };

  const createNewInvite = (values, actions) => {
    const data = {
      ...compose(reject(equals('')), omit(['agree']))(values),
      totalAmount: values.totalAmount * 100,
    };

    startSpinner();

    createPatientInvite({ providerId, data })
      .then(() => {
        setIsOpen(false);
        stopSpinner();
        const forceUpdate = true;
        return updateParams('page', 1, forceUpdate);
      })
      .catch(onFailure)
      .finally(actions.setSubmitting(false));
  };

  const expireInvite = () => {
    startSpinner();

    expirePatientInvite({
      providerId,
      patientInviteId: currentPatientInviteId,
    })
      .then(() => {
        stopSpinner();
        setIsOpenConfirmModal(false);
      })
      .catch(() => {
        onFailure();
        setIsOpenConfirmModal(false);
      });
  };

  const prolongSuccess = (response) => {
    const expireAt = path(['value', 'expireAt'], response);
    const date = format(new Date(expireAt), 'MM/dd/yyyy');
    const message = `Now invite will expire on ${date}`;
    stopSpinner();
    toast.success(message);
  };

  const prolongInvite = (patientInviteId) => () => {
    startSpinner();

    prolongPatientInvite({
      providerId,
      patientInviteId,
    })
      .then(prolongSuccess)
      .catch(onFailure);
  };

  const editInviteSuccess = () => {
    setIsOpen(false);
    stopSpinner();
  };

  const editInvite = (values, actions) => {
    startSpinner();

    const data = {
      ...compose(reject(equals('')), omit(['firstName', 'lastName', 'email', 'phone', 'agree']))(values),
      totalAmount: values.totalAmount * 100,
    };

    updatePatientInvite({
      providerId,
      patientInviteId: currentPatientInviteId,
      data,
    })
      .then(editInviteSuccess)
      .catch(onFailure)
      .finally(actions.setSubmitting(false));
  };

  useEffect(() => {
    if (!isEmpty(patientInvites) && !isLoaded) {
      setIsLoaded(true);
    }
  }, [patientInvites]);

  useEffect(() => {
    if (!isNil(email)) {
      fetchData({ providerId, params });
    }
  }, [providerId, email]);

  useEffect(() => {
    getPatientsInvitesStats({ providerId });
  }, [providerId]);

  return (
    <Layout>
      <div className="invites d-flex flex-column h-100">
        <div className="invites__header text-center pb-7">
          <div className="mb-6 pb-1">
            <h1 className="h4 text-charade mb-0">Invite a Patient to Pay</h1>
          </div>
          <div className="row align-items-center mb-4">
            <div className="col-3 text-left">
              <h2 className="text-tuna text-large font-weight-semibold mb-0">Track Invite</h2>
            </div>
          </div>
          <div className="invites__summary">
            <div className="row align-items-center text-left">
              <div className="col">
                <span className="text-xlarger font-weight-semibold text-trout">{stats && stats.totalInvites}</span>
                <br />
                <span className="text-comet">Total Invites</span>
              </div>
              <div className="col">
                <NumberFormat
                  value={stats && stats.totalInvitesAmount / 100}
                  decimalScale={2}
                  displayType="text"
                  prefix="$"
                  thousandSeparator
                  className="text-xlarger font-weight-semibold text-trout"
                />
                <br />
                <span className="text-comet">Total Amount</span>
              </div>
              <div className="col">
                <span className="text-xlarger font-weight-semibold text-bright-sun">{stats && stats.totalInProgress}</span>
                <br />
                <span className="text-comet">Total Pending</span>
              </div>
              {/* <div className="col">
                <span className="text-xlarger font-weight-semibold text-jungle-green">208</span>
                <br />
                <span className="text-comet">Total Paid</span>
              </div> */}
              <div className="col">
                <span className="text-xlarger font-weight-semibold text-dodger-blue-alt">{stats && stats.totalClicked}</span>
                <br />
                <span className="text-comet">Total Clicked</span>
              </div>
              <div className="col">
                <span className="text-xlarger font-weight-semibold text-burnt-sienna">{stats && stats.totalExpired}</span>
                <br />
                <span className="text-comet">Total Expired</span>
              </div>
              <div className="col-3">
                <Button
                  type="primary"
                  className="btn-block text-larger py-3"
                  onClick={openCreateInviteModal}
                >
                  + Add new patient
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Table
          onSortChange={onSortChange}
          initialSortBy={orderObjectToSortByArray(params.order)}
          data={patientInvites}
          setIsOpenConfirmModal={setIsOpenConfirmModal}
          setCurrentPatientInviteId={setCurrentPatientInviteId}
          prolongInvite={prolongInvite}
          setIsOpen={setIsOpen}
          setCurrentInvite={setCurrentInvite}
        />
      </div>
      {totalPages > 1 && (
        <Pagination
          pages={totalPages}
          page={page - 1}
          onPageChange={onPageChange}
          previousText="<"
          nextText=">"
        />
      )}
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={isEmpty(currentInvite) ? createNewInvite : editInvite}
        currentInvite={currentInvite}
      />
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        setIsOpen={setIsOpenConfirmModal}
        onSubmit={expireInvite}
      />
    </Layout>
  );
};

export default Invites;
