import React from 'react';

const AuthProvidersList = ({
  authStrategies,
}) => {
  if (!authStrategies || authStrategies.length === 0) {
    return <></>;
  }

  return (
    <div className="provider-form">
      {
        authStrategies.map((strategy) => {
          const { providerAuthStrategyId, displayName } = strategy;
          return (
            <a
              key={providerAuthStrategyId}
              href={`${process.env.REACT_APP_SERVER_URL}v1/sso/login/idp?providerAuthStrategyId=${providerAuthStrategyId}`}
              className="btn btn-block btn-scooter mb-4"
            >
              {displayName}
            </a>
          );
        })
      }
    </div>
  );
};

export default AuthProvidersList;
