import { connect } from 'react-redux';

import { getUser } from 'actions/user';
import { startSpinner, stopSpinner } from 'actions/spinner';

import Layout from './Layout';

const mapStateToProps = ({ user: { email, typeName } }) => ({ email, userType: typeName });

const mapDispatchToProps = { getUser, startSpinner, stopSpinner };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);
