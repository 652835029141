import { connect } from 'react-redux';
import {
  find,
  propEq,
  reject,
} from 'ramda';

import { login, loginAdmin } from 'actions/user';
import { getAuthStrategies } from 'actions/provider';
import {
  startSpinner,
  stopSpinner,
} from 'actions/spinner';
import isPayzenAdminSite from 'utils/isPayzenAdminSite';

import Login from './Login';

const mapStateToProps = ({
  provider: { authStrategies },
}) => {
  if (isPayzenAdminSite) {
    return {
      isPayzenAdminSite: true,
      basicAuth: {},
      authIdentityProviders: [],
    };
  }

  return {
    basicAuth: find(propEq('type', 'BASIC'), authStrategies || []),
    authIdentityProviders: reject(propEq('type', 'BASIC'), authStrategies || []),
  };
};

const mapDispatchToProps = {
  login: isPayzenAdminSite ? loginAdmin : login,
  startSpinner,
  stopSpinner,
  getAuthStrategies,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
