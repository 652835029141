import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import queryString from 'qs';
import {
  compose, map, isEmpty, omit,
  equals, pick, zipObj, toPairs, fromPairs, isNil,
} from 'ramda';
import {
  format,
  startOfYear,
  parse,
} from 'date-fns';
import { CSVLink } from 'react-csv';

// eslint-disable-next-line import/default
import Pagination from 'components/Pagination';
import Table from 'components/Financials/Table';

import { formatDate, parseDate } from 'utils/parseAndFormatDates';
import getProviderId from 'utils/getProviderId';
import getOrganizationId from 'utils/getOrganizationId';

import Layout from 'components/Layout';
import Icon from 'components/Icon';

import DownloadLink from 'components/DownloadLink';

const DEFAULT_PARAMS = Object.freeze({
  fromDate: format(startOfYear(new Date()), 'MM-dd-yyyy'),
  toDate: format(new Date(), 'MM-dd-yyyy'),
  order: { paymentDate: 'desc' },
  page: 1,
});

// Firefox do not allow 'MM-dd-yyyy' as the input for Date constructor
const parseDateStr = (date) => parse(date, 'MM-dd-yyyy', new Date());

const Financials = ({
  payments,
  getProviderPayments,
  downloadProviderPaymentsCSV,
  csvStatus,
  isDemoUser,
  email,
  csvData,
  startSpinner,
  stopSpinner,
}) => {
  const history = useHistory();
  const [params, setParams] = useState({
    ...DEFAULT_PARAMS,
    ...queryString.parse(history.location.search, { ignoreQueryPrefix: true }),
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const from = parseDateStr(params.fromDate);
  const to = parseDateStr(params.toDate);
  const modifiers = { start: from, end: to };
  const FORMAT = 'MMM dd, yyy';
  const providerId = getProviderId();
  const defaultProviderId = sessionStorage.getItem('DEFAULT_PROVIDER_ID');
  const organizationId = defaultProviderId === providerId ? getOrganizationId() : undefined;
  const { providerPayments, totalPages, page } = payments;
  const fetchData = (data) => {
    startSpinner();
    getProviderPayments(data)
      .then(stopSpinner)
      .catch(stopSpinner);
  };

  const clearDefaultQueryParams = compose(
    fromPairs,
    map(([key, value]) => (equals(value, DEFAULT_PARAMS[key]) ? [] : [key, value])),
    toPairs,
  );

  const updateParams = (key, value) => {
    const newParams = pick(['fromDate', 'toDate', 'page', 'order'], {
      ...params,
      [key]: value,
    });

    if (equals(params, newParams)) {
      return null;
    }

    // if we update anything but page number, we should move to the first page
    if (key !== 'page') {
      newParams.page = 1;
    }

    const queryParams = queryString.stringify(clearDefaultQueryParams(newParams));
    history.push(`/financials?${queryParams}`);
    setParams(newParams);

    if (isDemoUser) {
      return null;
    }

    return fetchData({
      providerId,
      organizationId,
      params: newParams,
    });
  };

  const onSortChange = (sortBy) => {
    const order = sortBy.reduce((r, sortRule) => {
      r[sortRule.id] = sortRule.desc ? 'desc' : 'asc';
      return r;
    }, {});

    updateParams('order', isEmpty(sortBy) ? DEFAULT_PARAMS.order : order);
  };

  const orderObjectToSortByArray = compose(
    map(zipObj(['id', 'desc'])),
    toPairs,
    map(equals('desc')),
  );

  const handleFromChange = (fromDate) => {
    updateParams('fromDate', format(fromDate, 'MM-dd-yyyy'));
  };

  const handleToChange = (toDate) => {
    updateParams('toDate', format(toDate, 'MM-dd-yyyy'));
  };

  const onPageChange = (val) => {
    updateParams('page', val + 1);
  };

  const downloadAction = () => downloadProviderPaymentsCSV({
    providerId,
    organizationId,
    params: {
      ...omit(['page'], params),
      order: {
        ...pick(['createdAt', 'paymentDate', 'amount'], params.order),
      },
    },
  });

  useEffect(() => {
    if (!isEmpty(providerPayments) && !isLoaded) {
      setIsLoaded(true);
    }
  }, [providerPayments]);

  useEffect(() => {
    if (!isNil(email) && !isDemoUser) {
      fetchData({
        providerId,
        organizationId,
        params,
      });
    }
  }, [providerId, email]);

  const fomatDate = (date) => date && format(parseDateStr(date), 'MM/dd/yyyy');

  const fromDate = fomatDate(params.fromDate);
  const toDate = fomatDate(params.toDate);

  const csvFileName = `Financial Report from ${fromDate} to ${toDate}.csv`;

  return (
    <Layout>
      <div className="financials d-flex flex-column h-100">
        <div className="financials__header text-center pb-7">
          <div className="mb-4 pb-1">
            <h1 className="h4 text-tuna mb-4">Financial Transactions</h1>
            <p className="text-small text-comet mb-0">
              PayZen is funding you daily for enrolled patients.
              See below for the transaction history
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-3" />
            <div className="col-6 text-center text-small">
              <div className="InputFromTo">
                <span className="text-tuna text-small">From</span>
                <DayPickerInput
                  classNames={{
                    container: 'DayPickerInput d-inline-block w-auto mx-2',
                    overlayWrapper: 'DayPickerInput-OverlayWrapper',
                    overlay: 'DayPickerInput-Overlay',
                  }}
                  inputProps={{
                    className: 'btn btn-sm btn-pattens-blue text-sapphire text-smaller text-uppercase',
                    style: { width: '115px' },
                    readOnly: true,
                  }}
                  value={from}
                  placeholder="From"
                  format={FORMAT}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { after: to },
                    toMonth: to,
                    modifiers,
                    numberOfMonths: 1,
                  }}
                  onDayChange={handleFromChange}
                />
                <span className="text-tuna text-small">To</span>
                <span className="InputFromTo-to">
                  <DayPickerInput
                    classNames={{
                      container: 'DayPickerInput d-inline-block w-auto mx-2',
                      overlayWrapper: 'DayPickerInput-OverlayWrapper',
                      overlay: 'DayPickerInput-Overlay',
                    }}
                    inputProps={{
                      className: 'btn btn-sm btn-pattens-blue text-sapphire text-smaller text-uppercase',
                      style: { width: '115px' },
                      readOnly: true,
                    }}
                    value={to}
                    placeholder="To"
                    format={FORMAT}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      selectedDays: [from, { from, to }],
                      disabledDays: { before: from, after: new Date() },
                      modifiers,
                      month: from,
                      fromMonth: from,
                      numberOfMonths: 1,
                    }}
                    onDayChange={handleToChange}
                  />
                </span>
              </div>
            </div>
            {!isEmpty(providerPayments) && (
              <div className="col-3 text-right">
                {isDemoUser ? (
                  <CSVLink
                    data={csvData}
                    filename={csvFileName}
                    className="btn btn-sm btn-dodger-blue text-small text-decoration-none"
                  >
                    <Icon name="doc" className="align-baseline mr-2" />
                    Download CSV
                  </CSVLink>
                ) : (
                  <DownloadLink
                    downloadAction={downloadAction}
                    disabled={csvStatus === 'pending'}
                    filename={csvFileName}
                    className="btn btn-sm btn-dodger-blue text-small text-decoration-none"
                  >
                    <Icon name="doc" className="align-baseline mr-2" />
                    Download CSV
                  </DownloadLink>
                )}
              </div>
            )}
          </div>
        </div>
        <Table
          onSortChange={onSortChange}
          initialSortBy={orderObjectToSortByArray(params.order)}
          data={providerPayments}
          isDemoUser={isDemoUser}
        />
        {totalPages > 1 && (
          <Pagination
            pages={totalPages}
            page={page - 1}
            onPageChange={onPageChange}
            previousText="<"
            nextText=">"
          />
        )}
      </div>
    </Layout>
  );
};

export default Financials;
