import LogRocket from 'logrocket';
import { createAsyncAction, createAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';
import {
  reqLogin,
  reqLoginAdmin,
  reqSetNewPassword,
  reqExchangeAuthCode,
  reqVerificationCode,
  reqGetUser,
  reqUpdatePassword,
  reqRefreshToken,
  reqGetOrganizationProviders,
  reqGetDefaultProvider,
  reqGetProviderPermissions,
  reqLogout,
} from 'api/user';
import { anonymizeEmail, anonymizeName } from 'utils/anonymize';

export const login = createAsyncAction(
  'LOGIN',
  (payload) => reqLogin(payload).then(prop('data')),
);

export const loginAdmin = createAsyncAction(
  'LOGIN_ADMIN',
  (payload) => reqLoginAdmin(payload).then(prop('data')),
);

export const setNewPassword = createAsyncAction(
  'SET_NEW_PASSWORD',
  (payload) => reqSetNewPassword(payload).then(prop('data')),
);

export const exchangeAuthCode = createAsyncAction(
  'EXCHANGE_AUTH_CODE',
  (code) => reqExchangeAuthCode(code).then(prop('data')),
);

export const verificationCode = createAsyncAction(
  'VERIFICATION_CODE',
  (payload) => reqVerificationCode(payload).then(prop('data')),
);

export const getUser = createAsyncAction(
  'GET_USER',
  () => reqGetUser()
    .then(prop('data'))
    .then((data) => {
      LogRocket.identify(prop('userId', data), {
        name: `${anonymizeName(prop('firstName', data))} ${anonymizeName(prop('lastName', data))}`,
        email: anonymizeEmail(prop('email', data)),
      });

      return data;
    }),
);

export const updatePassword = createAsyncAction(
  'UPDATE_PASSWORD',
  (payload) => reqUpdatePassword(payload).then(prop('data')),
);

export const refreshToken = createAsyncAction(
  'REFRESH_TOKEN',
  (payload) => reqRefreshToken(payload).then(prop('data')),
);

export const getOrganizationProviders = createAsyncAction(
  'GET_ORGANIZATION_PROVIDERS',
  (payload) => reqGetOrganizationProviders(payload).then(prop('data')),
);

export const getDefaultProvider = createAsyncAction(
  'GET_DEFAULT_PROVIDER',
  (payload) => reqGetDefaultProvider(payload).then(prop('data')),
);

export const getProviderPermissions = createAsyncAction(
  'GET_PROVIDER_PERMISSIONS',
  () => reqGetProviderPermissions().then(prop('data')),
);

export const logout = createAction('LOGOUT');

export const ssoLogout = createAsyncAction(
  'LOGOUT',
  () => reqLogout().then(prop('data')),
);
