/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

const InfoBubble = ({ title, content }) => (
  <div className="info-bubble">
    <h1 className="info-bubble__title">{title}</h1>
    <div className="info-bubble__content">
      {(typeof content === 'object') && (
        <ul className="info-bubble__list">
          {content.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
      )}
      {(typeof content === 'string') && (
        <p className="mb-0">{content}</p>
      )}
    </div>
  </div>
);

InfoBubble.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
};

InfoBubble.defaultProps = {
  title: '',
};

export default InfoBubble;
