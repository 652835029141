import { DateUtils } from 'react-day-picker';
import { format as dateFnsFormat, parse as dateFnsParse } from 'date-fns';

export const parseDate = (str, format, locale) => {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
};

export const formatDate = (date, format, locale) => (
  date && dateFnsFormat(new Date(date), format, { locale })
);
