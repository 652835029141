import { authenticationService } from '../libs';

const logoutMiddleware = () => (next) => (action) => {
  const { type } = action;
  if (type && type === 'LOGOUT') {
    authenticationService.clearUserDataFromSessionStorage();
  }
  next(action);
};

export default logoutMiddleware;
