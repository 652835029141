import Icon from 'components/Icon';
import React from 'react';
import PropTypes from 'prop-types';

const NoResultsFound = ({ searchTerm }) => (
  <div className="flex-fill d-flex flex-column justify-content-center align-items-center p-2">
    <p className="text-large text-tuna mb-12 text-center">
      <Icon name="band-aids" className="search-patients-results__icon mb-5" />
      <br />
      No results found for
      {' '}
      <b>{searchTerm}</b>
    </p>
  </div>
);

NoResultsFound.propTypes = {
  searchTerm: PropTypes.string.isRequired,
};

export default NoResultsFound;
