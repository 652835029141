import user from './user';
import provider from './provider';
import spinner from './spinner';
import providerForm from './providerForm';

export default {
  user,
  provider,
  spinner,
  providerForm,
};
