import { connect } from 'react-redux';

import {
  verificationCode,
  getOrganizationProviders,
  getDefaultProvider,
  getProviderPermissions,
} from 'actions/user';
import { startSpinner, stopSpinner } from 'actions/spinner';

import VerificationCode from './VerificationCode';

const mapStateToProps = ({ user: { twoFactorToken } }) => ({ twoFactorToken });

const mapDispatchToProps = {
  verificationCode,
  startSpinner,
  stopSpinner,
  getOrganizationProviders,
  getDefaultProvider,
  getProviderPermissions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerificationCode);
