import decode from 'jwt-decode';
import { propEq, anyPass } from 'ramda';

import { http } from 'libs';

// Token string name
const TOKEN_NAME = 'token';
const REFRESH_TOKEN_NAME = 'refreshToken';
export const PROVIDER_ID_NAME = 'providerId';
export const SESSION_ID = 'SESSION_ID';

export const getToken = () =>
  // Retrieves the user token from sessionStorage
  sessionStorage.getItem(TOKEN_NAME);

export const getRefreshToken = () =>
  // Retrieves the user token from sessionStorage
  sessionStorage.getItem(REFRESH_TOKEN_NAME);

export const loggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken(); // Getting token from sessionstorage
  return !!token;
};

export const setToken = (token) => {
  // Saves user token to sessionStorage
  sessionStorage.setItem(TOKEN_NAME, token);
};

export const setRefreshToken = (token) => {
  // Saves user refresh token to sessionStorage
  sessionStorage.setItem(REFRESH_TOKEN_NAME, token);
};

export const clearUserDataFromSessionStorage = () => {
  sessionStorage.clear();
};

export const getProfile = () =>
  // Using jwt-decode to decode the token
  decode(getToken());

const getSessionId = () =>
  // Retrieves the session id from sessionStorage
  sessionStorage.getItem(SESSION_ID);

const checkStatus = (response) => {
  // raises an error in case response status is not a success
  if (response.status >= 200 && response.status < 300) {
    // Success status lies between 200 to 300
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

const unauthorizedHandler = (error) => {
  const { response } = error;

  if (response) {
    const is401 = propEq('status', 401);
    const is403 = propEq('status', 403);
    const shouldLogout = anyPass([is401, is403])(response);

    if (shouldLogout) {
      clearUserDataFromSessionStorage();
    }

    const err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
};

export const apiCall = (method, url, options) => {
  // performs api calls sending the required authentication headers
  const sessionId = getSessionId();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-session-id': sessionId || undefined,
  };

  // Setting Authorization header if logged in
  // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
  if (loggedIn()) {
    headers.Authorization = `Bearer ${getToken()}`;
  }

  return http[method](url, {
    headers,
    ...options,
  })
    .then(checkStatus)
    .then((response) => response)
    .catch(unauthorizedHandler);
};
