import { connect } from 'react-redux';

import { logout as logoutAction, ssoLogout } from 'actions/user';

import Header from './Header';

const mapStateToProps = ({
  user: {
    email,
    typeName,
    providersPermission,
  },
}) => ({
  email,
  typeName,
  providersPermission,
});

const mapDispatchToProps = { logoutAction, ssoLogout };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
