import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';
import IdleTimer from 'react-idle-timer';
import PropTypes from 'prop-types';

import { StateLessModal } from 'components/MainModal';
import VisibilityChange from 'components/VisibilityChange';

import getErrorMessage from 'utils/getErrorMessage';

class LogOutModal extends Component {
  constructor() {
    super();
    this.idleTimer = null;
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this.setClock();
  }

  componentDidUpdate(prevProps) {
    const { expAtToken } = this.props;
    if (expAtToken && prevProps.expAtToken !== this.props.expAtToken) {
      this.setClock();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  setClock = () => {
    const { expAtToken, timeOutThreshold } = this.props;
    const duration = expAtToken - timeOutThreshold - Date.now();
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      if (this.idleTimer.getRemainingTime() > 0) {
        this.handlerRefreshToken();
      } else {
        this.onOpenHandler();
      }
    }, duration);
  };

  onOpenHandler = () => this.setState({ isOpen: true });

  onCloseHandler = () => this.setState({ isOpen: false });

  handlerRefreshToken = () => {
    const { refreshToken, refreshTokenAction, logoutAction } = this.props;
    refreshTokenAction({ refreshToken })
      .then(this.onCloseHandler)
      .catch((error) => {
        const message = getErrorMessage(error);
        toast.error(message || 'Something went wrong.');
        logoutAction();
      });
  };

  isTokenActive = () => {
    const { expAtToken, logoutAction } = this.props;
    if (Date.now() > expAtToken) {
      logoutAction();
    }
  };

  render() {
    const { isOpen } = this.state;
    const { children, expAtToken, refreshToken, refreshTokenStatus, timeOutIdle } = this.props;
    const isRefreshingToken = refreshTokenStatus === 'pending';

    return (
      <Fragment>
        {children}
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          debounce={0}
          timeout={timeOutIdle}
        />
        <VisibilityChange onVisibleHandler={this.isTokenActive} />
        <StateLessModal
          isOpen={isOpen}
          contentLabel="Log Off Modal"
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          onCloseHandler={this.onCloseHandler}
        >
          <div className="log-off-modal">
            <div className="log-off-modal__content">
              <div className="text-center">
                <h1 className="text-trout h6 h5-md font-weight-semibold mb-4 mb-md-5 log-off-modal__text">
                  Your online session <br className="d-md-none" /> will expire in:
                </h1>
                <div className="mb-4 mb-md-5 log-off-modal__text log-off-modal__countdown font-weight-light line-height-1">
                  <Countdown
                    date={expAtToken}
                    intervalDelay={1000}
                    precision={0}
                    zeroPadLength={1}
                    onComplete={this.props.logoutAction}
                    renderer={({ minutes, seconds }) => (
                      <span>
                        {minutes} min {seconds} secs
                      </span>
                    )}
                  />
                </div>
                <p className="text-trout mb-6 mb-md-5 log-off-modal__text">
                  Click <span className="font-weight-semibold">“Continue”</span> to keep working
                  <br />
                  or <span className="font-weight-semibold">“Log Out”</span> to end your session
                  now.
                </p>
                <div className="log-off-modal__buttons">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg text-large py-3 mr-md-5 mb-4 mb-md-0"
                    onClick={this.handlerRefreshToken}
                    disabled={isRefreshingToken}
                  >
                    Continue
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-lg text-large py-3"
                    onClick={this.props.logoutAction}>
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </StateLessModal>
      </Fragment>
    );
  }
}

export default LogOutModal;

LogOutModal.propTypes = {
  children: PropTypes.node,
  expAtToken: PropTypes.number.isRequired,
  refreshToken: PropTypes.string.isRequired,
  isRefreshingToken: PropTypes.bool,
};

LogOutModal.defaultProps = {
  isRefreshingToken: false,
  children: <div />,
};
