import React from 'react';
import PropTypes from 'prop-types';

const SelectComponent = ({
  value,
  onChange,
  options,
  valueProp = 'value',
  labelProp = 'label',
}) => (
  <div className="w-auto d-flex">
    <select
      value={value}
      onChange={(evt) => onChange(evt.target.value)}
      className="custom-select custom-select-lg provider-header__select"
    >
      {options && options.map((p) => (
        <option value={p[valueProp]} key={p[valueProp]}>
          {p[labelProp]}
        </option>
      ))}
    </select>
  </div>
);

SelectComponent.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  valueProp: PropTypes.string,
  labelProp: PropTypes.string,
};

SelectComponent.defaultProps = {
  valueProp: 'value',
  labelProp: 'label',
};

export default SelectComponent;
