import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import SearchInput from 'components/Fields/SearchInput';

const SearchForm = ({
  onInputChange,
  suggestionsRequest,
  extractSuggestionsFromResponse,
  shouldRenderSuggestions,
  onSuggestionSelected,
  submitEvent,
  value,
}) => (
  <div className="form-group input-group mb-0">
    <SearchInput
      id="q"
      name="q"
      type="search"
      placeholder="Search for patients"
      className="form-control form-control--search"
      onInputChange={onInputChange}
      suggestionsRequest={suggestionsRequest}
      extractSuggestionsFromResponse={extractSuggestionsFromResponse}
      shouldRenderSuggestions={shouldRenderSuggestions}
      onSuggestionSelected={onSuggestionSelected}
      submitEvent={submitEvent}
      value={value}
    />
    <div className="input-group-prepend">
      <span className="input-group-text">
        <Icon name="magnifying-glass" />
      </span>
    </div>
  </div>
);

export default SearchForm;

SearchForm.propTypes = {
  suggestionsRequest: PropTypes.func.isRequired,
  extractSuggestionsFromResponse: PropTypes.func,
  shouldRenderSuggestions: PropTypes.func,
  submitEvent: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func,
  value: PropTypes.string,
};

SearchForm.defaultProps = {
  value: '',
  extractSuggestionsFromResponse: undefined,
  shouldRenderSuggestions: undefined,
  onSuggestionSelected: undefined,
};
