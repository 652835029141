import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ error }) => (
  <div className="invalid-feedback" role="alert">
    <p className="mb-0">{error}</p>
  </div>
);

ErrorMessage.propTypes = {
  error: PropTypes.string,
};

ErrorMessage.defaultProps = {
  error: '',
};

export default ErrorMessage;
