import { authenticationService, httpMethods, apiUrls } from 'libs';
import { POST } from 'libs/httpMethods';

const { apiCall } = authenticationService;
const { GET } = httpMethods;
const {
  PATIENTS_SEARCH_SUGGESTIONS,
  PATIENTS_SEARCH,
  GET_PROVIDERS,
  CREATE_USER,
  VAULT_PATIENTS_SEARCH_SUGGESTIONS,
  VAULT_PATIENTS_DETAILED,
} = apiUrls.admin;

export const reqSuggestPatients = ({
  params,
}) => apiCall(GET, PATIENTS_SEARCH_SUGGESTIONS, { params });

export const reqSearchPatients = ({
  params,
}) => apiCall(GET, PATIENTS_SEARCH, { params });

export const reqGetProviders = () => apiCall(GET, GET_PROVIDERS);

export const reqCreateUser = ({ providerId, data }) => apiCall(POST, CREATE_USER(providerId), { data });

export const reqVaultSuggestPatients = ({
  params,
}) => apiCall(GET, VAULT_PATIENTS_SEARCH_SUGGESTIONS, { params });

export const reqGetVaultPatientDetail = ({ data }) => apiCall(POST, VAULT_PATIENTS_DETAILED, { data });
