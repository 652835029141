import { path } from 'ramda';
import { logout } from '../actions/user';

/**
 * redux middleware to trigger a logout action in case a http call (promise) returned a 401 status.
 * which in turn means the token used in invalid.
 */
const unauthorizedMiddleware = (store) => (next) => (action) => {
  const { type, payload } = action;

  if (type && type.includes('REJECTED')) {
    const status = path(['response', 'status'])(payload);
    if (status === 401) {
      store.dispatch(logout());
    }
  }
  next(action);
};

export default unauthorizedMiddleware;
