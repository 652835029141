import React from 'react';
import PropTypes from 'prop-types';

const DefaultButton = ({ children, ...props }) => <button type="button" {...props}>{children}</button>;

export default class Pagination extends React.Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pages } = this.props;
    if (pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages),
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    }
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    } if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }
    return [1, 2, 3, 4, 5, total];
  };

  changePage(page) {
    const { pages, page: propsPage, onPageChange } = this.props;
    const activePage = propsPage + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, pages),
    });

    onPageChange(page - 1);
  }

  render() {
    const { visiblePages } = this.state;
    const { pages, page, previousText, nextText } = this.props;
    const activePage = page + 1;
    const prevDisabled = activePage === 1;
    const nextDisabled = activePage === pages;

    return (
      <ul className="pagination justify-content-center py-4 mb-0">
        {!prevDisabled && (
          <li className="page-item">
            <DefaultButton
              className="page-link text-persian-green"
              onClick={() => {
                if (activePage === 1) return;
                this.changePage(activePage - 1);
              }}
              disabled={activePage === 1}
            >
              {previousText}
            </DefaultButton>
          </li>
        )}
        {visiblePages.map((page, index, array) => {
          const isActive = activePage === page ? 'active' : '';

          return (
            <li className={`page-item ${isActive}`} key={`pagination_${page}`}>
              <DefaultButton
                key={page}
                className="page-link"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={this.changePage.bind(null, page)}
              >
                {array[index - 1] + 2 < page ? `...${page}` : page}
              </DefaultButton>
            </li>
          );
        })}
        {!nextDisabled && (
          <li className="page-item">
            <DefaultButton
              className="page-link text-persian-green"
              onClick={() => {
                if (activePage === pages) return;
                this.changePage(activePage + 1);
              }}
              disabled={activePage === pages}
            >
              {nextText}
            </DefaultButton>
          </li>
        )}
      </ul>
    );
  }
}

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  previousText: PropTypes.string,
  nextText: PropTypes.string,
};

Pagination.defaultProps = {
  previousText: 'Previous',
  nextText: 'Next',
};
