import React, { useEffect } from 'react';
import { isNil } from 'ramda';
import { toast } from 'react-toastify';

import Navigation from 'components/Navigation';
import LogOutModal from 'components/LogOutModal';
import Header from 'components/Header';

import getErrorMessage from 'utils/getErrorMessage';

const App = ({
  children, noLoggedIn, userType, providerFrom,
}) => (
  <div className="provider-layout d-flex flex-row">
    <Navigation isLoggedIn={!noLoggedIn} userType={userType} providerFrom={providerFrom} />
    <div className="provider-layout__content d-flex flex-column flex-fill position-relative">
      {!noLoggedIn && <Header />}
      <div className="d-flex flex-column flex-fill overflow-auto">
        {children}
      </div>
    </div>
  </div>
);

const Layout = ({
  children, noLoggedIn, getUser, email, userType, providerFrom, startSpinner, stopSpinner,
}) => {
  const onFailure = (error) => {
    const message = getErrorMessage(error);
    stopSpinner();
    toast.error(message);
  };
  useEffect(() => {
    if (!noLoggedIn && isNil(email)) {
      startSpinner();

      getUser()
        .then(stopSpinner)
        .catch(onFailure);
    }
  }, [noLoggedIn]);

  if (noLoggedIn) {
    return (
      <App noLoggedIn={noLoggedIn} userType={userType} providerFrom={providerFrom}>
        {children}
      </App>
    );
  }

  return (
    <LogOutModal>
      <App noLoggedIn={noLoggedIn} userType={userType}>
        {children}
      </App>
    </LogOutModal>
  );
};

export default Layout;
