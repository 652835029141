import { connect } from 'react-redux';

import Spinner from './Spinner';

const mapStateToProps = ({ spinner: { pendingTask } }) => ({ pendingTask });

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Spinner);
