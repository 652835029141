// VERIFICATION CODE 2FA
export const TWO_FACTOR_CODE_VERIFICATION = 'v1/sso/auth/2fa';

// LOGIN
export const LOGIN = 'v1/sso/auth';
export const LOGIN_ADMIN = 'v1/sso/auth/payzenadmin';
export const EXCHANGE_CODE = 'v1/sso/exchange-code';
export const GET_AUTH_STRATEGIES = 'v1/auth/strategies';
export const SET_NEW_PASSWORD = 'v1/auth/set-password';

// LOGOUT
export const LOGOUT = 'v1/sso/logout';

// REFRESH TOKEN
export const REFRESH_TOKEN = 'v1/sso/auth/refresh-auth';

// USER
export const GET_USER = 'v1/user';
export const UPDATE_PASSWORD = 'v1/sso/user/update-password';
export const GET_PROVIDER_PERMISSIONS = 'v1/users/provider-permissions';

// PATIENTS
export const SEARCH_PATIENTS = (providerId, organizationId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return (organizationId ? `v1/organizations/${organizationId}/patients` : `v1/providers/${id}/patients`);
};

export const GET_OFFER_DATA = 'v1/providers/offer-data';
export const UPDATE_MRN = 'v1/providers/update-mrn';
export const CONFIRM_OFFER = ({ providerId, offerId }) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return `v1/providers/${id}/confirmOffer/${offerId}`;
};

// FINANCIALS
export const GET_PROVIDER_PAYMENTS = (providerId, organizationId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return (organizationId ? `v1/organizations/${organizationId}/organization-payments` : `v1/providers/${id}/provider-payments`);
};

export const GET_PROVIDER_PAYMENTS_CSV = (providerId, organizationId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return (organizationId
    ? `v1/organizations/${organizationId}/organization-payments/detailed-csv`
    : `v1/providers/${id}/provider-payments/detailed-csv`);
};

// DATA EXCHANGE
export const GET_PROVIDER_UPLOADED_FILES = (providerId, organizationId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return (organizationId ? `v1/organizations/${organizationId}/uploaded-files` : `v1/providers/${id}/uploaded-files`);
};

export const GET_PROVIDER_REPORTS_CREDENTIALS = (providerId, organizationId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return (organizationId ? `v1/organizations/${organizationId}/reports-credentials` : `v1/providers/${id}/reports-credentials`);
};

export const GET_PROVIDER_LIST_REPORTS = (providerId, organizationId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return (organizationId ? `v1/organizations/${organizationId}/list-reports` : `v1/providers/${id}/list-reports`);
};

//  REPORTS
export const GET_PROVIDER_REPORTS = (providerId, organizationId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return (organizationId ? `v1/organizations/${organizationId}/reports` : `v1/providers/${id}/reports`);
};

// INVITES
export const GET_PATIENT_INVITES = (providerId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return `v1/providers/${id}/patient-invites`;
};

export const CREATE_PATIENT_INVITES = (providerId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return `v1/providers/${id}/patient-invites`;
};

export const UPDATE_PATIENT_INVITE = (providerId, patientInviteId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return `v1/providers/${id}/patient-invites/${patientInviteId}`;
};

export const EXPIRE_PATIENT_INVITE = (providerId, patientInviteId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return `v1/providers/${id}/patient-invites/${patientInviteId}/expire`;
};

export const PROLONG_PATIENT_INVITE = (providerId, patientInviteId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return `v1/providers/${id}/patient-invites/${patientInviteId}/prolong`;
};
export const GET_PATIENT_INVITES_STATS = (providerId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return `v1/providers/${id}/patient-invites/stats`;
};

export const GET_PROVIDER_WEBSITE = (subdomain) => `v1/providers/website?subdomain=${subdomain}`;

// ORGANIZATION
export const GET_ORGANIZATION_PROVIDERS = (organizationId) => `v1/organizations/${organizationId}/providers`;
export const GET_DEFAULT_PROVIDER = (organizationId) => `v1/organizations/${organizationId}`;

// ADMIN / CUSTOMER SUPPORT
export const admin = Object.freeze({
  PATIENTS_SEARCH_SUGGESTIONS: 'v1/admin/patients/suggest',
  PATIENTS_SEARCH: 'v1/admin/patients',
  GET_PROVIDERS: 'v1/admin/providers',
  CREATE_USER: (providerId) => `v1/providers/${providerId}/users`,
  VAULT_PATIENTS_SEARCH_SUGGESTIONS: 'v1/admin/vault/patients',
  VAULT_PATIENTS_DETAILED: 'v1/admin/vault/patients/detailed',
});

// MESSAGING CENTRE
export const GET_PROVIDER_RELEASE_AUTOMATION_ID = (providerId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return `v1/providers/${id}/manual-release/release-automation-id`;
};

export const GET_PROVIDER_MESSAGING_USERS = (providerId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return `v1/providers/${id}/manual-release/users`;
};

export const SEND_MESSAGE_TO_USER = (providerId, acUserId) => {
  const currentProviderId = sessionStorage.getItem('PROVIDER_ID');
  const id = currentProviderId || providerId;
  return `v1/providers/${id}/manual-release/users/${acUserId}`;
};
