import React from 'react';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { Link } from 'react-router-dom';
import { equals } from 'ramda';

import Icon from 'components/Icon';
import SelectOrgProviderForm from 'components/SelectOrgProviderForm';
import SelectProviderForm from 'components/SelectProviderForm';

const Header = ({
  email,
  typeName,
  logoutAction,
  ssoLogout,
}) => {
  const onLogout = () => {
    ssoLogout();
    logoutAction();
  };

  return (
    <header className="provider-header d-flex justify-content-center px-4 sticky-top">
      <div className="d-flex justify-content-end align-items-center w-100">
        {equals(typeName, 'organization_user') && (
          <>
            <SelectOrgProviderForm />
            <span className="text-french-gray mx-3">|</span>
          </>
        )}
        <SelectProviderForm />
        <Dropdown>
          <DropdownTrigger className="text-decoration-none cursor-pointer d-flex align-items-center">
            <span data-private>{email}</span>
            <Icon name="caret-down" className="ml-1 text-xsmaller" />
          </DropdownTrigger>
          <DropdownContent className="mt-3 p-4">
            <ul className="list-unstyled mb-0">
              <li>
                <Link to="/my-account" className="text-decoration-none d-inline-flex align-items-center">
                  <Icon name="gear" className="mr-3" />
                  <span>My Account</span>
                </Link>
              </li>
              <hr />
              <li>
                <button
                  type="button"
                  onClick={onLogout}
                  className="bnt btn-link bg-transparent border-0 text-decoration-none d-inline-flex align-items-center p-0"
                >
                  <Icon name="logout" className="mr-3" />
                  <span>Logout</span>
                </button>
              </li>
            </ul>
          </DropdownContent>
        </Dropdown>
      </div>
    </header>
  );
};

export default Header;
