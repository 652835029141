import React, { useEffect, useState } from 'react';
import queryString from 'qs';
import { useHistory } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import {
  compose, isEmpty, map, path, trim,
} from 'ramda';

import Layout from 'components/Layout';
import Icon from 'components/Icon';
import SearchForm from 'components/VaultUsers/SearchForm';
import Table from 'components/VaultUsers/Table';
import SummaryResultTable from 'components/VaultUsers/SummaryResultTable';
import ConfirmModal from 'components/VaultUsers/ConfirmModal';
import PatientInfoModal from 'components/VaultUsers/PatientInfoModal';
import { providerSelectCustomStyles, providerSelectFilterConfig } from 'AdminSite/pages/PatientsSearch/PatientsSearch.config';
import { reqVaultSuggestPatients } from 'AdminSite/api/admin';

const VaultUsers = ({
  adminGetProviders,
  providers,
  startSpinner,
  stopSpinner,
}) => {
  const history = useHistory();
  const { q: searchTermFromQueryParam = '', providerId: providerIdFromQueryParam } = queryString.parse(
    history.location.search, { ignoreQueryPrefix: true },
  );

  const [provider, setProvider] = useState((providers.length && providers[0]) || null);
  const [searchInput, setSearchInput] = useState(searchTermFromQueryParam);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPatient, setCurrentPatient] = useState({});
  const [vaultPatientResponse, setVaultPatientResponse] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPatientResponse, setOpenPatientResponse] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (providerIdFromQueryParam && providers.length) {
      const selectedProvider = providers.find((p) => p.providerId === providerIdFromQueryParam);
      if (selectedProvider) setProvider(selectedProvider);
    }
  }, [providers]);

  useEffect(() => {
    startSpinner();
    adminGetProviders().finally(stopSpinner);
  }, []);

  const handleSearchResponse = (res) => {
    const { patients, total } = res.data;
    setSearchResults(patients);
    setResultsCount(patients.length);
    setTotalCount(total);
  };

  const suggestionsRequest = (value) => reqVaultSuggestPatients({
    params: {
      q: trim(value || ''),
      providerId: provider.providerId,
    },
  });

  useEffect(() => {
    if (searchTermFromQueryParam && provider) {
      startSpinner();

      reqVaultSuggestPatients({ params: { q: searchTermFromQueryParam, providerId: provider.providerId } })
        .then(handleSearchResponse)
        .finally(stopSpinner);
    }
  }, [provider, searchTermFromQueryParam]);

  const pushHistoryWithSearchParams = (searchTerm) => history.push(`/admin/vault-users?q=${searchTerm}&providerId=${provider.providerId}`);

  const onSuggestionSelected = (suggestionValue) => pushHistoryWithSearchParams(suggestionValue.trim());

  const submitEvent = (input) => {
    setSearchInput(input);
    const trimmedInput = trim(input || '');
    if (provider) {
      pushHistoryWithSearchParams(trimmedInput);
      reqVaultSuggestPatients({ params: { q: trimmedInput || undefined, providerId: provider.providerId } })
        .then(handleSearchResponse);
    }
  };

  const shouldRenderSuggestions = (v) => provider && v && v.trim().length > 2;

  const extractSuggestionsFromResponse = compose(
    map((patient) => ({
      firstName: patient.payzenPatientFirstName,
      lastName: patient.payzenPatientLastName,
    })),
    path(['data', 'patients']),
  );

  return (
    <Layout>
      <div className="flex-column h-100">
        <div>
          <div className="text-center">
            <h1 className="h4 font-weight-normal letter-spacing mb-4">Search vault users</h1>
            <div className="text-tuna letter-spacing mb-4">
              Enter name to find patients on vault file.
            </div>
          </div>
        </div>
        {providers.length ? (
          <div className="row justify-content-md-center">
            <div className="col-md-3" data-testid="provider-select">
              <Select
                value={provider}
                onChange={setProvider}
                options={providers}
                getOptionLabel={(option) => option.providerName}
                getOptionValue={(option) => option.providerId}
                styles={providerSelectCustomStyles}
                filterOption={createFilter(providerSelectFilterConfig)}
              />
            </div>
            <div className="col-md-5">
              <SearchForm
                onInputChange={setSearchInput}
                suggestionsRequest={suggestionsRequest}
                extractSuggestionsFromResponse={extractSuggestionsFromResponse}
                shouldRenderSuggestions={shouldRenderSuggestions}
                onSuggestionSelected={onSuggestionSelected}
                submitEvent={submitEvent}
                value={searchInput}
                data-testid="search-input"
              />
            </div>
          </div>
        ) : null}
        {!isEmpty(searchResults) ? (
          <>
            <SummaryResultTable
              searchTerm={searchTermFromQueryParam}
              resultsCount={resultsCount}
              totalCount={totalCount}
            />
            <Table
              data={searchResults}
              setOpen={setOpen}
              setCurrentPatient={setCurrentPatient}
            />
            <ConfirmModal
              isOpen={open}
              setIsOpen={setOpen}
              patient={currentPatient}
              setVaultPatientResponse={setVaultPatientResponse}
              setOpenPatientResponse={setOpenPatientResponse}
              startSpinner={startSpinner}
              stopSpinner={stopSpinner}
            />
            <PatientInfoModal
              isOpen={openPatientResponse}
              setIsOpen={setOpenPatientResponse}
              vaultPatientResponse={vaultPatientResponse}
            />
          </>
        ) : null}
        {isEmpty(searchResults) && searchTermFromQueryParam ? (
          <div className="flex-fill d-flex flex-column justify-content-center align-items-center p-2 py-12">
            <p className="text-large text-tuna mb-12 text-center">
              <Icon name="band-aids" className="search-patients-results__icon mb-5" />
              <br />
              No results found for
              {' '}
              <b>{searchInput}</b>
            </p>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default VaultUsers;
