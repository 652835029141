import { createReducer } from '@reduxjs/toolkit';
import {
  getProviderPayments,
  downloadProviderPaymentsCSV,
  getProviderUploadedFiles,
  getProviderReportsCredentials,
  getProviderListReports,
  getProviderReports,
  getPatientInvites,
  createPatientInvite,
  updatePatientInvite,
  expirePatientInvite,
  prolongPatientInvite,
  getPatientsInvitesStats,
  getAuthStrategies,
  getProviderMessagingUsers,
  sendMessageToUser,
  getManualReleaseAutomationId,
} from 'actions/provider';

import { PENDING, FULFILLED, REJECTED } from 'libs/promiseStatuses';
import { pathOr } from 'ramda';

const initialState = {
  authStrategies: [],
  payments: {
    providerPayments: [],
    total: null,
    startIndex: null,
    endIndex: null,
    page: null,
    totalPages: null,
  },
  dataExchange: {
    uploadedFiles: [],
    downloadedFiles: [],
  },
  messagingCentre: {
    allUsersFetched: false,
    users: [],
  },
  reports: {
    patientCount: null,
    totalAmountEnrolled: null,
    totalAmountPreFunded: null,
    trend: [],
    trendData: [],
    trendCategories: [],
  },
  invites: {
    patientInvites: [],
    total: null,
    startIndex: null,
    endIndex: null,
    page: null,
    totalPages: null,
    stats: {
      totalClicked: null,
      totalExpired: null,
      totalInProgress: null,
      totalInvites: null,
      totalInvitesAmount: null,
    },
  },
  csvStatus: null,
  reportsCredentialsUrl: null,
};

const getMessageSentAcUserIds = () => JSON.parse(localStorage.getItem('messageSentAcUserIds')) || [];

const checkIfUserMessageWasSent = (acUserId) => {
  const messageSentAcUserIds = getMessageSentAcUserIds();
  return messageSentAcUserIds.includes(acUserId);
};

const addNewAcUserIdToSentList = (acUserId) => {
  const currentList = getMessageSentAcUserIds();
  const newList = [...currentList, acUserId];
  localStorage.setItem('messageSentAcUserIds', JSON.stringify(newList));
};

export default createReducer(initialState, {
  [String(getAuthStrategies.pending)]: (state) => ({
    ...state,
  }),
  [String(getAuthStrategies.fulfilled)]: (state, action) => ({
    ...state,
    authStrategies: action.payload,
  }),
  [String(getAuthStrategies.rejected)]: (state) => ({
    ...state,
  }),

  [String(getProviderPayments.pending)]: (state) => ({
    ...state,
  }),
  [String(getProviderPayments.fulfilled)]: (state, action) => ({
    ...state,
    payments: { ...action.payload },
  }),
  [String(getProviderPayments.rejected)]: (state) => ({
    ...state,
  }),

  [String(getPatientInvites.pending)]: (state) => ({
    ...state,
  }),
  [String(getPatientInvites.fulfilled)]: (state, action) => ({
    ...state,
    invites: {
      ...state.invites,
      ...action.payload,
    },
  }),
  [String(getPatientInvites.rejected)]: (state) => ({
    ...state,
  }),

  [String(createPatientInvite.pending)]: (state) => ({
    ...state,
  }),
  [String(createPatientInvite.fulfilled)]: (state) => ({
    ...state,
  }),
  [String(createPatientInvite.rejected)]: (state) => ({
    ...state,
  }),

  [String(updatePatientInvite.pending)]: (state) => ({
    ...state,
  }),
  [String(updatePatientInvite.fulfilled)]: (state, action) => ({
    ...state,
    invites: {
      patientInvites: state.invites.patientInvites.map(
        (invite) => (invite.patientInviteId === action.payload.patientInviteId
          ? { ...invite, ...action.payload } : invite),
      ),
    },
  }),
  [String(updatePatientInvite.rejected)]: (state) => ({
    ...state,
  }),

  [String(expirePatientInvite.pending)]: (state) => ({
    ...state,
  }),
  [String(expirePatientInvite.fulfilled)]: (state, action) => ({
    ...state,
    invites: {
      patientInvites: state.invites.patientInvites.map(
        (invite) => (invite.patientInviteId === action.payload.patientInviteId
          ? { ...invite, ...action.payload } : invite),
      ),
    },
  }),
  [String(expirePatientInvite.rejected)]: (state) => ({
    ...state,
  }),

  [String(prolongPatientInvite.pending)]: (state) => ({
    ...state,
  }),
  [String(prolongPatientInvite.fulfilled)]: (state, action) => ({
    ...state,
    invites: {
      patientInvites: state.invites.patientInvites.map(
        (invite) => (invite.patientInviteId === action.payload.patientInviteId
          ? { ...invite, ...action.payload } : invite),
      ),
    },
  }),
  [String(prolongPatientInvite.rejected)]: (state) => ({
    ...state,
  }),

  [String(getPatientsInvitesStats.pending)]: (state) => ({
    ...state,
  }),
  [String(getPatientsInvitesStats.fulfilled)]: (state, action) => ({
    ...state,
    invites: {
      ...state.invites,
      stats: action.payload,
    },
  }),
  [String(getPatientsInvitesStats.rejected)]: (state) => ({
    ...state,
  }),

  [String(downloadProviderPaymentsCSV.pending)]: (state) => ({
    ...state,
    csvStatus: PENDING,
  }),
  [String(downloadProviderPaymentsCSV.fulfilled)]: (state) => ({
    ...state,
    csvStatus: FULFILLED,
  }),
  [String(downloadProviderPaymentsCSV.rejected)]: (state) => ({
    ...state,
    csvStatus: REJECTED,
  }),

  [String(getProviderUploadedFiles.pending)]: (state) => ({
    ...state,
  }),
  [String(getProviderUploadedFiles.fulfilled)]: (state, action) => ({
    ...state,
    dataExchange: { ...action.payload },
  }),
  [String(getProviderUploadedFiles.rejected)]: (state) => ({
    ...state,
  }),
  [String(getProviderMessagingUsers.pending)]: (state) => ({
    ...state,
  }),
  [String(getProviderMessagingUsers.fulfilled)]: (state, action) => {
    const manualReleaseUsers = pathOr([], ['payload', 'manualReleaseUsers'], action);
    const currentUsers = state.messagingCentre.users;
    const newUsers = manualReleaseUsers.map((user) => ({ ...user, messageSent: checkIfUserMessageWasSent(user.acUserId) }));
    const allUsersFetched = !manualReleaseUsers.length;

    return {
      ...state,
      messagingCentre: {
        ...state.messagingCentre, users: [...currentUsers, ...newUsers], allUsersFetched,
      },
    };
  },
  [String(getProviderMessagingUsers.rejected)]: (state) => ({
    ...state,
  }),
  [String(getManualReleaseAutomationId.pending)]: (state) => ({
    ...state,
  }),
  [String(getManualReleaseAutomationId.fulfilled)]: (state, action) => ({
    ...state,
    messagingCentre: { ...state.messagingCentre, manualReleaseAutomationId: action.payload.manualReleaseAutomationId },
  }),
  [String(getManualReleaseAutomationId.rejected)]: (state) => ({
    ...state,
  }),
  [String(sendMessageToUser.pending)]: (state) => ({
    ...state,
  }),
  [String(sendMessageToUser.fulfilled)]: (state, action) => {
    const updatedAcUserId = action.payload.activeCampaignId;
    const updatedUsers = state.messagingCentre.users.map(
      (user) => ({ ...user, messageSent: user.acUserId === updatedAcUserId || checkIfUserMessageWasSent(user.acUserId) }),
    );
    addNewAcUserIdToSentList(updatedAcUserId);
    return {
      ...state,
      messagingCentre: {
        ...state.messagingCentre,
        users: updatedUsers,
      },
    };
  },
  [String(sendMessageToUser.rejected)]: (state) => ({
    ...state,
  }),
  [String(getProviderReportsCredentials.pending)]: (state) => ({
    ...state,
  }),
  [String(getProviderReportsCredentials.fulfilled)]: (state, action) => ({
    ...state,
    reportsCredentialsUrl: action.payload.url,
  }),
  [String(getProviderReportsCredentials.rejected)]: (state) => ({
    ...state,
  }),

  [String(getProviderListReports.pending)]: (state) => ({
    ...state,
  }),
  [String(getProviderListReports.fulfilled)]: (state, action) => ({
    ...state,
    dataExchange: {
      ...state.dataExchange,
      downloadedFiles: action.payload.reports,
    },
  }),
  [String(getProviderListReports.rejected)]: (state) => ({
    ...state,
  }),

  [String(getProviderReports.pending)]: (state) => ({
    ...state,
  }),
  [String(getProviderReports.fulfilled)]: (state, action) => ({
    ...state,
    reports: { ...action.payload },
  }),
  [String(getProviderReports.rejected)]: (state) => ({
    ...state,
  }),
});
