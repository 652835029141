import { connect } from 'react-redux';

import { exchangeAuthCode } from 'actions/user';
import {
  startSpinner,
  stopSpinner,
} from 'actions/spinner';

import SSOSignin from './SSOSignin';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  exchangeAuthCode,
  startSpinner,
  stopSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SSOSignin);
