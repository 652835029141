import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

const StateLessModal = (props) => {
  const {
    isOpen,
    contentLabel,
    children,
    modalClass,
    modalContentClass,
    onAfterOpen,
    onCloseHandler,
    ...rest
  } = props;
  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onCloseHandler}
      className={`main-modal ${modalClass}`}
      overlayClassName="main-modal__overlay"
      contentLabel={contentLabel}
      ariaHideApp={false}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      role="dialog"
      closeTimeoutMS={300}
      {...rest}
    >
      <div className={`main-modal__content ${modalContentClass}`}>{children}</div>
    </ReactModal>
  );
};

export default StateLessModal;

StateLessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  contentLabel: PropTypes.string.isRequired,
  modalClass: PropTypes.string,
  modalContentClass: PropTypes.string,
  onAfterOpen: PropTypes.func,
  onCloseHandler: PropTypes.func.isRequired,
};

StateLessModal.defaultProps = {
  modalClass: '',
  modalContentClass: '',
  onAfterOpen: () => {},
};
