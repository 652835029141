import React, { useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  compose, isEmpty, filter, values,
} from 'ramda';
import { toast } from 'react-toastify';

import Layout from 'components/Layout';
import { Input } from 'components/Fields';
import Button from 'components/Button';

import { capitalize } from 'utils/capitalize';
import { createUserForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';

import { providerSelectFilterConfig } from '../PatientsSearch/PatientsSearch.config';

const USER_ROL = [
  {
    label: 'Admin',
    role: 'ADMIN',
  },
  {
    label: 'Manager',
    role: 'MANAGER',
  },
  {
    label: 'Advocate',
    role: 'ADVOCATE',
  },
];

const CreateUsers = ({
  createUser,
  adminGetProviders,
  startSpinner,
  stopSpinner,
  providers,
}) => {
  const initialValues = Object.freeze({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    providerId: null,
    role: null,
  });

  useEffect(() => {
    startSpinner();
    adminGetProviders().finally(stopSpinner);
  }, []);

  const onSubmit = (values, actions) => {
    const { providerId, ...data } = values;

    startSpinner();

    createUser({ providerId, data })
      .then(() => {
        actions.setSubmitting(false);
        actions.resetForm();
        stopSpinner();
        toast.success('User created!');
      })
      .catch((error) => {
        actions.setSubmitting(false);
        const message = getErrorMessage(error);
        stopSpinner();
        toast.error(message);
      });
  };

  return (
    <Layout>
      <div className="invites d-flex flex-column h-100">
        <div className="invites__header text-center pb-7">
          <div className="">
            <h1 className="h4 text-charade mb-0">Create users</h1>
          </div>
        </div>
        <div className="wrapper px-4">
          <div className="">
            <Formik
              initialValues={initialValues}
              validationSchema={createUserForm}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ isSubmitting, values: formData, errors }) => {
                const isDataComplete = !compose(isEmpty, filter(isEmpty), values)(formData);
                const withErrors = !isEmpty(errors);
                const isDisabled = isSubmitting || isDataComplete || withErrors;

                return (
                  <Form>
                    <div className="mb-4">
                      <div className="row smaller-gutters">
                        <div className="col-sm-6">
                          <Field
                            id="firstName"
                            type="text"
                            name="firstName"
                            label="First Name"
                            component={Input}
                            onChangeHandler={capitalize}
                            isRequired
                          />
                        </div>
                        <div className="col-sm-6">
                          <Field
                            id="lastName"
                            type="text"
                            name="lastName"
                            label="Last Name"
                            component={Input}
                            onChangeHandler={capitalize}
                            isRequired
                          />
                        </div>
                        <div className="col-sm-6">
                          <Field
                            id="email"
                            type="email"
                            name="email"
                            label="Email"
                            component={Input}
                            isRequired
                          />
                        </div>
                        <div className="col-sm-6">
                          <Field
                            id="phone"
                            type="tel"
                            name="phone"
                            label="Mobile Number"
                            component={Input}
                            isRequired
                          />
                        </div>
                        <div className="col-sm-6">
                          <Field
                            id="providerId"
                            type="select"
                            name="providerId"
                            label="Select Provider"
                            component={Input}
                            options={providers}
                            filterOption={providerSelectFilterConfig}
                            getOptionLabel={(option) => option.providerName}
                            getOptionValue={(option) => option.providerId}
                            isRequired
                          />
                        </div>
                        <div className="col-sm-6">
                          <Field
                            id="role"
                            type="select"
                            name="role"
                            label="Select Role"
                            component={Input}
                            options={USER_ROL}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.role}
                            isRequired
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        type="primary"
                        className="px-12"
                        isSubmit
                        disabled={isDisabled}
                      >
                        Create user
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateUsers;
