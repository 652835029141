import React from 'react';
import PropTypes from 'prop-types';

const DownloadLink = ({
  filename,
  className,
  children,
  disabled,
  downloadAction,
}) => {
  const handleAction = async () => {
    const { value: href } = await downloadAction();

    const aElement = window.document.createElement('a');
    aElement.style.display = 'none';

    aElement.download = filename;
    aElement.href = href;

    aElement.click();
  };

  return (
    <>
      <button
        type="button"
        disabled={disabled}
        onClick={handleAction}
        className={className}
      >
        {children}
      </button>
    </>
  );
};

DownloadLink.defaultProps = {
  className: '',
  disabled: false,
};

DownloadLink.propTypes = {
  children: PropTypes.node.isRequired,
  downloadAction: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DownloadLink;
