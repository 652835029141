import { createAsyncAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';

import {
  reqGetProviders,
  reqCreateUser,
} from 'AdminSite/api/admin';

export const adminGetProviders = createAsyncAction(
  'ADMIN_GET_PROVIDERS',
  () => reqGetProviders().then(prop('data')),
);

export const createUser = createAsyncAction(
  'ADMIN_CREATE_USER',
  ({ providerId, data }) => reqCreateUser({ providerId, data }).then(prop('data')),
);
