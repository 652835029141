import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ value }) => {
  const state = {
    optionsRadial: {
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          track: {
            background: '#EEEEEF',
            margin: 0,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              formatter(val) {
                return `${val}%`;
              },
              color: '#000',
              fontSize: '40px',
              show: true,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 1,
          gradientToColors: ['#2CD6C1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
    },
    seriesRadial: value,
  };

  return <Chart options={state.optionsRadial} series={state.seriesRadial} type="radialBar" />;
};

export default LineChart;
