import { connect } from 'react-redux';

import { getOrganizationProviders, getDefaultProvider } from 'actions/user';
import { startSpinner, stopSpinner } from 'actions/spinner';

import SelectOrgProviderForm from './SelectOrgProviderForm';

const mapStateToProps = ({ user: { providers } }) => ({ providers });

const mapDispatchToProps = {
  getOrganizationProviders,
  startSpinner,
  stopSpinner,
  getDefaultProvider,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectOrgProviderForm);
