import React from 'react';
import NumberFormat from 'react-number-format';

const CurrencyInput = ({
  id, placeholder, className, disabled, name, setField, value,
}) => (
  <NumberFormat
    id={id}
    name={name}
    thousandSeparator
    className={className}
    placeholder={placeholder}
    onValueChange={(val) => setField(name, val.value)}
    disabled={disabled}
    value={value}
  />
);

export default CurrencyInput;
