import { loggedIn, getProfile } from 'libs/authenticationService';
import { pathOr } from 'ramda';

const getProviderId = () => {
  const profile = loggedIn() && getProfile();
  const providerId = pathOr(
    sessionStorage.getItem('providerId'),
    ['providerUser', 'providerId'],
  )(profile);

  return providerId;
};

export default getProviderId;
