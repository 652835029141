import React from 'react';

import Icon from 'components/Icon';

const StatusIcon = ({ className, iconName, text }) => (
  <span className={`d-inline-flex align-items-center invites__status ${className}`}>
    <div className="invites__status__icon d-flex justify-content-center align-items-center">
      <Icon name={iconName} className="text-white" />
    </div>
    <span>{text}</span>
  </span>
);

export default StatusIcon;
