import { Component } from 'react';
import PropTypes from 'prop-types';

let hidden, visibilityChange;
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

class VisibilityChange extends Component {
  componentDidMount() {
    if ('hidden' in document) {
      document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    } else {
      this.interval = setInterval(this.onHasFocus, 200);
    }
  }

  componentWillUnmount() {
    if ('hidden' in document) {
      document.removeEventListener(visibilityChange, this.handleVisibilityChange, false);
    } else {
      clearInterval(this.interval);
    }
  }

  handleVisibilityChange = () => {
    if (!document[hidden]) {
      this.props.onVisibleHandler();
    }
  };

  onHasFocus = () => {
    if (document.hasFocus()) {
      this.props.onVisibleHandler();
    }
  };

  render() {
    return null;
  }
}

export default VisibilityChange;

VisibilityChange.propTypes = {
  onVisibleHandler: PropTypes.func,
};

VisibilityChange.defaultProps = {
  onVisibleHandler: () => {},
};
