import React from 'react';
import { useTable } from 'react-table';
import { ReallySimpleInfiniteScroll } from 'react-really-simple-infinite-scroll';

const Table = ({
  columns, data, fetchData, hasMore, isInfiniteLoading,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
  );

  return (
    <ReallySimpleInfiniteScroll
      hasMore={hasMore}
      length={data.length}
      loadingComponent={(
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      isInfiniteLoading={isInfiniteLoading}
      onInfiniteLoad={fetchData}
      style={{ overflow: 'visible' }}
    >
      <div className="table-custom table-custom--financials">
        <div className="table-custom__head">
          <table className="table table-striped mb-0" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className="text-tuna text-small font-weight-normal">
                      <span className="align-middle">{column.render('Header')}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          </table>
        </div>
        <div className="table-custom__body">
          <table className="table table-striped mb-0" {...getTableProps()}>
            <tbody {...getTableBodyProps()}>
              {rows.map(
                (row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        </div>
      </div>
      {!hasMore && (
        <>
          <hr className="m-0" />
          <div className="text-center py-5">
            <p className="text-xlarge mb-0">
              There are no
              {' '}
              {data.length && ('more ')}
              results
            </p>
          </div>
        </>
      )}
    </ReallySimpleInfiniteScroll>
  );
};

export default Table;
