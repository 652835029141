export const exampleMock = { key: 'hello world!' };

export const dataBilling = [{
  status: 'Success',
  downloadTimeStamp: 'Jan 13, 2020 at 7:02PM EST',
  fileSize: '10',
  records: '4,012',
}, {
  status: 'Abort',
  downloadTimeStamp: 'Jan 13, 2020 at 5:23PM EST',
  fileSize: 'NA',
  records: 'NA',
}, {
  status: 'Success',
  downloadTimeStamp: 'Jan 10, 2020 at 6:34PM EST',
  fileSize: '9',
  records: '3,529',
}, {
  status: 'Success',
  downloadTimeStamp: 'Jan 9, 2020 at 7:05PM EST',
  fileSize: '10',
  records: '3,895',
}];

export const dataEnrollment = [{
  status: 'Success',
  downloadTimeStamp: 'Jan 13, 2020 at 8:03AM EST',
  fileSize: '10',
  records: '2,250',
}, {
  status: 'Success',
  downloadTimeStamp: 'Jan 10, 2020 at 9:00AM EST',
  fileSize: '10',
  records: '2,280',
}, {
  status: 'Abort',
  downloadTimeStamp: 'Jan 10, 2020 at 8:40AM EST',
  fileSize: 'NA',
  records: 'NA',
}, {
  status: 'Success',
  downloadTimeStamp: 'Jan 9, 2020 at 8:55AM EST',
  fileSize: '10',
  records: '2,276',
}];

export const dataFinancials = [{
  createdAt: '1/13/2020',
  transactionId: '28574989',
  fromAccount: 'Payzen WF - 1234',
  toAccount: 'Sutter H22 - 4567',
  noOfPlans: '350',
  avgYield: '62%',
  amount: '$291,400.00',
  status: 'PENDING',
}, {
  createdAt: '1/10/2020',
  transactionId: '28574988',
  fromAccount: 'Payzen WF - 1234',
  toAccount: 'Sutter H22 - 4567',
  noOfPlans: '540',
  avgYield: '60%',
  amount: '$360,000.00',
  status: 'PAYMENT_CLEARED',
}, {
  createdAt: '1/9/2020',
  transactionId: '28574987',
  fromAccount: 'Payzen WF - 1234',
  toAccount: 'Sutter H22 - 4567',
  noOfPlans: '340',
  avgYield: '61%',
  amount: '$268,400.00',
  status: 'PAYMENT_CLEARED',
}, {
  createdAt: '1/8/2020',
  transactionId: '28574986',
  fromAccount: 'Payzen WF - 1234',
  toAccount: 'Sutter H22 - 4567',
  noOfPlans: '420',
  avgYield: '60%',
  amount: '$300,000.00',
  status: 'PAYMENT_CLEARED',
}, {
  createdAt: '1/7/2020',
  transactionId: '28574985',
  fromAccount: 'Payzen WF - 1234',
  toAccount: 'Sutter H22 - 4567',
  noOfPlans: '300',
  avgYield: '62%',
  amount: '$303,800.00',
  status: 'PAYMENT_CLEARED',
}, {
  createdAt: '1/6/2020',
  transactionId: '28574984',
  fromAccount: 'Payzen WF - 1234',
  toAccount: 'Sutter H22 - 4567',
  noOfPlans: '350',
  avgYield: '62%',
  amount: '$347,200.00',
  status: 'PAYMENT_CLEARED',
}, {
  createdAt: '1/3/2020',
  transactionId: '28574983',
  fromAccount: 'Payzen WF - 1234',
  toAccount: 'Sutter H22 - 4567',
  noOfPlans: '380',
  avgYield: '60%',
  amount: '$240,000.00',
  status: 'PAYMENT_CLEARED',
}, {
  createdAt: '1/2/2020',
  transactionId: '28574982',
  fromAccount: 'Payzen WF - 1234',
  toAccount: 'Sutter H22 - 4567',
  noOfPlans: '340',
  avgYield: '61%',
  amount: '$268,400.00',
  status: 'PAYMENT_CLEARED',
}];

const getMessagingUser = (n) => ({
  fullName: `Full Name ${n}`,
  acUserId: `active-campaign-id-${n}`,
});

export const getMessagingUsers = (amount, startIndex = 0) => {
  if (startIndex > 100) return Array.from({ length: 10 }, (_, i) => getMessagingUser(i + startIndex));
  return Array.from({ length: amount }, (_, i) => getMessagingUser(i + startIndex));
};

export const reportPatientCount = 6809;

export const reportTotalAmountEnrolled = 830000000;

export const reportTotalAmountPreFunded = 506300000;

export const reportCategories = ['01/01/2021', '01/04/2021', '01/16/2021', '01/23/2021', '01/28/2021'];

export const reportData = [0, 200, 300, 340, 400];

export const providers = [{
  providerId: '5b81df3d-2054-48e8-be5b-7bfed7dd0000',
  providerName: 'Byrd Regional Hospital',
}, {
  providerId: '11111111-1111-4111-8111-111111111111',
  providerName: 'Demo',
}, {
  providerId: 'ffffffff-ffff-4fff-aaaa-fffffffff000',
  providerName: 'DEMO',
}, {
  providerId: '39f600c3-c304-4a53-8838-04d7346ea538',
  providerName: 'Dr. Otze',
}, {
  providerId: '76e95c2b-4c56-42d5-a1ec-6b09e7eac4e2',
  providerName: 'e2e-stg',
}, {
  providerId: '945fa7c5-be30-4105-bf20-0a916667999d',
  providerName: 'Epic POC',
}, {
  providerId: '03bebd10-a164-4358-856a-c321b07009ac',
  providerName: 'GEISINGER',
}, {
  providerId: '03bebd10-a164-4358-856a-c321b0700001',
  providerName: 'GEISINGER - CLINIC',
}, {
  providerId: '03bebd10-a164-4358-856a-c321b0700005',
  providerName: 'GEISINGER - GBH',
}, {
  providerId: '03bebd10-a164-4358-856a-c321b0700002',
  providerName: 'GEISINGER - GCM',
}, {
  providerId: '03bebd10-a164-4358-856a-c321b0700004',
  providerName: 'GEISINGER - GHS',
}, {
  providerId: '03bebd10-a164-4358-856a-c321b0700006',
  providerName: 'GEISINGER - GLH',
}, {
  providerId: '03bebd10-a164-4358-856a-c321b0700003',
  providerName: 'GEISINGER - GMC',
}, {
  providerId: '03bebd10-a164-4358-856a-c321b0700007',
  providerName: 'GEISINGER - GWV',
}, {
  providerId: '9f1d0611-4f06-4cfd-86e8-44d88e118117',
  providerName: 'GENESIS DENTAL',
}, {
  providerId: '3140623c-f0c4-4921-b72f-eda4a19b0cf2',
  providerName: 'JOSE HEALTH',
}, {
  providerId: 'c2560e98-8a07-46a8-a9ed-239464a9f9a9',
  providerName: 'MAGRUDER',
}, {
  providerId: 'c2560e98-8a07-46a8-a9ed-239464a90020',
  providerName: 'Memorial Hospital at Gulfport',
}, {
  providerId: '05c6b740-b5dd-4f83-93dc-f516fcfdc563',
  providerName: 'NEUROLOGICAL ASSOCIATES OF LA',
}, {
  providerId: '04bebd10-a164-4358-856a-c321b07009ac',
  providerName: 'OLD GEISINGER',
}, {
  providerId: 'dbd9f807-0868-4f3b-b5d3-ea1b54159f65',
  providerName: 'OSORIO HEALTH',
}, {
  providerId: '85409857-6990-444f-90ca-f8b6e90da19d',
  providerName: 'PayZen Hospital',
}, {
  providerId: 'ffffffff-ffff-1fff-ffff-ffffffff0000',
  providerName: 'PayZen Hospital Care Card',
}, {
  providerId: '543d825d-50ef-48b8-b1a7-e63cd9298b31',
  providerName: 'PETR PROVIDEr',
}, {
  providerId: '2ba1cc7b-2733-4631-b198-5fb530508c91',
  providerName: 'Petrs Health',
}, {
  providerId: '6d444a4f-9c14-445a-9da3-62bed370886b',
  providerName: 'Sprintz Center, PLLC',
}, {
  providerId: '5529d465-8df6-4ea9-b814-a9e99a9dd6a2',
  providerName: 'Sutter Health',
}, {
  providerId: '8daa4220-7b2a-403c-82d2-b8c9d42640ee',
  providerName: 'Sutter Health',
}, {
  providerId: 'f9baa8f3-f5d2-4d10-a68e-6abdeca24a29',
  providerName: 'test health',
}, {
  providerId: '02421e0a-5c2d-403d-ba35-9a857fa90000',
  providerName: 'UTMB Health',
}, {
  providerId: 'fc4b909f-18aa-4d8e-883b-18f9a44a0000',
  providerName: 'Winston Medical Center',
}];
