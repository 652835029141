import { createAsyncAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';

import {
  reqGetProviderPayments,
  reqDownloadProviderPaymentsCSV,
  reqGetProviderUploadedFiles,
  reqGetProviderReportsCredentials,
  reqGetProviderListReports,
  reqGetProviderReports,
  reqGetPatientInvites,
  reqCreatePatientInvite,
  reqUpdatePatientInvite,
  reqExpirePatientInvite,
  reqProlongPatientInvite,
  reqGetPatientsInvitesStats,
  reqGetAuthStrategies,
  reqGetProviderMessagingUsers,
  reqSendMessageToUser,
  reqGetProviderManualReleaseAutomationId,
} from 'api/provider';

export const getProviderPayments = createAsyncAction(
  'GET_PROVIDER_PAYMENTS',
  (payload) => reqGetProviderPayments(payload).then(prop('data')),
);

export const getAuthStrategies = createAsyncAction(
  'GET_PROVIDER_AUTH_STRATEGIES',
  () => reqGetAuthStrategies().then(prop('data')),
);

export const downloadProviderPaymentsCSV = createAsyncAction(
  'DOWNLOAD_PROVIDER_PAYMENTS_CSV',
  (payload) => reqDownloadProviderPaymentsCSV(payload).then((result) => {
    const blob = window.URL.createObjectURL(result.data);

    return blob;
  }),
);

export const getProviderUploadedFiles = createAsyncAction(
  'GET_PROVIDER_UPLOADED_FILES',
  (payload) => reqGetProviderUploadedFiles(payload).then(prop('data')),
);

export const getManualReleaseAutomationId = createAsyncAction(
  'GET_PROVIDER_RELEASE_AUTOMATION_ID',
  (payload) => reqGetProviderManualReleaseAutomationId(payload).then(prop('data')),
);

export const getProviderMessagingUsers = createAsyncAction(
  'GET_PROVIDER_MESSAGING_USERS',
  (payload) => reqGetProviderMessagingUsers(payload).then(prop('data')),
);

export const sendMessageToUser = createAsyncAction(
  'SEND_MESSAGE_TO_USER',
  (payload) => reqSendMessageToUser(payload).then(prop('data')),
);

export const getProviderReportsCredentials = createAsyncAction(
  'GET_PROVIDER_REPORTS_CREDENTIALS',
  (payload) => reqGetProviderReportsCredentials(payload).then(prop('data')),
);

export const getProviderListReports = createAsyncAction(
  'GET_PROVIDER_LIST_REPORTS',
  (payload) => reqGetProviderListReports(payload).then(prop('data')),
);

export const getProviderReports = createAsyncAction(
  'GET_PROVIDER_REPORTS',
  (payload) => reqGetProviderReports(payload).then(prop('data')),
);

export const getPatientInvites = createAsyncAction(
  'GET_PATIENT_INVITES',
  (payload) => reqGetPatientInvites(payload).then(prop('data')),
);

export const createPatientInvite = createAsyncAction(
  'CREATE_PATIENT_INVITE',
  (payload) => reqCreatePatientInvite(payload).then(prop('data')),
);

export const updatePatientInvite = createAsyncAction(
  'UPDATE_PATIENT_INVITE',
  (payload) => reqUpdatePatientInvite(payload).then(prop('data')),
);

export const expirePatientInvite = createAsyncAction(
  'EXPIRE_PATIENT_INVITE',
  (payload) => reqExpirePatientInvite(payload).then(prop('data')),
);

export const prolongPatientInvite = createAsyncAction(
  'PROLONG_PATIENT_INVITE',
  (payload) => reqProlongPatientInvite(payload).then(prop('data')),
);

export const getPatientsInvitesStats = createAsyncAction(
  'GET_PATIENT_INVITES_STATS',
  (payload) => reqGetPatientsInvitesStats(payload).then(prop('data')),
);
