import { connect } from 'react-redux';

import SearchPage from './SearchPage';

const mapStateToProps = () => ({ });

const mapDispatchToProps = { };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchPage);
