import axios from 'axios';
import { equals, mergeAll } from 'ramda';
import queryString from 'qs';
import LogRocket from 'logrocket';
import getSubdomain from 'utils/getSubdomain';
import {
  POST,
  PUT,
  GET,
  DELETE,
  DOWNLOAD,
} from './httpMethods';

const defaultHeaders = {};

const baseURL = equals(process.env.REACT_APP_ENV, 'development')
  ? process.env.REACT_APP_SERVER_URL
  : window.location.origin;

const subdomain = getSubdomain(window);

const request = (method, url, headers, axiosConfig = {}) => {
  const options = mergeAll([
    {
      method,
      url,
      baseURL,
      headers: {
        ...defaultHeaders,
        ...headers,
        'X-Provider-Name': subdomain,
        'X-LogRocket-URL': LogRocket.sessionURL,
      },
      responseType: 'json',
    },
    axiosConfig,
  ]);

  return axios(options);
};

export default {
  [POST]: (url, {
    headers = {}, data = {}, params = {}, transformResponse = [],
  }) => request(
    POST, url, headers, {
      data,
      params,
      transformResponse,
    },
  ),
  [PUT]: (url, {
    headers = {}, data = {}, params = {}, transformResponse = [],
  }) => request(
    PUT, url, headers, {
      data,
      params,
      transformResponse,
    },
  ),
  [GET]: (url, { headers = {}, params = {}, transformResponse = [] }) => request(
    GET, url, headers, {
      params,
      transformResponse,
      paramsSerializer: queryString.stringify,
    },
  ),
  [DOWNLOAD]: (url, { headers = {}, params = {}, transformResponse = [] }) => request(
    GET, url, headers, {
      params,
      transformResponse,
      paramsSerializer: queryString.stringify,
      responseType: 'blob',
    },
  ),
  [DELETE]: (url, { headers = {} }) => request(DELETE, url, headers),
};
