import { connect } from 'react-redux';
import React from 'react';
import Layout from 'components/Layout';

const AdminHome = () => (
  <Layout>
    <div className="search-patients">
      <div className="provider-form provider-form--large">
        <div className="text-center">
          <h1 className="h4 font-weight-normal letter-spacing mb-5">PayzenCustomer Support Portal</h1>
          <p className="text-tuna letter-spacing mb-7">
            Use the navigation links on the left
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

const mapStateToProps = () => ({});

const mapDispatchToProps = { };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminHome);
