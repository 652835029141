import React from 'react';
import PropTypes from 'prop-types';
import { trim } from 'ramda';

const SearchResultsTableInfo = ({ searchTerm, resultsCount, totalCount }) => (
  <div className="text-center pt-4 pb-4 text-large text-tuna">
    {`Showing ${resultsCount} of ${totalCount} results`}
    {trim(searchTerm) && (
      <>
        {' for '}
        <b>{trim(searchTerm)}</b>
      </>
    )}
  </div>
);

SearchResultsTableInfo.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  resultsCount: PropTypes.number,
  totalCount: PropTypes.number,
};

SearchResultsTableInfo.defaultProps = {
  resultsCount: 0,
  totalCount: 0,
};

export default SearchResultsTableInfo;
