/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/no-autofocus */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import Textarea from 'react-textarea-autosize';
import { getIn } from 'formik';
import Select, { createFilter } from 'react-select';

import Icon from 'components/Icon';
import {
  ErrorMessage, ManualDateInput, PhoneInput, CurrencyInput, NumberInput,
} from 'components/Fields';

const Input = ({
  id,
  type,
  className,
  disabled,
  field,
  label,
  icon,
  form,
  onChangeHandler,
  innerRef,
  isRequired,
  autoFocus,
  options = [],
  filterOption = {},
  getOptionLabel = () => { },
  getOptionValue = () => { },
}) => {
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;
  const isValidClass = !error && field.value ? 'is-valid' : '';
  const invalidClass = hasError ? 'is-invalid' : '';
  const withoutLabelClass = label ? '' : 'form-control--without-label';

  const selectValue = options.find((item) => item[id] === field.value) || null;

  const { setFieldTouched, validateOnMount } = form;

  const Label = () => (
    <>
      {label}
      {isRequired && <span className="ml-1 text-burnt-sienna">*</span>}
    </>
  );

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    validateOnMount && setFieldTouched(name, true);
  }, [error]);

  switch (type) {
    case 'number':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <NumberInput
              setField={form.setFieldValue}
              id={id}
              type={type}
              placeholder={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass}`}
              disabled={disabled}
              {...field}
            />
            {label && <label htmlFor={id} className="form-control-placeholder">{label}</label>}
          </div>
          {hasError && <ErrorMessage error={error} />}
        </>
      );
    case 'textarea':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <Textarea
              id={id}
              type={type}
              placeholder={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass}`}
              disabled={disabled}
              {...field}
              minRows={3}
              maxRows={3}
            />
            {label && <label htmlFor={id} className="form-control-placeholder">{label}</label>}
          </div>
          {hasError && <ErrorMessage error={error} />}
        </>
      );
    case 'currency':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <CurrencyInput
              setField={form.setFieldValue}
              id={id}
              type={type}
              placeholder={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass}`}
              disabled={disabled}
              {...field}
            />
            {label && (
              <label htmlFor={id} className="form-control-placeholder">
                {label}
                {isRequired && <span className="ml-1 text-burnt-sienna">*</span>}
              </label>
            )}
          </div>
          {hasError && <ErrorMessage error={error} />}
        </>
      );
    case 'tel':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <PhoneInput
              setField={form.setFieldValue}
              id={id}
              type={type}
              placeholder={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass}`}
              disabled={disabled}
              {...field}
            />
            {label && (
              <label htmlFor={id} className="form-control-placeholder">
                {label}
                {isRequired && <span className="ml-1 text-burnt-sienna">*</span>}
              </label>
            )}
          </div>
          {hasError && <ErrorMessage error={error} />}
        </>
      );
    case 'date':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <ManualDateInput
              setField={form.setFieldValue}
              id={id}
              label={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass}`}
              disabled={disabled}
              {...field}
            />
          </div>
          {hasError && <ErrorMessage error={error} />}
        </>
      );
    case 'radio':
      return (
        <div className="form-radio mr-3">
          <input
            id={id}
            type={type}
            {...field}
            checked={form.values[name] === field.value}
            className="form-radio__input"
          />
          <label htmlFor={id} className="form-radio__label mb-0">
            <span className="fake-radio" />
            <span>{label}</span>
          </label>
        </div>
      );
    case 'checkbox':
      return (
        <div className={`form-checkbox mr-3 ${invalidClass}`}>
          <input
            id={id}
            type={type}
            {...field}
            checked={form.values[name]}
            className="form-checkbox__input"
          />
          <label htmlFor={id} className="form-checkbox__label mb-0">
            <Icon name="checkmark" className="form-checkbox__label__icon" />
          </label>
        </div>
      );
    case 'select':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 ${icon && 'input-group'}`}>
            <Select
              id={id}
              value={selectValue}
              onChange={(value) => form.setFieldValue(id, value[id])}
              options={options}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              filterOption={createFilter(filterOption)}
              placeholder={<Label />}
              className={`form-control p-0  ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass}`}
              styles={{
                control: (provided) => ({
                  ...provided,
                  height: '100%',
                  'border-radius': '2px',
                  border: '0 none',
                }),
                container: (provided) => ({
                  ...provided,
                  height: '55px',
                }),
              }}
            />
          </div>
          {hasError && <ErrorMessage error={error} />}
        </>
      );
    default:
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <input
              id={id}
              ref={innerRef}
              type={type}
              placeholder={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass}`}
              disabled={disabled}
              autoFocus={autoFocus}
              {...field}
              onChange={(e) => {
                const value = onChangeHandler(e.target.value);
                form.setFieldValue(name, value);
              }}
            />
            {label && (
              <label htmlFor={id} className="form-control-placeholder">
                {label}
                {isRequired && <span className="ml-1 text-burnt-sienna">*</span>}
              </label>
            )}
          </div>
          {hasError && <ErrorMessage error={error} />}
        </>
      );
  }
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  onChangeHandler: PropTypes.func,
  innerRef: PropTypes.any,
  isRequired: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

Input.defaultProps = {
  className: '',
  disabled: false,
  icon: '',
  label: '',
  onChangeHandler: (val) => val,
  innerRef: null,
  isRequired: false,
  autoFocus: false,
};

export default Input;
