import React, { useState, useEffect } from 'react';
import {
  propOr, isEmpty, compose, divide, __,
} from 'ramda';

import { StateLessModal } from 'components/MainModal';
import { Field, Form, Formik } from 'formik';
import { Input } from 'components/Fields';
import Button from 'components/Button';

import { capitalize } from 'utils/capitalize';
import { patientInviteForm } from 'utils/validationSchemas';

const Modal = ({
  isOpen, setIsOpen, onSubmit, currentInvite,
}) => {
  const [disabledFirstName, setDisabledFirstName] = useState(false);
  const [disabledLastName, setDisabledLastName] = useState(false);
  const [disabledEmail, setDisabledEmail] = useState(false);

  const initialValues = Object.freeze({
    firstName: propOr('', 'firstName')(currentInvite),
    lastName: propOr('', 'lastName')(currentInvite),
    email: propOr('', 'email')(currentInvite),
    phone: propOr('', 'phone')(currentInvite),
    medicalRecordNumber: propOr('', 'medicalRecordNumber')(currentInvite),
    invoiceId: propOr('', 'invoiceId')(currentInvite),
    totalAmount: currentInvite.totalAmount ? compose(
      divide(__, 100),
      propOr('', 'totalAmount'),
    )(currentInvite) : '',
    agree: false,
  });

  useEffect(() => {
    setDisabledFirstName(!!initialValues.firstName);
    setDisabledLastName(!!initialValues.lastName);
    setDisabledEmail(!!initialValues.email);
  }, [initialValues]);

  const isNewInvite = isEmpty(currentInvite);

  return (
    <StateLessModal
      isOpen={isOpen}
      contentLabel="Invites Modal"
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onCloseHandler={() => { }}
    >
      <div className="invites-modal">
        <div className="invites-modal__content">
          <div className="text-left mb-5">
            <h1 className="text-tuna font-weight-semibold text-large mb-1">
              Add a Patient to the Invite List
            </h1>
            <p className="text-comet mb-0">Patients on the list will receive an email and text message invite</p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={patientInviteForm}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-4">
                  <div className="row smaller-gutters">
                    <div className="col-sm-6">
                      <Field
                        id="firstName"
                        type="text"
                        name="firstName"
                        label="First Name"
                        component={Input}
                        onChangeHandler={capitalize}
                        isRequired
                        disabled={disabledFirstName}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Field
                        id="lastName"
                        type="text"
                        name="lastName"
                        label="Last Name"
                        component={Input}
                        onChangeHandler={capitalize}
                        isRequired
                        disabled={disabledLastName}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Field
                        id="email"
                        type="email"
                        name="email"
                        label="Email"
                        component={Input}
                        isRequired
                        disabled={disabledEmail}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Field
                        id="phone"
                        type="tel"
                        name="phone"
                        label="Mobile Number"
                        component={Input}
                        disabled={!isNewInvite}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Field
                        id="totalAmount"
                        name="totalAmount"
                        type="currency"
                        label="Bill amount"
                        component={Input}
                        icon="$"
                        isRequired
                      />
                    </div>
                    <div className="col-sm-6">
                      <Field
                        id="medicalRecordNumber"
                        type="text"
                        name="medicalRecordNumber"
                        label="MRN/Account number"
                        component={Input}
                        isRequired
                      />
                    </div>
                    <div className="col-sm-6">
                      <Field
                        id="invoiceId"
                        type="text"
                        name="invoiceId"
                        label="Invoice ID"
                        component={Input}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Field
                        id="servicingDate"
                        type="date"
                        name="servicingDate"
                        label="Service Date"
                        placeholder="MM/DD/YYYY"
                        className="form-control--with-placeholder "
                        component={Input}
                      />
                    </div>
                    <div className="col-12">
                      <div className="media text-left">
                        <Field id="agree" type="checkbox" name="agree" component={Input} className="mr-1" />
                        <div className="media-body">
                          <p className="mb-0">Patient balance after insurance</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row smaller-gutters">
                  <div className="col-sm-2  " />
                  <div className="col-sm-5">
                    <Button
                      type="outline-primary"
                      className="btn-block"
                      onClick={() => { setIsOpen(false); }}
                      disabled={isSubmitting}
                    >
                      Discard
                    </Button>
                  </div>
                  <div className="col-sm-5">
                    <Button
                      type="primary"
                      className="btn-block"
                      isSubmit
                      disabled={isSubmitting}
                    >
                      {isNewInvite ? '+ Add new patient' : 'Edit patient invite'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

        </div>
      </div>
    </StateLessModal>
  );
};

export default Modal;
