import { createAsyncAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';

import {
  reqGetOfferData, reqUpdateMrn, reqConfirmOffer,
} from 'api/patients';

export const getOfferData = createAsyncAction(
  'GET_OFFER_DATA',
  (payload) => reqGetOfferData(payload).then(prop('data')),
);

export const updateMrn = createAsyncAction(
  'UPDATE_MRN',
  (payload) => reqUpdateMrn(payload).then(prop('data')),
);

export const confirmOffer = createAsyncAction(
  'CONFIRM_OFFER',
  (payload) => reqConfirmOffer(payload).then(prop('data')),
);
