import React from 'react';
import PropTypes from 'prop-types';
import { gt } from 'ramda';

const Spinner = ({ message, pendingTask }) => {
  if (gt(pendingTask, 0)) {
    return (
      <div className="spinner">
        <div className="mb-2">
          <span className="spinner__bullet" />
          <span className="spinner__bullet" />
          <span className="spinner__bullet" />
          <span className="spinner__bullet" />
        </div>
        {message && <p className="mb-0">{message}</p>}
      </div>
    );
  }
  return null;
};

Spinner.propTypes = {
  message: PropTypes.string,
};

Spinner.defaultProps = {
  message: 'Please Wait',
};

export default Spinner;
