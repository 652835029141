import { connect } from 'react-redux';

import { updatePassword } from 'actions/user';
import { startSpinner, stopSpinner } from 'actions/spinner';

import MyAccount from './MyAccount';

const mapStateToProps = ({
  user: {
    email,
    authStrategy,
  },
}) => ({
  email,
  authStrategy,
});

const mapDispatchToProps = {
  updatePassword,
  startSpinner,
  stopSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyAccount);
