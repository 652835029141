import React from 'react';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';

import Layout from 'components/Layout';
import { Input } from 'components/Fields';
import Button from 'components/Button';
import Icon from 'components/Icon';

import { changePasswordForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';

const initialValues = {
  currentPassword: '',
  newPassword: '',
  EnterPasswordAgain: '',
};

const MyAccount = ({
  email,
  authStrategy,
  updatePassword,
  startSpinner,
  stopSpinner,
}) => {
  const onSubmitSuccess = (resetForm) => () => {
    stopSpinner();
    toast.success('Password changed successfully!');
    resetForm();
  };

  const onSubmitFailure = (error) => {
    const message = getErrorMessage(error);
    stopSpinner();
    toast.error(message);
  };

  const onSubmit = ({ currentPassword, newPassword }, actions) => {
    startSpinner();
    const { setSubmitting, resetForm } = actions;
    return updatePassword({ currentPassword, newPassword })
      .then(onSubmitSuccess(resetForm))
      .catch(onSubmitFailure)
      .finally(setSubmitting(false));
  };

  const authStrategyType = authStrategy && authStrategy.type;
  const authStrategyName = authStrategy && authStrategy.displayName;

  return (
    <Layout>
      <div className="my-account d-flex align-items-center h-100">
        <div className="w-100">
          <h1 className="h4 text-tuna mb-3 text-center my-account__title">My Account</h1>
          <div className="d-flex flex-fill justify-content-center align-items-center">
            <div className="my-account__wrapper w-100 mx-auto h-100">
              <div className="row my-account__form-container">
                <div className="col-6">
                  {authStrategyType === 'BASIC' ? (
                    <div className="my-account__form">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={changePasswordForm}
                        onSubmit={onSubmit}
                      >
                        {({ isSubmitting }) => (
                          <Form>
                            <div className="text-center">
                              <h2 className="text-large text-charade mb-5">Change My Password</h2>
                            </div>
                            <div className="mb-5">
                              <Field
                                id="currentPassword"
                                type="password"
                                name="currentPassword"
                                label="Current Password"
                                component={Input}
                              />
                              <Field
                                id="newPassword"
                                type="password"
                                name="newPassword"
                                label="New Password"
                                component={Input}
                              />
                              <Field
                                id="EnterPasswordAgain"
                                type="password"
                                name="EnterPasswordAgain"
                                label="Enter Password Again"
                                component={Input}
                              />
                            </div>
                            <Button
                              type="primary"
                              className="btn-block"
                              isSubmit
                              disabled={isSubmitting}
                            >
                              Submit
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : (
                    <div className="my-account__form text-center">
                      <Icon name="padlock" className="provider-padlock text-dodger-blue mb-5" />
                      <p className="text-large text-charade">{authStrategyName}</p>
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <div className="my-account__form">
                    <div className="text-center">
                      <h2 className="text-large text-charade mb-5">Change Email Address</h2>
                    </div>
                    <div className="form-control form-control--without-label is-disabled mb-4">
                      <span>{email}</span>
                    </div>
                    <p className="text-center mb-0">
                      Please contact
                      {' '}
                      <a href="#link">your administrator</a>
                      <br />
                      to change your email address.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MyAccount;
