import { getManualReleaseAutomationId } from 'actions/provider';
import { startSpinner, stopSpinner } from 'actions/spinner';
import { connect } from 'react-redux';
import getProviderId from 'utils/getProviderId';

import getRole from 'utils/getRole';

import Navigation from './Navigation';

const mapStateToProps = ({ provider: { messagingCentre } }) => {
  const providerId = getProviderId();
  const role = getRole();

  const { manualReleaseAutomationId } = messagingCentre;

  return { role, manualReleaseAutomationId, providerId };
};

const mapDispatchToProps = {
  getManualReleaseAutomationId,
  startSpinner,
  stopSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Navigation);
