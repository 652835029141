import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { getProviderMessagingUsers, sendMessageToUser } from 'actions/provider';
import getProviderId from 'utils/getProviderId';
import MessagingCentre from './MessagingCentre';

const mapStateToProps = ({ provider }) => {
  const providerId = getProviderId();

  const messagingCentre = {
    allUsersFetched: pathOr([], ['messagingCentre', 'allUsersFetched'])(provider),
    users: pathOr([], ['messagingCentre', 'users'])(provider),
  };

  return {
    ...messagingCentre, providerId,
  };
};

const mapDispatchToProps = {
  getProviderMessagingUsers,
  sendMessageToUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessagingCentre);
