import React, { useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';

import Icon from 'components/Icon';
import TableRow from './TableRow';

export const columns = [{
  Header: 'Submitted',
  accessor: 'createdAt',
}, {
  Header: 'Full Name',
  accessor: 'displayFullName',
  disableSortBy: true,
  hide: true,
}, {
  Header: 'Bill Amount',
  accessor: 'displayTotalAmount',
  disableSortBy: true,
  hide: true,
}, {
  Header: 'MRN',
  accessor: 'displayMRN',
  disableSortBy: true,
  hide: true,
}, {
  Header: 'Email',
  accessor: 'displayEmail',
  disableSortBy: true,
  hide: true,
}, {
  Header: 'Mobile',
  accessor: 'displayPhone',
  disableSortBy: true,
  hide: true,
}, {
  Header: 'Status',
  accessor: 'status',
  disableSortBy: true,
}, {
  Header: 'Action',
  accessor: 'action',
  disableSortBy: true,
}];

const Table = ({
  data,
  initialSortBy,
  onSortChange,
  setCurrentPatientInviteId,
  setIsOpenConfirmModal,
  prolongInvite,
  setIsOpen,
  setCurrentInvite,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable({
    columns,
    data,
    initialState: { sortBy: initialSortBy },
  }, useSortBy);

  useEffect(() => {
    onSortChange(sortBy);
  }, [sortBy]);

  const renderColumnOrderIcon = (column) => {
    if (column.isSorted && column.isSortedDesc) return <Icon name="caret-down" />;
    if (column.isSorted && !column.isSortedDesc) return <Icon name="caret-up" />;
    return null;
  };

  return (
    <div className="table-custom table-responsive table-custom--invites">
      <div className="table-custom__head">
        <table className="table table-striped mb-0" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="text-tuna font-weight-normal text-uppercase"
                  >
                    <span className="align-middle">{column.render('Header')}</span>
                    <span className="text-xsmaller text-commet align-middle ml-1">
                      {renderColumnOrderIcon(column)}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </table>
      </div>
      <div className="table-custom__body">
        <table className="table table-striped mb-0" {...getTableProps()}>
          <tbody {...getTableBodyProps()}>
            {rows.map(
              (row) => {
                prepareRow(row);
                return (
                  <TableRow
                    key={row.id}
                    row={row}
                    setCurrentPatientInviteId={setCurrentPatientInviteId}
                    setIsOpenConfirmModal={setIsOpenConfirmModal}
                    prolongInvite={prolongInvite}
                    setIsOpen={setIsOpen}
                    setCurrentInvite={setCurrentInvite}
                  />
                );
              },
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
