import React from 'react';
import { Redirect } from 'react-router-dom';

import { authenticationService } from 'libs';
import isPayzenAdminSite from 'utils/isPayzenAdminSite';

const { loggedIn } = authenticationService;

const Root = () => {
  if (loggedIn() && isPayzenAdminSite) return <Redirect to="/admin" />;
  if (loggedIn()) return <Redirect to="/patients-search" />;
  return <Redirect to="/login" />;
};

export default Root;
