import React from 'react';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import { path, pathOr } from 'ramda';

import StatusIcon from './StatusIcon';
import ActionMenu from './ActionMenu';

const TableRow = ({
  row,
  setCurrentPatientInviteId,
  setIsOpenConfirmModal,
  prolongInvite,
  setIsOpen,
  setCurrentInvite,
}) => {
  const statusIcon = (cell) => {
    switch (cell.value) {
      case 'VISITED':
        return <StatusIcon className="clicked" iconName="clicked" text="Clicked" />;
      case 'EXPIRED':
      case 'VISITED_AFTER_EXPIRED':
        return <StatusIcon className="expired" iconName="expired" text="Expired" />;
      case 'SENT_TO_PATIENT':
        return <StatusIcon className="pending" iconName="clock" text="Invited" />;
      default:
        return null;
    }
  };

  const totalAmountCell = (cell) => (
    <NumberFormat
      value={pathOr(0, ['row', 'original', 'meta', 'totalAmount'], cell) / 100}
      decimalScale={2}
      displayType="text"
      prefix="$"
      thousandSeparator
    />
  );

  const dateCell = (cell) => cell.value && format(new Date(cell.value), 'MM/dd/yyyy');

  const fullNameCell = (cell) => {
    const firstName = path(['row', 'original', 'meta', 'firstName'], cell);
    const lastName = path(['row', 'original', 'meta', 'lastName'], cell);
    return [firstName, lastName].join(' ');
  };

  const emailCell = path(['row', 'original', 'meta', 'email']);
  const phoneCell = path(['row', 'original', 'meta', 'phone']);
  const mrnCell = path(['row', 'original', 'meta', 'medicalRecordNumber']);

  const actionCell = (cell) => {
    const patientInviteId = path(['row', 'original', 'patientInviteId'])(cell);
    const status = path(['row', 'original', 'status'])(cell);
    const invite = path(['row', 'original', 'meta'])(cell);

    switch (status) {
      case 'EXPIRED':
      case 'VISITED_AFTER_EXPIRED':
        return null;
      default:
        return (
          <ActionMenu
            patientInviteId={patientInviteId}
            status={status}
            setCurrentPatientInviteId={setCurrentPatientInviteId}
            setIsOpenConfirmModal={setIsOpenConfirmModal}
            prolongInvite={prolongInvite}
            setIsOpen={setIsOpen}
            invite={invite}
            setCurrentInvite={setCurrentInvite}
          />
        );
    }
  };

  const renderCell = (cell) => {
    const id = path(['column', 'id'])(cell);

    switch (id) {
      case 'status': return statusIcon(cell);
      case 'displayFullName': return fullNameCell(cell);
      case 'displayTotalAmount': return totalAmountCell(cell);
      case 'createdAt': return dateCell(cell);
      case 'displayEmail': return emailCell(cell);
      case 'displayPhone': return phoneCell(cell);
      case 'displayMRN': return mrnCell(cell);
      case 'action': return actionCell(cell);
      default: return cell.render('Cell');
    }
  };

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        const attrs = {
          ...(cell.column.hide && { 'data-private': true }),
        };

        return (
          <td {...attrs} {...cell.getCellProps()}>
            {renderCell(cell)}
          </td>
        );
      })}
    </tr>
  );
};

export default TableRow;
