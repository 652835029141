import React from 'react';
import { useTable, useSortBy } from 'react-table';

import { renderCell, renderColumnOrderIcon } from './tableHelpers';

const columns = [{
  Header: 'First Name',
  accessor: 'payzenPatientFirstName',
  hide: true,
}, {
  Header: 'Last Name',
  accessor: 'payzenPatientLastName',
  hide: true,
}, {
  Header: 'Email',
  accessor: 'payzenPatientEmail',
  hide: true,
}, {
  Header: 'Mobile',
  accessor: 'payzenPatientPhone',
  hide: true,
}, {
  Header: '',
  accessor: 'id',
  hide: true,
}];

const Table = ({ data, setOpen, setCurrentPatient }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  }, useSortBy);

  return (
    <div className="table-custom table-responsive table-custom--search-results">
      <div className="table-custom__head">
        <table className="table table-striped mb-0" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-tuna text-small font-weight-normal">
                    <span className="align-middle">{column.render('Header')}</span>
                    <span className="text-xsmaller text-commet align-middle ml-2">
                      {renderColumnOrderIcon(column)}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </table>
      </div>
      <div className="table-custom__body">
        <table className="table table-striped mb-0" {...getTableProps()}>
          <tbody {...getTableBodyProps()}>
            {rows.map(
              (row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      const attrs = {
                        ...(cell.column.hide && { 'data-private': true }),
                      };

                      return (
                        <td {...attrs} {...cell.getCellProps({ className: 'align-middle' })}>
                          {renderCell(row, cell, setCurrentPatient, setOpen)}
                        </td>
                      );
                    })}
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
