import { createReducer } from '@reduxjs/toolkit';
import { getOfferData } from 'actions/patients';
import { PENDING, FULFILLED, REJECTED } from 'libs/promiseStatuses';

const initialState = {
  status: null,
  bills: [],
  offer: {
    totalAmount: null,
    totalPayment: null,
    firstWithdrawDate: null,
    paymentsAmount: [],
  },
  patient: {},
  user: {
    firstName: null,
    lastName: null,
  },
  provider: {},
  patientDocuments: [],
  providerDocuments: [],
  billFilesWithUrls: [],
};

export default createReducer(initialState, {
  [String(getOfferData.pending)]: (state) => ({
    ...state,
    status: PENDING,
  }),
  [String(getOfferData.fulfilled)]: (state, action) => ({
    ...state,
    status: FULFILLED,
    ...action.payload,
  }),
  [String(getOfferData.rejected)]: (state) => ({
    ...state,
    status: REJECTED,
  }),
});
