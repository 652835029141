import { createReducer } from '@reduxjs/toolkit';
import { inc, dec } from 'ramda';

import { startSpinner, stopSpinner, stopAllSpinners } from 'actions/spinner';

const initialState = {
  pendingTask: 0,
};

export default createReducer(initialState, {
  [startSpinner]: (state) => ({
    pendingTask: inc(state.pendingTask),
  }),
  [stopSpinner]: (state) => ({
    pendingTask: dec(state.pendingTask),
  }),
  [stopAllSpinners]: () => ({
    pendingTask: initialState.pendingTask,
  }),
});
