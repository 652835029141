import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import promiseMiddleware from 'redux-promise-middleware';
import provider360reducers from 'reducers';
import adminReducers from 'AdminSite/reducers';
import logoutMiddleware from 'middleware/logout';
import promiseRejectedWith401Middleware from 'middleware/unauthorizedApiCall';

const preloadedState = {};

const isProd = process.env.REACT_APP_ENV === 'production';

const baseMiddleware = [
  promiseMiddleware,
  promiseRejectedWith401Middleware,
  logoutMiddleware,
  ...getDefaultMiddleware(),
];

const middleware = !isProd ? [...baseMiddleware, logger] : baseMiddleware;

const store = configureStore({
  preloadedState,
  reducer: { ...provider360reducers, ...adminReducers },
  middleware,
  devTools: !isProd,
});

export default store;
