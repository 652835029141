import React from 'react';
import { Link } from 'react-router-dom';

import { authenticationService } from 'libs';

import Layout from 'components/Layout';

const { loggedIn } = authenticationService;

const NoMatch = () => (
  <Layout noLoggedIn={!loggedIn()}>
    <div className="min-vh-100 d-flex flex-column justify-content-center text-center">
      <div className="text-center mx-auto text-jumbo">
        <h1 className="h2 mb-2">Page not found</h1>
        <p className="mb-5">We can’t seem to find the page you’re looking for.</p>
        <Link to="/" className="btn btn-primary text-decoration-none px-10 py-3">Go to Homepage</Link>
      </div>
    </div>
  </Layout>
);

export default NoMatch;
