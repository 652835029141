import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'qs';
import { path } from 'ramda';
import { toast } from 'react-toastify';

import Layout from 'components/Layout';

import getErrorMessage from 'utils/getErrorMessage';

const SSOSignin = ({
  exchangeAuthCode,
  startSpinner,
  stopSpinner,
}) => {
  const history = useHistory();
  const queryParams = queryString.parse(history.location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    startSpinner();
    exchangeAuthCode({
      code: queryParams.code,
      state: queryParams.state,
    }).then((data) => {
      stopSpinner();
      if (path(['value', 'twoFactorToken'], data)) {
        history.push('/verification-code');
      } else {
        history.push('/');
      }
    }).catch((error) => {
      stopSpinner();
      history.push('/login');
      const message = getErrorMessage(error);
      toast.error(message);
    });
  }, []);

  return (
    <Layout noLoggedIn>
      <div className="min-vh-100 d-flex flex-column justify-content-center text-center">
        <div className="text-center mx-auto text-jumbo" />
      </div>
    </Layout>
  );
};

export default SSOSignin;
