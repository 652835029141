import { path } from 'ramda';

import { loggedIn, getProfile } from 'libs/authenticationService';

const getOrganizationId = () => {
  const profile = loggedIn() && getProfile();
  return path(['organizationUser', 'organizationId'])(profile);
};

export default getOrganizationId;
