import { connect } from 'react-redux';

import { refreshToken as refreshTokenAction, logout as logoutAction } from 'actions/user';

import { authenticationService } from 'libs';

import LogOutModal from './LogOutModal';

const { getToken, getRefreshToken, getProfile } = authenticationService;

const mapStateToProps = ({ user }) => {
  const { refreshTokenStatus } = user;
  const token = getToken();
  const expAtToken = token ? getProfile().exp * 1000 : 0;
  const refreshToken = getRefreshToken() || '';
  const timeOutThreshold = 5 * 60 * 1000; // When to refresh token/show log off modal
  const timeOutIdle = 3 * 60 * 1000; // Automatically refresh token if client wasn't idle for this time

  return {
    expAtToken,
    token,
    refreshToken,
    refreshTokenStatus,
    timeOutThreshold,
    timeOutIdle,
  };
};

const mapDispatchToProps = { refreshTokenAction, logoutAction };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogOutModal);
