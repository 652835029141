import { connect } from 'react-redux';

import { confirmOffer } from 'actions/patients';
import { startSpinner, stopSpinner } from 'actions/spinner';

import BillApproval from './BillApproval';

const mapStateToProps = ({
  providerForm: {
    provider,
    offer,
    providerDocuments,
    patientDocuments,
    paymentMethod,
  },
}) => ({
  provider,
  offer,
  providerDocuments,
  patientDocuments,
  paymentMethod,
});

const mapDispatchToProps = { startSpinner, stopSpinner, confirmOffer };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillApproval);
