import { connect } from 'react-redux';
import getRole from 'utils/getRole';
import getUserType from 'utils/getUserType';
import { loggedIn } from 'libs/authenticationService';
import PrivateRoute from './PrivateRoute';

const mapStateToProps = () => ({
  role: getRole(),
  userType: getUserType(),
  isLoggedIn: loggedIn(),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute);
