import { loggedIn, getProfile } from 'libs/authenticationService';
import { pathOr } from 'ramda';

const getRole = () => {
  const profile = loggedIn() && getProfile();
  const providerUserRole = pathOr('', ['providerUser', 'role'])(profile);
  const organizationUserRole = pathOr('', ['organizationUser', 'role'])(profile);

  return providerUserRole || organizationUserRole;
};

export default getRole;
