import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import StateLessModal from './StateLessModal';

class MainModal extends Component {
  state = {
    isOpen: false,
  };

  onOpenHandler = () => {
    this.setState({ isOpen: true });
  };

  onCloseHandler = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    const { action, children, ...rest } = this.props;
    return (
      <Fragment>
        {typeof action === 'function' ? (
          action(isOpen, this.onOpenHandler)
        ) : (
          <button className="button button-primary" onClick={this.onOpenHandler} type="button">
            Open Modal
          </button>
        )}
        <StateLessModal isOpen={isOpen} onCloseHandler={this.onCloseHandler} {...rest}>
          {typeof children === 'function' ? children(isOpen, this.onCloseHandler) : children}
        </StateLessModal>
      </Fragment>
    );
  }
}

export default MainModal;

MainModal.propTypes = {
  action: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
