import { connect } from 'react-redux';
import { path } from 'ramda';

import {
  getProviderPayments,
  downloadProviderPaymentsCSV,
} from 'actions/provider';
import { startSpinner, stopSpinner } from 'actions/spinner';
import { adminGetProviders } from 'AdminSite/actions/admin';

import getRole from 'utils/getRole';

import Financial from './Financial';

const mapStateToProps = ({
  user: { email },
  provider,
  adminPatients: { providers },
}) => {
  const role = getRole();

  const realData = path(['payments'])(provider);
  const { csvStatus } = provider;

  return {
    payments: realData,
    csvStatus,
    role,
    email,
    providers,
  };
};

const mapDispatchToProps = {
  getProviderPayments,
  downloadProviderPaymentsCSV,
  startSpinner,
  stopSpinner,
  adminGetProviders,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Financial);
