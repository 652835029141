import React from 'react';
import Router from 'router';
import Spinner from 'components/Spinner';

// todo: move idle timer and logic here
// example: https://github.com/SupremeTechnopriest/react-idle-timer#hook-usage
const App = () => (
  <>
    <Spinner />
    <Router />
  </>
);

export default App;
