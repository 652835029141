import {
  object, string, ref, number, date, boolean,
} from 'yup';
import Joi from '@hapi/joi';
import { equals, path } from 'ramda';
import { format } from 'date-fns';

import tldsList from 'libs/tldsList';

const oneUpperRegExp = /[A-Z]/;
const oneLowerRegExp = /[a-z]/;
const codeRegExp = /^[a-zA-Z0-9]{4}(?:[a-zA-Z0-9]{1})?$/;
const specialCharacterRegExp = /(?=.*\W)/;

const requiredMessage = (text) => `${text} is a required field`;
const codeMessage = 'Code must be 4 or 5 alphanumeric';
const passwordMinCharacters = 'New password has to be longer than 8 characters!';
const passwordOneUpperMessage = 'Password must contain at least one uppercase letter';
const passwordOneLowerMessage = 'Password must contain at least one lowercase letter';
const specialCharacterMessage = 'Password must contain at least one special character';
const matchPasswordMessage = 'Passwords are not the same!';

const emailSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: tldsList } }),
});

const isEmailValid = (val) => {
  const result = emailSchema.validate({ email: val });
  if (path(['error'])(result)) return false;
  return true;
};

const phoneRegExp = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

export const loginForm = object().shape({
  email: string()
    .trim()
    .required(requiredMessage('Email')),
  password: string()
    .trim()
    .required(requiredMessage('Password')),
});

export const changePasswordForm = object().shape({
  currentPassword: string()
    .trim()
    .required(requiredMessage('Current password')),
  newPassword: string()
    .trim()
    .min(8, passwordMinCharacters)
    .matches(oneUpperRegExp, passwordOneUpperMessage)
    .matches(oneLowerRegExp, passwordOneLowerMessage)
    .matches(specialCharacterRegExp, specialCharacterMessage)
    .required(requiredMessage('New password')),
  EnterPasswordAgain: string()
    .trim()
    .oneOf([ref('newPassword'), null], matchPasswordMessage)
    .required(requiredMessage('Enter new password')),
});

export const createPasswordForm = object().shape({
  password: string()
    .trim()
    .min(8, passwordMinCharacters)
    .matches(oneUpperRegExp, passwordOneUpperMessage)
    .matches(oneLowerRegExp, passwordOneLowerMessage)
    .matches(specialCharacterRegExp, specialCharacterMessage)
    .required(requiredMessage('Password')),
  rePassword: string()
    .trim()
    .oneOf([ref('password'), null], matchPasswordMessage)
    .required(requiredMessage('Re-Enter Password')),
});

export const verificationCodeForm = object().shape({
  twoFactorCode: string()
    .trim()
    .matches(codeRegExp, codeMessage),
});

export const patientInfoForm = object().shape({
  medicalRecordNumber: string().trim().required('MRN is a required field'),
});

export const billApprovalForm = (paymentMethodValidation) => object().shape({
  contactName: string().trim().required('Contact Name is a required field'),
  ach: equals(paymentMethodValidation, 'ach') && object().shape({
    accountHolderName: string().trim().required('Account Holder Name is a required field'),
    routingNumber: number().typeError('Must be a number').required('Routing Number is a required field'),
    accountNumber: number().typeError('Must be a number').required('Account Number is a required field'),
  }),
  check: equals(paymentMethodValidation, 'check') && object().shape({
    accountHolderName: string().trim().required('Account Holder Name is a required field'),
    line1: string().trim().required('Address is a required field'),
    line2: string().trim(),
    locality: string().trim().required('City is a required field'),
    region: string().trim().required('State is a required field'),
    postalCode: string().trim().required('Zip Code is a required field'),
  }),
});

export const patientInviteForm = () => {
  const maxDate = new Date();

  return object().shape({
    firstName: string()
      .trim()
      .required('First Name is a required field'),
    lastName: string()
      .trim()
      .required('Last Name is a required field'),
    email: string()
      .trim()
      .test('email', 'Must be a valid email', isEmailValid)
      .required('Email is a required field'),
    totalAmount: number()
      .min(250, 'Not less than $250')
      .max(10000, 'Not more than $10,000')
      .required('Amount is a required field'),
    invoiceId: string().trim(),
    phone: string()
      .trim()
      .matches(phoneRegExp, 'Phone number is not valid'),
    medicalRecordNumber: string()
      .trim()
      .required('MRN/Account number is a required field'),
    servicingDate: date()
      .typeError('Must be a valid date')
      .max(new Date(maxDate), `Service Date should be equal or earlier than ${format(maxDate, 'MM/dd/yyyy')}`),
    agree: boolean().oneOf([true]),
  });
};

export const createUserForm = object().shape({
  firstName: string()
    .trim()
    .required('First Name is a required field'),
  lastName: string()
    .trim()
    .required('Last Name is a required field'),
  email: string()
    .trim()
    .test('email', 'Must be a valid email', isEmailValid)
    .required('Email is a required field'),
  phone: string()
    .trim()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is a required field'),
  providerId: string()
    .trim()
    .required('Provider is a required field'),
  role: string()
    .trim()
    .required('Role is a required field'),
});

export const vaultForm = object().shape({
  param: string()
    .trim()
    .required('Field is a required field'),
  value: string()
    .trim()
    .required('value is a required field'),
});
