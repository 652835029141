export const providerSelectFilterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  stringify: (option) => option.label,
};

export const providerSelectCustomStyles = {
  control: (provided) => ({
    ...provided,
    height: '100%',
    'border-radius': '100px',
  }),
  container: (provided) => ({
    ...provided,
    height: '100%',
  }),
};
