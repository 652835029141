import React from 'react';
import Icon from 'components/Icon';
import Button from 'components/Button';

const SeeDetails = ({ patientInfo, setCurrentPatient, setOpen }) => (
  <Button
    type="primary"
    className="btn-sm px-7"
    onClick={() => {
      setCurrentPatient(patientInfo);
      setOpen(true);
    }}
  >
    See Details
  </Button>
);

export const renderCell = (row, cell, setCurrentPatient, setOpen) => {
  if (cell.column.id === 'id') return <SeeDetails patientInfo={row.original} setCurrentPatient={setCurrentPatient} setOpen={setOpen} />;
  return <span className="text-break">{cell.render('Cell')}</span>;
};

export const renderColumnOrderIcon = (column) => {
  if (column.isSorted && column.isSortedDesc) return <Icon name="caret-down" />;
  if (column.isSorted && !column.isSortedDesc) return <Icon name="caret-up" />;
  return null;
};
