import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ name, className, onClick }) => (
  <svg className={`icon icon-${name} ${className}`} onClick={onClick} data-testid="icon-component">
    <use xlinkHref={`#icon-${name}`} />
  </svg>
);

export default Icon;

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  className: '',
  onClick: () => {},
};
