import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import {
  path, compose, head, isNil, prop,
} from 'ramda';

import Layout from 'components/Layout';
import { Input } from 'components/Fields';
import Button from 'components/Button';
import Icon from 'components/Icon';

import { PROVIDER_ID_NAME } from 'libs/authenticationService';

import { verificationCodeForm } from 'utils/validationSchemas';
import getOrganizationId from 'utils/getOrganizationId';
import getProviderId from 'utils/getProviderId';
import getErrorMessage from 'utils/getErrorMessage';

const initialValues = {
  twoFactorCode: '',
};

const VerificationCode = ({
  verificationCode,
  twoFactorToken,
  startSpinner,
  stopSpinner,
  getOrganizationProviders,
  getProviderPermissions,
}) => {
  const history = useHistory();
  if (isNil(twoFactorToken)) history.push('/login');

  const goToRootPath = () => {
    stopSpinner();
    return history.push('/');
  };

  const onFailure = (error) => {
    const message = getErrorMessage(error);
    stopSpinner();
    toast.error(message);
  };

  const onSelectProviderSuccess = (response) => {
    const providerId = compose(
      prop('providerId'),
      head,
      path(['value', 'providers']),
    )(response);

    sessionStorage.setItem(PROVIDER_ID_NAME, providerId);

    return goToRootPath();
  };

  const selectProvider = (organizationId) => getOrganizationProviders(organizationId)
    .then(onSelectProviderSuccess)
    .catch(onFailure);

  const onSubmitSuccess = async () => {
    const organizationId = getOrganizationId();
    if (organizationId) return selectProvider(organizationId);
    const providerPermissions = await getProviderPermissions();
    if (providerPermissions.value.length) {
      const currentProviderId = getProviderId();
      sessionStorage.setItem('PROVIDER_ID', currentProviderId);
    }
    return goToRootPath();
  };

  const onSubmit = (data, actions) => {
    startSpinner();

    verificationCode({ ...data, twoFactorToken })
      .then(onSubmitSuccess)
      .catch(onFailure)
      .finally(actions.setSubmitting(false));
  };

  return (
    <Layout noLoggedIn>
      <div className="min-vh-100 d-flex flex-column justify-content-center text-center">
        <div>
          <Icon name="verification-code" className="provider-verification-code text-dodger-blue" />
          <p className="text-tuna letter-spacing mb-5">We sent you a verification code to your phone</p>
          <div className="provider-form">
            <Formik
              initialValues={initialValues}
              validationSchema={verificationCodeForm}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field id="twoFactorCode" type="text" name="twoFactorCode" label="Please Enter Code" autoFocus component={Input} />
                  <Button type="primary" className="btn-block mt-4" isSubmit disabled={isSubmitting}>Continue</Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VerificationCode;
