import React from 'react';

import { StateLessModal } from 'components/MainModal';
import Button from 'components/Button';

const ConfirmModal = ({ isOpen, setIsOpen, onSubmit }) => {
  const onClickHandler = () => {
    onSubmit();
  };

  return (
    <StateLessModal
      isOpen={isOpen}
      contentLabel="Confirm Expire Invite Modal"
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onCloseHandler={() => { }}
    >
      <div className="invites-modal invites-modal--confirm">
        <div className="invites-modal__content">
          <div className="text-left mb-5">
            <h1 className="text-tuna font-weight-semibold text-xlarger mb-1 text-center">
              Are you sure?
            </h1>
          </div>
          <div className="row smaller-gutters">
            <div className="col-sm-6">
              <Button
                type="outline-primary"
                className="btn-block"
                onClick={() => { setIsOpen(false); }}
              >
                Cancel
              </Button>
            </div>
            <div className="col-sm-6">
              <Button
                type="primary"
                className="btn-block"
                onClick={onClickHandler}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </StateLessModal>
  );
};

export default ConfirmModal;
