import { authenticationService, httpMethods, apiUrls } from 'libs';

const { apiCall } = authenticationService;
const { POST, GET } = httpMethods;
const {
  LOGIN,
  LOGIN_ADMIN,
  SET_NEW_PASSWORD,
  EXCHANGE_CODE,
  TWO_FACTOR_CODE_VERIFICATION,
  GET_USER,
  UPDATE_PASSWORD,
  REFRESH_TOKEN,
  GET_ORGANIZATION_PROVIDERS,
  GET_DEFAULT_PROVIDER,
  GET_PROVIDER_PERMISSIONS,
  LOGOUT,
} = apiUrls;

export const reqLogin = (data) => apiCall(POST, LOGIN, { data });

export const reqLoginAdmin = (data) => apiCall(POST, LOGIN_ADMIN, { data });

export const reqSetNewPassword = (data) => apiCall(POST, SET_NEW_PASSWORD, { data });

export const reqExchangeAuthCode = (params) => apiCall(GET, EXCHANGE_CODE, { params });

export const reqVerificationCode = (data) => apiCall(POST, TWO_FACTOR_CODE_VERIFICATION, { data });

export const reqGetUser = () => apiCall(GET, GET_USER);

export const reqUpdatePassword = (data) => apiCall(POST, UPDATE_PASSWORD, { data });

export const reqRefreshToken = (data) => apiCall(POST, REFRESH_TOKEN, { data });

export const reqGetOrganizationProviders = (organizationId) =>
  apiCall(GET, GET_ORGANIZATION_PROVIDERS(organizationId));

export const reqGetDefaultProvider = (organizationId) =>
  apiCall(GET, GET_DEFAULT_PROVIDER(organizationId));

export const reqGetProviderPermissions = () =>
  apiCall(GET, GET_PROVIDER_PERMISSIONS);

export const reqLogout = () => apiCall(POST, LOGOUT);
