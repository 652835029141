import React, { useEffect, useState } from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import {
  compose, path, head, prop, isEmpty, isNil,
} from 'ramda';
import { toast } from 'react-toastify';

import Layout from 'components/Layout';
import Icon from 'components/Icon';
import Table from 'components/DataExchange/Table/Table';

import getOrganizationId from 'utils/getOrganizationId';

const columnsUpload = [{
  Header: 'Download Time Stamp',
  accessor: 'createdAt',
}, {
  Header: 'File Size (bytes)',
  accessor: 'fileSize',
}, {
  Header: 'No of Records in File',
  accessor: 'numberOfRecordsInFile',
}, {
  Header: 'No of Records Processed',
  accessor: 'numberOfRecordsProcessed',
}, {
  Header: 'No of Failed Records',
  accessor: 'numberOfFailedRecords',
}];

const columnsDownloaded = [{
  Header: 'File Name',
  accessor: 'fileName',
}, {
  Header: 'File Size (bytes)',
  accessor: 'size',
}, {
  Header: 'Modified Date',
  accessor: 'modifiedAt',
}];

const columns = [{
  Header: 'Status',
  accessor: 'status',
}, {
  Header: 'Download Time Stamp',
  accessor: 'downloadTimeStamp',
}, {
  Header: 'File Size (MB)',
  accessor: 'fileSize',
}, {
  Header: 'No of Records',
  accessor: 'records',
}];

const DataExchange = ({
  getProviderUploadedFiles,
  getProviderReportsCredentials,
  getProviderListReports,
  dataExchange: { uploadedFiles, downloadedFiles },
  providerId,
  isDemoUser,
  email,
  startSpinner,
  stopSpinner,
  reportsCredentialsUrl,
}) => {
  const alertInfo = {
    type: 'info',
    message: 'Everything is syncing correctly. Check below on last week\'s data updates.',
  };

  const alertError = {
    type: 'error',
    message: 'The last enrollment data download failed. Please try again.',
  };

  const alertDataDefault = isDemoUser ? alertInfo : {};
  const [alert, setAlert] = useState(alertDataDefault);

  const defaultProviderId = sessionStorage.getItem('DEFAULT_PROVIDER_ID');
  const organizationId = defaultProviderId === providerId ? getOrganizationId() : undefined;

  const onFailure = (error) => {
    stopSpinner();
    const statusCode = path(['response', 'status'])(error);
    const errorMessage = compose(prop('message'), head, path(['response', 'data', 'errors']))(error);
    if (statusCode !== 401) { return setAlert(alertError); }
    return toast.error(errorMessage);
  };

  const getProviderListReportsSuccess = () => {
    stopSpinner();
    setAlert(alertInfo);
  };

  const getProviderUploadedFilesSuccess = () => {
    getProviderListReports({
      providerId,
      organizationId,
    })
      .then(getProviderListReportsSuccess)
      .catch(onFailure);
  };

  useEffect(() => {
    if (!isNil(email) && !isDemoUser) {
      startSpinner();

      getProviderUploadedFiles({
        providerId,
        organizationId,
      })
        .then(getProviderUploadedFilesSuccess)
        .catch(onFailure);
    }
  }, [providerId, email]);

  useEffect(() => {
    if (!isNil(providerId)) {
      startSpinner();

      getProviderReportsCredentials({
        providerId,
        organizationId,
      })
        .then(() => stopSpinner())
        .catch(() => stopSpinner());
    }
  }, [providerId]);

  const renderIcon = (type) => {
    switch (type) {
      case 'info':
        return 'circle-check-outline';
      case 'error':
        return 'circle-warning-outline';
      default:
        return '';
    }
  };

  return (
    <Layout logoutBtnClass="logout-btn--data-exchange">
      <div className="data-exchange h-100 position-relative">
        {!isEmpty(alert) && (
          <div className={`data-exchange__notification ${alert.type}`}>
            <div className="d-inline-flex align-items-center">
              <Icon name={renderIcon(alert.type)} className="h2 align-baseline mb-0 mr-3" />
              <p className="mb-0">
                {alert.message}
              </p>
            </div>
          </div>
        )}
        <div className="text-center mb-6">
          <h1 className="h4 font-weight-normal letter-spacing mb-0">Data Exchange</h1>
        </div>
        <Tabs className="tabs h-100 d-flex flex-column">
          <TabList className="tabs__tab-list">
            <Tab className="tabs__tab" selectedClassName="is-active">
              <Icon name="upload" className="text-base align-baseline mr-6" />
              Update Billing Data
            </Tab>
            <Tab className="tabs__tab" selectedClassName="is-active">
              <Icon name="download" className="text-base align-baseline mr-3" />
              Download Enrollment Data
            </Tab>
          </TabList>
          <TabPanel className="tabs__tab-panel flex-fill" selectedClassName="h-100 is-active">
            <div className="card mh-100">
              <div className="tabs__tab__header text-center flex-shrink-0">
                <h1 className="h6 text-tuna mb-3">Past Uploads</h1>
                <div className="text-comet">
                  <p className="mb-0">
                    These uploads keep an up-to-date record of patient balances.
                  </p>
                  <p className="mb-0">
                    The data is secure and encrypted in the PayZen Vault.
                  </p>
                </div>
              </div>
              <Table
                columns={isDemoUser ? columns : columnsUpload}
                data={uploadedFiles}
              />
            </div>
          </TabPanel>
          <TabPanel className="tabs__tab-panel flex-fill" selectedClassName="h-100 is-active">
            <div className="card mh-100">
              <div className="tabs__tab__header tabs__tab__header--alt text-center flex-shrink-0">
                <p className="text-comet mb-0">
                  These downloads keep your systems up-to-date on patient payments.
                </p>
              </div>
              <Table
                columns={isDemoUser ? columns : columnsDownloaded}
                data={downloadedFiles}
                reportsUrl={reportsCredentialsUrl}
              />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </Layout>
  );
};

export default DataExchange;
