import { connect } from 'react-redux';
import { equals, pathOr } from 'ramda';

import { getProviderUploadedFiles, getProviderReportsCredentials, getProviderListReports } from 'actions/provider';
import { startSpinner, stopSpinner } from 'actions/spinner';

import getProviderId from 'utils/getProviderId';

import { dataBilling, dataEnrollment } from 'helpers/mocks';

import DataExchange from './DataExchange';

const mapStateToProps = ({ user: { email }, provider }) => {
  const providerId = getProviderId();
  const isDemoUser = equals(email, 'demo@provider.com');

  const fakeData = {
    uploadedFiles: dataBilling,
    downloadedFiles: dataEnrollment,
  };

  const realData = {
    uploadedFiles: pathOr({}, ['dataExchange', 'uploadedFiles'])(provider),
    downloadedFiles: pathOr({}, ['dataExchange', 'downloadedFiles'])(provider),
  };

  const dataExchange = isDemoUser ? fakeData : realData;
  const { reportsCredentialsUrl } = provider;

  return {
    dataExchange, providerId, isDemoUser, email, reportsCredentialsUrl,
  };
};

const mapDispatchToProps = {
  getProviderUploadedFiles,
  getProviderReportsCredentials,
  getProviderListReports,
  startSpinner,
  stopSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataExchange);
