import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  children, type, id, onClick, className, disabled, isSubmit, ...rest
}) => {
  const buttonClass = type ? `btn-${type}` : '';
  const disabledClass = disabled ? 'is-disabled' : '';
  const classes = `btn ${buttonClass} ${className} ${disabledClass}`;

  return (
    <>
      {
        isSubmit ? (
          <button
            id={id}
            className={classes}
            onClick={onClick}
            disabled={disabled}
            type="submit"
            {...rest}
          >
            {children}
          </button>
        ) : (
          <button
            className={classes}
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              onClick();
            }}
            disabled={disabled}
            type="button"
              {...rest}
          >
            {children}
          </button>
        )
      }
    </>
  );
};

Button.defaultProps = {
  id: '',
  onClick: () => false,
  className: '',
  disabled: false,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
