import React from 'react';
import {
  compose,
  filter,
  isEmpty,
  values,
} from 'ramda';
import { toast } from 'react-toastify';

import { StateLessModal } from 'components/MainModal';
import { Field, Form, Formik } from 'formik';
import { Input } from 'components/Fields';
import Button from 'components/Button';

import { reqGetVaultPatientDetail } from 'AdminSite/api/admin';
import { vaultForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';

const FIELDS = [
  'payzenMedicalRecordNumber',
  'payzenPatientSSN',
  'payzenPatientRawAddress',
  'payzenPatientAddressPostcode',
  'payzenServiceCost',
  'payzenServiceDue',
];

const ConfirmModal = ({
  isOpen,
  setIsOpen,
  patient,
  setVaultPatientResponse,
  setOpenPatientResponse,
  startSpinner,
  stopSpinner,
}) => {
  const initialValues = Object.freeze({
    param: null,
    value: '',
  });

  const onSuccess = (response) => {
    setVaultPatientResponse(response.data.patient);
    setOpenPatientResponse(true);
    setIsOpen(false);
  };

  const onFailure = (error) => {
    const message = getErrorMessage(error);
    toast.error(message);
  };

  const onSubmit = (formData, actions) => {
    startSpinner();

    const data = {
      providerId: patient.providerId,
      payzenPatientLastName: patient.payzenPatientLastName,
      payzenOpenVaultId: patient.payzenOpenVaultId,
      params: { ...formData },
    };

    reqGetVaultPatientDetail({ data })
      .then(onSuccess)
      .catch(onFailure)
      .finally(() => {
        actions.setSubmitting(false);
        stopSpinner();
      });
  };

  return (
    <StateLessModal
      isOpen={isOpen}
      contentLabel="Decrypt Modal"
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onCloseHandler={() => setIsOpen(false)}
    >
      <div className="invites-modal">
        <div className="invites-modal__content">
          <div className="text-left mb-5">
            <h1 className="text-tuna font-weight-semibold text-large mb-0">
              Confirm Patient Info
            </h1>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={vaultForm}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values: formData, errors }) => {
              const isDataComplete = !compose(isEmpty, filter(isEmpty), values)(formData);
              const withErrors = !isEmpty(errors);
              const isDisabled = isSubmitting || isDataComplete || withErrors;

              return (
                <Form>
                  <div className="mb-4">
                    <div className="row smaller-gutters">
                      <div className="col-sm-6">
                        <Field
                          id="param"
                          type="select"
                          name="param"
                          label="Select Field"
                          component={Input}
                          options={FIELDS.map((field) => ({ label: field, param: field }))}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.param}
                          isRequired
                        />
                      </div>
                      <div className="col-sm-6">
                        <Field
                          id="value"
                          type="text"
                          name="value"
                          label="Enter Value"
                          component={Input}
                          isRequired
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row smaller-gutters">
                    <div className="col-sm-6">
                      <Button
                        type="outline-primary"
                        className="btn-block"
                        onClick={() => setIsOpen(false)}
                        disabled={isDisabled}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-sm-6">
                      <Button
                        type="primary"
                        className="btn-block"
                        isSubmit
                        disabled={isDisabled}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </StateLessModal>
  );
};

export default ConfirmModal;
