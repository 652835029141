import { connect } from 'react-redux';
import { path } from 'ramda';

import {
  getProviderReports,
  downloadProviderPaymentsCSV,
} from 'actions/provider';
import { startSpinner, stopSpinner } from 'actions/spinner';
import { adminGetProviders } from 'AdminSite/actions/admin';

import getRole from 'utils/getRole';

import Reports from './Reports';

const mapStateToProps = ({
  user: { email },
  provider: { reports, csvStatus },
  adminPatients: { providers },
}) => {
  const { trend, trendData, trendCategories } = reports;

  const patientCount = path(['patientCount'])(reports);
  const totalAmountEnrolled = path(['totalAmountEnrolled'])(reports);
  const totalAmountPreFunded = path(['totalAmountPreFunded'])(reports);
  const role = getRole();

  return {
    patientCount,
    totalAmountEnrolled,
    totalAmountPreFunded,
    trendCategories,
    trendData,
    trend,
    role,
    email,
    csvStatus,
    providers,
  };
};

const mapDispatchToProps = {
  getProviderReports,
  downloadProviderPaymentsCSV,
  startSpinner,
  stopSpinner,
  adminGetProviders,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reports);
