import { connect } from 'react-redux';

import {
  adminGetProviders,
  createUser,
} from 'AdminSite/actions/admin';

import {
  startSpinner,
  stopSpinner,
} from 'actions/spinner';

import CreateUsers from './CreateUsers';

const mapStateToProps = ({
  adminPatients: {
    providers,
  },
}) => ({
  providers,
});

const mapDispatchToProps = {
  adminGetProviders,
  createUser,
  startSpinner,
  stopSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateUsers);
