import { connect } from 'react-redux';
import { prop } from 'ramda';

import getProviderId from 'utils/getProviderId';

import {
  getPatientInvites,
  createPatientInvite,
  updatePatientInvite,
  expirePatientInvite,
  prolongPatientInvite,
  getPatientsInvitesStats,
} from 'actions/provider';

import {
  startSpinner,
  stopSpinner,
} from 'actions/spinner';
import Invites from './Invites';

const mapStateToProps = ({
  user: { email },
  provider,
}) => {
  const invites = prop('invites', provider);
  const providerId = getProviderId();

  return {
    invites,
    email,
    providerId,
  };
};

const mapDispatchToProps = {
  getPatientInvites,
  createPatientInvite,
  updatePatientInvite,
  expirePatientInvite,
  prolongPatientInvite,
  getPatientsInvitesStats,
  startSpinner,
  stopSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Invites);
