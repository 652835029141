import React from 'react';
import { path } from 'ramda';

import Layout from 'components/Layout';

import iconSuccess from 'assets/images/check-color.png';

const BillApproval = ({ location }) => {
  const offerApproved = path(['state', 'offerApproved'])(location);
  const message = path(['state', 'message'])(location);

  return (
    <Layout noLoggedIn providerFrom>
      <div className="provider-payment-plan w-100 h-100">
        <div className="wrapper h-100">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="py-8 text-center">
              <img src={iconSuccess} alt="" className="mb-5" />
              <h1 className="text-cornflower-blue mb-4">You Are All Set</h1>
              <p className="text-large text-trout">
                {offerApproved
                  ? message
                  : 'Please check your email for a confirmation from us'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BillApproval;
