const isString = (value) => typeof value === 'string';

export const capitalize = (val) => {
  if (!val) return val;
  if (!isString(val)) return val;
  return val.trim() && val[0].toUpperCase() + val.slice(1);
};

export const toUpperCase = (val) => {
  if (!val) return val;
  if (!isString(val)) return val;
  return val.trim() && val.toUpperCase();
};
