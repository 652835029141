import { createReducer } from '@reduxjs/toolkit';
import { adminGetProviders } from 'AdminSite/actions/admin';
import { PENDING, REJECTED, FULFILLED } from 'libs/promiseStatuses';

const initialState = {
  getProvidersStatus: null,
  providers: [],
};

export default createReducer(initialState, {
  [String(adminGetProviders.pending)]: (state) => ({
    ...state,
    getProvidersStatus: PENDING,
  }),
  [String(adminGetProviders.fulfilled)]: (state, action) => ({
    ...state,
    getProvidersStatus: FULFILLED,
    providers: action.payload.providers,
  }),
  [String(adminGetProviders.rejected)]: (state) => ({
    ...state,
    getProvidersStatus: REJECTED,
  }),
});
