import React from 'react';
import Chart from 'react-apexcharts';
import { map } from 'ramda';

const SHOW_MARKERS_THRESHOLD = 20;

const LineChart = ({ categories, data }) => {
  const state = {
    options: {
      chart: {
        id: 'basic-bar',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        stacked: false,
      },
      stroke: {
        colors: '#4D7EFE',
        width: 1.5,
      },
      xaxis: {
        categories: map((item) => new Date(item).getTime())(categories),
        type: 'datetime',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      markers: data.length < SHOW_MARKERS_THRESHOLD ? {
        size: 4,
        colors: '#FFF',
        strokeColor: '#4D7EFE',
        strokeWidth: 2,
        fillOpacity: 0,
        strokeOpacity: 1,
        hover: {
          size: 8,
        },
      } : {
        size: 0,
        hover: {
          size: 6,
        },
      },
      yaxis: {
        forceNiceScale: true,
      },
      grid: {
        borderColor: '#CDCDCF',
        strokeDashArray: 1,
        padding: {
          right: 15,
          left: 15,
        },
      },
    },
    series: [{
      name: '',
      type: 'line',
      data,
    }],
  };

  return (
    <Chart
      options={state.options}
      series={state.series}
      type="line"
      className="my-auto w-100"
    />
  );
};

export default LineChart;
