import React from 'react';
import Cleave from 'cleave.js/react';

const PhoneInput = ({
  id, placeholder, className, disabled, name, setField, value, onBlur,
}) => (
  <Cleave
    id={id}
    name={name}
    className={className}
    placeholder={placeholder}
    onChange={(e) => setField(name, e.target.rawValue)}
    onBlur={onBlur}
    disabled={disabled}
    value={value}
    options={{
      delimiters: ['(', ') ', '-'],
      blocks: [0, 3, 3, 4],
      numericOnly: true,
    }}
  />
);

export default PhoneInput;
