import { is } from 'ramda';

export const anonymizeName = (name) => {
  if (is(String, name)) return `${name.substring(0, 1)}.`;
  return name;
};

export const anonymizeEmail = (email) => {
  if (is(String, email)) {
    const emailSplitted = email.split('@');
    const username = emailSplitted[0];
    const domain = `@${emailSplitted[1]}`;

    return `${username.substring(0, 3)}***${domain}`;
  }

  return email;
};
