import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { isEmpty } from 'ramda';
import Recaptcha from 'react-google-invisible-recaptcha';
import { v4 as uuidv4 } from 'uuid';

import Layout from 'components/Layout';
import { Input } from 'components/Fields';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner/Spinner';

import { loginForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';
import { SESSION_ID } from 'libs/authenticationService';

import AuthProvidersList from './AuthProvidersList';
import NoAuthAvailable from './NoAuthAvailable';

const initialValues = {
  email: '',
  password: '',
};

const isRecaptchaEnabled = !!process.env.REACT_APP_RECAPTCHA_KEY;

const Login = ({
  login,
  getAuthStrategies,
  startSpinner,
  stopSpinner,
  isPayzenAdminSite,
  basicAuth,
  authIdentityProviders,
}) => {
  const history = useHistory();
  const recaptchaRef = useRef(null);
  const [showNoAuthText, setShowNoAuthText] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(null);
  const [formActions, setFormActions] = useState(null);
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);

  useEffect(() => {
    sessionStorage.clear();
    sessionStorage.setItem(SESSION_ID, uuidv4());
    if (isPayzenAdminSite) {
      setIsLoading(false);
    } else {
      getAuthStrategies().then((data) => {
        setIsLoading(false);
        const { value } = data;
        if (!value || value.length === 0) {
          setShowNoAuthText(true);
        }
      }).catch((error) => {
        setIsLoading(false);
        if (error.response.statusCode === 404) {
          setShowNoAuthText(true);
          window.location.replace('https://payzen.com');
        }
      });
    }
  }, []);

  const onSubmitSuccess = ({ value }) => {
    stopSpinner();
    if (value && value.token) {
      history.push('/');
    }
    if (value && value.twoFactorToken) {
      history.push('/verification-code');
    }
    if (value && value.resetPassToken) {
      history.push('/create-password');
    }
  };

  const onSubmitFailure = (error) => {
    if (isRecaptchaEnabled) {
      recaptchaRef.current.reset();
    }

    stopSpinner();
    const message = getErrorMessage(error);
    toast.error(message);
  };

  const startLogin = (reCaptchaResponse) => {
    startSpinner();
    login({ ...formData, reCaptchaResponse })
      .then(onSubmitSuccess)
      .catch(onSubmitFailure)
      .finally(formActions.setSubmitting(false));
  };

  useEffect(() => {
    if (formData && formActions && (!isRecaptchaEnabled || recaptchaResponse)) {
      startLogin(recaptchaResponse);
    }
  }, [formData, formActions, recaptchaResponse]);

  const onSubmit = async (data, actions) => {
    setFormData(data);
    setFormActions(actions);

    if (isRecaptchaEnabled) {
      recaptchaRef.current.execute();
    }
  };

  const onResolved = () => {
    setRecaptchaResponse(recaptchaRef.current.getResponse());
  };

  return (
    <Layout noLoggedIn>
      <div className="min-vh-100 d-flex flex-column justify-content-center text-center">
        {isLoading && <Spinner message="Loading" pendingTask={1} />}
        {!isLoading && (
          <NoAuthAvailable show={showNoAuthText}>
            <div>
              <Icon name="login" className="provider-login text-dodger-blue mb-5" />
              <h1 className="h4 font-weight-normal letter-spacing mb-5">
                {basicAuth && !isEmpty(authIdentityProviders) ? 'Single Sign-On' : 'Login'}
              </h1>
              <AuthProvidersList authStrategies={authIdentityProviders} />
              {basicAuth && !isEmpty(authIdentityProviders) && <p align="center">OR</p>}
              {basicAuth && (
                <div className="provider-form">
                  <Formik
                    initialValues={{
                      ...initialValues,
                      providerAuthStrategyId: basicAuth.providerAuthStrategyId,
                    }}
                    validationSchema={loginForm}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="mb-5">
                          <Field id="email" type="email" name="email" label="Email" component={Input} />
                          <Field id="password" type="password" name="password" label="Password" component={Input} />
                        </div>
                        <Button type="primary" className="btn-block mb-4" isSubmit disabled={isSubmitting}>Continue</Button>
                        <div className="text-center">
                          <p className="text-smaller mb-0">
                            This site is protected by reCAPTCHA and the Google
                            {' '}
                            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                            {' '}
                            and
                            {' '}
                            <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                            {' '}
                            apply.
                          </p>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </NoAuthAvailable>
        )}
      </div>
      {isRecaptchaEnabled && (
        <Recaptcha
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          onResolved={onResolved}
        />
      )}
    </Layout>
  );
};

export default Login;
