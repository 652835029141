import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {
  divide, compose, prop, head, __, propOr, isEmpty, not,
} from 'ramda';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';

import Layout from 'components/Layout';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { Input } from 'components/Fields';
import ProviderFormWrapper from 'components/ProviderFormWrapper';

import { patientInfoForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';

const PatientInfo = ({
  updateMrn,
  startSpinner,
  stopSpinner,
  bills,
  billFilesWithUrls,
  offer,
  user,
  provider,
  patientDocuments,
}) => {
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const totalAmount = compose(divide(__, 100), prop('totalAmount'))(offer);
  const monthlyAmount = compose(divide(__, 100), head, prop('paymentsAmount'))(offer);
  const totalPayment = propOr('', 'totalPayment')(offer);
  const firstName = propOr('', 'firstName')(user);
  const lastName = propOr('', 'lastName')(user);
  const bill = head(bills);
  const billId = propOr('', 'billId')(bill);
  const accountId = propOr('', 'accountId')(bill);
  const providerName = propOr('', 'providerName')(provider);
  const totalBalance = compose(divide(__, 100), prop('totalAmount'))(bill);

  const hipaaDoc = patientDocuments
    .find((d) => d.documentType === 'AUTH_FOR_USE_OR_DISCLOSURE_OF_HEALTH_INFORMATION_PARTNER');
  const paymentAgreementDoc = patientDocuments
    .find((d) => d.documentType === 'PAYMENT_PLAN_AGREEMENT');

  const initialValues = {
    medicalRecordNumber: accountId,
  };

  const onSubmitSuccess = () => {
    stopSpinner();
    history.push(`/provider-form/bill-approval${history.location.search}`);
  };

  const onSubmitFailure = (error) => {
    stopSpinner();
    const message = getErrorMessage(error);
    toast.error(message);
  };

  const onSubmit = (data) => {
    startSpinner();

    updateMrn({ ...data, billId })
      .then(onSubmitSuccess)
      .catch(onSubmitFailure);
  };

  const mrnRef = useRef(null);

  useEffect(() => {
    if (isEmpty(accountId)) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [accountId]);

  useEffect(() => {
    if (isEditing) {
      mrnRef.current.focus();
    }
  }, [isEditing]);

  return (
    <Layout noLoggedIn providerFrom>
      <ProviderFormWrapper>
        <div className="provider-payment-plan w-100">
          <div className="wrapper">
            <div className="text-center mb-6">
              <h1 className="h4 text-tuna mb-5">PayZen Payment Plan Approval</h1>
              <div className="provider-payment-plan__steps text-uppercase text-smaller text-jumbo font-weight-semibold d-flex justify-content-between mx-auto">
                <span className="is-active">1. Patient info</span>
                <span>2. Bill Approval</span>
              </div>
            </div>
            <div>
              <Formik
                initialValues={initialValues}
                validationSchema={patientInfoForm}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ isSubmitting }) => (
                  <Form>
                    <h2 className="h4 font-weight-semibold text-comet mb-6">1. Patient info</h2>
                    <div className="media mb-6">
                      <Icon name="patient" className="text-large text-jumbo mr-2" />
                      <div className="media-body">
                        <h3 className="text-large font-weight-light mb-4">Patient</h3>
                        <div className="d-flex mb-3">
                          <div className="mr-12">
                            <label className="text-uppercase text-xsmall text-bombay mb-0">First Name</label>
                            <p className="text-jumbo mb-0">{firstName}</p>
                          </div>
                          <div className="mr-12">
                            <label className="text-uppercase text-xsmall text-bombay mb-0">Last Name</label>
                            <p className="text-jumbo mb-0">{lastName}</p>
                          </div>
                          <div>
                            {isEditing ? (
                              <Field
                                id="medicalRecordNumber"
                                type="text"
                                name="medicalRecordNumber"
                                label="MRN"
                                component={Input}
                                innerRef={mrnRef}
                              />
                            ) : (
                              <>
                                <label className="text-uppercase text-xsmall text-bombay mb-0">MRN</label>
                                <p className="text-jumbo mb-0">
                                  {accountId}
                                  <Icon
                                    name="edit-square"
                                    className="text-dodger-blue text-small ml-2 align-middle"
                                    onClick={() => setIsEditing(true)}
                                  />
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        {
                          hipaaDoc ? (
                            <a
                              // eslint-disable-next-line react/jsx-no-target-blank
                              target="_blank"
                              href={hipaaDoc.url}
                              className="text-decoration-none"
                            >
                              Authorization for use and disclosure of health information
                              (HIPAA Authorization)
                            </a>
                          ) : null
                        }
                      </div>
                    </div>

                    <div className="media mb-6">
                      <Icon name="balance" className="text-large text-jumbo mr-2" />
                      <div className="media-body">
                        <h3 className="text-large font-weight-light mb-4">Patient balance & billing statement</h3>
                        <div className="d-flex mb-3">
                          <div className="mr-12">
                            <label className="text-uppercase text-xsmall text-bombay mb-0">Provider Name</label>
                            <p className="text-jumbo mb-0">{providerName}</p>
                          </div>
                          <div className="">
                            <label className="text-uppercase text-xsmall text-bombay mb-0">Total Balance</label>
                            <p className="text-jumbo mb-0">
                              <NumberFormat
                                value={totalBalance}
                                decimalScale={2}
                                displayType="text"
                                prefix="$"
                                thousandSeparator
                                fixedDecimalScale
                              />
                            </p>
                          </div>
                        </div>
                        {compose(not, isEmpty)(billFilesWithUrls) && (
                          <ul className="list-unstyled">
                            {billFilesWithUrls.map((billFile) => (
                              <li key={billFile.billFileId}>
                                <a
                                  // eslint-disable-next-line react/jsx-no-target-blank
                                  target="_blank"
                                  href={billFile.url}
                                  className="text-decoration-none"

                                >
                                  {billFile.originalFileName}
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="media mb-8">
                      <Icon name="payment-plan" className="text-large text-jumbo mr-2" />
                      <div className="media-body">
                        <h3 className="text-large font-weight-light mb-4">Payment plan</h3>
                        <div className="d-flex align-items-center mb-1 bg-alice-blue-alt provider-payment-plan__box">
                          <div className="flex-fill text-center">
                            <NumberFormat
                              value={totalAmount}
                              decimalScale={2}
                              displayType="text"
                              prefix="$"
                              thousandSeparator
                              fixedDecimalScale
                              className="text-charade text-xlarger font-weight-light"
                            />
                            <br />
                            <span className="text-uppercase text-tundora text-smaller">Patient Account balance</span>
                          </div>
                          <div className="flex-fill text-center">
                            <span className="text-charade text-xlarger font-weight-light">
                              <NumberFormat
                                value={monthlyAmount}
                                decimalScale={2}
                                displayType="text"
                                prefix="$"
                                suffix="/"
                                fixedDecimalScale
                                thousandSeparator
                              />
                              <span className="text-small">MO</span>
                            </span>
                            <br />
                            <span className="text-uppercase text-tundora text-smaller">Mounthly Payment</span>
                          </div>
                          <div className="flex-fill text-center">
                            <span className="text-charade text-xlarger font-weight-light">{totalPayment}</span>
                            <br />
                            <span className="text-uppercase text-tundora text-smaller">Monthly Payments</span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          {
                            paymentAgreementDoc ? (
                              <a
                                // eslint-disable-next-line react/jsx-no-target-blank
                                target="_blank"
                                href={paymentAgreementDoc.url}
                                className="text-decoration-none"
                              >
                                Payment plan agreement
                              </a>
                            ) : null
                          }
                          <span className="text-smaller text-bombay">*Processing fee: 5% annual, charged monthly</span>
                        </div>
                      </div>
                    </div>
                    <div className="provider-payment-plan__button mx-auto">
                      <Button type="primary" className="btn-block" isSubmit disabled={isSubmitting}>Next Step</Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </ProviderFormWrapper>
    </Layout>
  );
};

export default PatientInfo;
