import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { isEmpty } from 'ramda';

import Button from 'components/Button';

import getErrorMessage from 'utils/getErrorMessage';

const SelectProviderForm = ({
  getProviderPermissions,
  providerPermissions,
  startSpinner,
  stopSpinner,
  typeName,
}) => {
  const history = useHistory();

  const onSubmitSuccess = () => stopSpinner();

  const onSubmitFailure = (error) => {
    const message = getErrorMessage(error);
    stopSpinner();
    toast.error(message);
  };

  const onSubmit = ({ providerId }) => {
    sessionStorage.setItem('PROVIDER_ID', providerId);
    history.go(0);
  };

  const initialValues = {
    providerId: sessionStorage.getItem('PROVIDER_ID'),
  };

  useEffect(() => {
    if (typeName === 'provider_user') {
      startSpinner();

      getProviderPermissions()
        .then(onSubmitSuccess)
        .catch(onSubmitFailure);
    }
  }, [typeName]);

  if (typeName !== 'provider_user' || isEmpty(providerPermissions)) return null;

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isSubmitting, values, dirty }) => {
          const isDisabled = !dirty || isEmpty(values.providerId);

          return (
            <Form className="input-group w-auto d-flex">
              <Field as="select" name="providerId" className="custom-select custom-select-sm provider-header__select">
                {providerPermissions.map((item) => (
                  <option
                    key={item.providerId}
                    value={item.providerId}
                  >
                    {item.provider.providerName}
                  </option>
                ))}
              </Field>
              <div className="input-group-append">
                <Button
                  type="primary"
                  className="btn-sm border-0"
                  disabled={isDisabled || isSubmitting}
                  isSubmit
                >
                  Select
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <span className="text-french-gray mx-3">|</span>
    </>
  );
};

export default SelectProviderForm;
