import { connect } from 'react-redux';

import { getProviderPermissions } from 'actions/user';
import { startSpinner, stopSpinner } from 'actions/spinner';

import SelectProviderForm from './SelectProviderForm';

const mapStateToProps = ({ user: { providerPermissions, typeName } }) => ({ providerPermissions, typeName });

const mapDispatchToProps = { getProviderPermissions, startSpinner, stopSpinner };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectProviderForm);
