import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import LogRocket from 'logrocket';

import store from 'store';

import App from 'components/App';
import IconsLibrary from 'components/Icon/IconsLibrary';
import cleanPIIData from 'utils/cleanPIIData';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/index.scss';

if (['production', 'uat'].includes(process.env.REACT_APP_ENV)) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
    dom: {
      // hide all inputs by default
      inputSanitizer: true,
    },
    // remove all PII info from request/response bodies
    network: {
      requestSanitizer: (request) => {
        try {
          const body = JSON.parse(request.body);
          request.body = cleanPIIData(body);
          // eslint-disable-next-line no-empty
        } catch (e) {}

        return request;
      },
      responseSanitizer: (response) => {
        response.body = cleanPIIData(response.body);
        return response;
      },
    },
  });
}

render(
  <Provider store={store}>
    <IconsLibrary />
    <ToastContainer autoClose={10000} />
    <App />
  </Provider>,
  document.getElementById('root'),
);
