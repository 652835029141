import { connect } from 'react-redux';

import { getOfferData } from 'actions/patients';
import { startSpinner, stopSpinner } from 'actions/spinner';

import ProviderFormWrapper from './ProviderFormWrapper';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getOfferData, startSpinner, stopSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderFormWrapper);
