import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { defaultTo, prop } from 'ramda';

const ENTER_KEYCODE = 13;

const getSuggestionValue = (suggestion) => `${suggestion.firstName} ${suggestion.lastName}`;

const renderSuggestion = (suggestion, { query }) => {
  const suggestionText = `${suggestion.firstName} ${suggestion.lastName}`;
  const matches = AutosuggestHighlightMatch(suggestionText, query);
  const parts = AutosuggestHighlightParse(suggestionText, matches);

  return (
    parts.map((part, index) => {
      // eslint-disable-next-line react/no-array-index-key
      if (prop('highlight')(part)) return <span key={`${part.text}_${index}`} className="font-weight-bold">{prop('text')(part)}</span>;
      return prop('text')(part);
    })
  );
};

const SearchInput = ({
  placeholder, className, id, name, type, value,
  debounceSuggestionsMs,
  suggestionsRequest,
  extractSuggestionsFromResponse,
  shouldRenderSuggestions,
  submitEvent,
  onSuggestionSelected = submitEvent,
  onInputChange,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [currentRequest, setCurrentRequest] = useState(0);

  // const onChange = (event, { newValue }) => setInputValue(newValue);
  const onChange = (event, { newValue }) => {
    setInputValue(newValue);
    onInputChange(newValue);
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const cancelCurrentSuggestionsRequest = () => currentRequest && clearTimeout(currentRequest);

  const onSuggestionsFetchRequested = async ({ value: input }) => {
    cancelCurrentSuggestionsRequest();
    const promiseRequest = setTimeout(async () => {
      const requestedSuggestions = await suggestionsRequest(input);
      const requestedSuggestionsValue = extractSuggestionsFromResponse(requestedSuggestions);
      setSuggestions(requestedSuggestionsValue);
    }, debounceSuggestionsMs);
    setCurrentRequest(promiseRequest);
  };

  const handleSuggestionSelection = (evt, { suggestion, suggestionValue }) => {
    cancelCurrentSuggestionsRequest();
    setInputValue(suggestionValue);
    onSuggestionSelected(suggestionValue, suggestion);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEYCODE) {
      cancelCurrentSuggestionsRequest();
      event.preventDefault();
      submitEvent(inputValue);
    }
  };

  const defaultToEmpty = defaultTo('');
  const realInputValue = defaultToEmpty(inputValue);

  const inputProps = {
    placeholder,
    className,
    value: realInputValue,
    id,
    name,
    type,
    onChange,
    onKeyDown,
  };

  const theme = {
    container: 'react-autosuggest__container',
    containerOpen: 'react-autosuggest__container--open',
    input: 'react-autosuggest__input',
    inputOpen: 'react-autosuggest__input--open',
    inputFocused: 'react-autosuggest__input--focused',
    suggestionsContainer: 'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList: 'react-autosuggest__suggestions-list',
    suggestion: 'react-autosuggest__suggestion suggestion-content text-tuna',
    suggestionFirst: 'react-autosuggest__suggestion--first',
    suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
    sectionContainer: 'react-autosuggest__section-container',
    sectionContainerFirst: 'react-autosuggest__section-container--first',
    sectionTitle: 'react-autosuggest__section-title',
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={handleSuggestionSelection}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      shouldRenderSuggestions={shouldRenderSuggestions}
      inputProps={inputProps}
      theme={theme}
    />
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  debounceSuggestionsMs: PropTypes.number,
  suggestionsRequest: PropTypes.func.isRequired,
  extractSuggestionsFromResponse: PropTypes.func,
  shouldRenderSuggestions: PropTypes.func,
  submitEvent: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
};

SearchInput.defaultProps = {
  debounceSuggestionsMs: 350,
  extractSuggestionsFromResponse: (v) => v,
  shouldRenderSuggestions: (v) => v.trim().length > 2,
  onInputChange: () => {},
};

export default SearchInput;
