import { authenticationService, httpMethods, apiUrls } from 'libs';

const { apiCall } = authenticationService;
const {
  GET, POST, PUT, DOWNLOAD,
} = httpMethods;

const {
  GET_PROVIDER_MESSAGING_USERS,
  GET_PROVIDER_PAYMENTS,
  GET_PROVIDER_PAYMENTS_CSV,
  GET_PROVIDER_UPLOADED_FILES,
  GET_PROVIDER_RELEASE_AUTOMATION_ID,
  GET_PROVIDER_REPORTS_CREDENTIALS,
  GET_PROVIDER_LIST_REPORTS,
  GET_PROVIDER_REPORTS,
  GET_PATIENT_INVITES,
  CREATE_PATIENT_INVITES,
  UPDATE_PATIENT_INVITE,
  EXPIRE_PATIENT_INVITE,
  PROLONG_PATIENT_INVITE,
  GET_PATIENT_INVITES_STATS,
  GET_AUTH_STRATEGIES,
  SEND_MESSAGE_TO_USER,
} = apiUrls;

export const reqGetProviderPayments = ({
  providerId,
  organizationId,
  params,
}) => apiCall(GET, GET_PROVIDER_PAYMENTS(providerId, organizationId), { params });

export const reqDownloadProviderPaymentsCSV = ({
  providerId,
  organizationId,
  params,
}) => apiCall(DOWNLOAD, GET_PROVIDER_PAYMENTS_CSV(providerId, organizationId), { params });

export const reqGetProviderUploadedFiles = ({
  providerId,
  organizationId,
}) => apiCall(GET, GET_PROVIDER_UPLOADED_FILES(providerId, organizationId));

export const reqGetProviderManualReleaseAutomationId = ({ providerId }) => apiCall(GET, GET_PROVIDER_RELEASE_AUTOMATION_ID(providerId));

export const reqGetProviderMessagingUsers = ({ providerId, params }) => apiCall(GET, GET_PROVIDER_MESSAGING_USERS(providerId), { params });

export const reqSendMessageToUser = ({
  providerId,
  acUserId,
}) => apiCall(POST, SEND_MESSAGE_TO_USER(providerId, acUserId));

export const reqGetProviderReportsCredentials = ({
  providerId,
  organizationId,
}) => apiCall(GET, GET_PROVIDER_REPORTS_CREDENTIALS(providerId, organizationId));

export const reqGetProviderListReports = ({
  providerId,
  organizationId,
}) => apiCall(GET, GET_PROVIDER_LIST_REPORTS(providerId, organizationId));

export const reqGetProviderReports = ({
  providerId,
  organizationId,
  params,
}) => apiCall(GET, GET_PROVIDER_REPORTS(providerId, organizationId), { params });

export const reqGetPatientInvites = ({
  providerId,
  params,
}) => apiCall(GET, GET_PATIENT_INVITES(providerId), { params });

export const reqGetAuthStrategies = () => apiCall(GET, GET_AUTH_STRATEGIES);

export const reqCreatePatientInvite = ({
  providerId,
  data,
}) => apiCall(POST, CREATE_PATIENT_INVITES(providerId), { data });

export const reqUpdatePatientInvite = ({
  providerId,
  patientInviteId,
  data,
}) => apiCall(PUT, UPDATE_PATIENT_INVITE(providerId, patientInviteId), { data });

export const reqExpirePatientInvite = ({
  providerId,
  patientInviteId,
  data,
}) => apiCall(POST, EXPIRE_PATIENT_INVITE(providerId, patientInviteId), { data });

export const reqProlongPatientInvite = ({
  providerId,
  patientInviteId,
  data,
}) => apiCall(POST, PROLONG_PATIENT_INVITE(providerId, patientInviteId), { data });

export const reqGetPatientsInvitesStats = ({
  providerId,
}) => apiCall(GET, GET_PATIENT_INVITES_STATS(providerId));
