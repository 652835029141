import React, { useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';

import Icon from 'components/Icon';
import { path } from 'ramda';

const StatusIcon = ({ className, iconName, text }) => (
  <span className={`d-flex align-items-center ${className}`}>
    <Icon name={iconName} className="mr-2" />
    <span>{text}</span>
  </span>
);

export const columns = [{
  Header: 'Date',
  id: 'paymentDate',
  accessor: (row) => row.effectiveDate || row.paymentDate,
}, {
  Header: 'Transaction ID',
  accessor: 'transactionId',
  disableSortBy: true,
}, {
  Header: 'From Account',
  accessor: 'fromBankAccount',
  disableSortBy: true,
}, {
  Header: 'To Account',
  accessor: 'toBankAccount',
  disableSortBy: true,
}, {
  Header: 'No. of Plans',
  accessor: 'receivablesCount',
}, {
  Header: 'Avg Yield',
  accessor: 'avgYield',
  disableSortBy: true,
}, {
  Header: 'Total Funded',
  accessor: 'amount',
}, {
  Header: 'Status',
  accessor: 'status',
  disableSortBy: true,
}];

const columnsFake = [{
  Header: 'Date',
  accessor: 'createdAt',
}, {
  Header: 'Transaction ID',
  accessor: 'transactionId',
}, {
  Header: 'From Account',
  accessor: 'fromAccount',
}, {
  Header: 'To Account',
  accessor: 'toAccount',
}, {
  Header: 'No. of Plans',
  accessor: 'noOfPlans',
}, {
  Header: 'Avg Yield',
  accessor: 'avgYield',
}, {
  Header: 'Total Funded',
  accessor: 'amount',
}, {
  Header: 'Status',
  accessor: 'status',
}];

const Table = ({
  data,
  isDemoUser,
  initialSortBy,
  onSortChange,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable({
    columns: isDemoUser ? columnsFake : columns,
    data,
    initialState: { sortBy: initialSortBy },
  }, useSortBy);

  useEffect(() => {
    onSortChange(sortBy);
  }, [sortBy]);

  const statusIcon = (cell) => {
    switch (cell.value) {
      case 'PAYMENT_CLEARED':
        return <StatusIcon className="text-dodger-blue" iconName="circle-check" text="Paid" />;
      case 'PAYMENT_RETURNED':
        return <StatusIcon className="text-comet" iconName="circle-equis" text="Returned" />;
      case 'PAYMENT_FAILED':
        return <StatusIcon className="text-burnt-sienna" iconName="circle-warning" text="Failed" />;
      case 'PENDING':
      case 'READY_TO_PROCESS':
      case 'PAYMENT_SENT_TO_PROCESSOR':
        return <StatusIcon className="text-jumbo" iconName="circle-pending" text="Pending" />;
      default:
        return null;
    }
  };

  const totalFundedCell = (cell) => {
    const status = path(['row', 'original', 'status'])(cell);
    const value = cell.value / 100;
    switch (status) {
      case 'PAYMENT_CLEARED':
        return (
          <span className="text-dodger-blue">
            <NumberFormat
              value={value}
              decimalScale={2}
              displayType="text"
              prefix="$"
              thousandSeparator
            />
          </span>
        );
      case 'PAYMENT_RETURNED':
        return (
          <span className="text-comet">
            <NumberFormat
              value={value}
              decimalScale={2}
              displayType="text"
              prefix="$"
              thousandSeparator
            />
          </span>
        );
      case 'PAYMENT_FAILED':
        return (
          <span className="text-burnt-sienna">
            <NumberFormat
              value={value}
              decimalScale={2}
              displayType="text"
              prefix="$"
              thousandSeparator
            />
          </span>
        );
      case 'PENDING':
      case 'READY_TO_PROCESS':
      case 'PAYMENT_SENT_TO_PROCESSOR':
        return (
          <span className="text-jumbo">
            <NumberFormat
              value={value}
              decimalScale={2}
              displayType="text"
              prefix="$"
              thousandSeparator
            />
          </span>
        );
      default:
        return null;
    }
  };

  const dateCell = (cell) => format(new Date(cell.value), 'MM/dd/yyyy');

  const fromBankAccountCell = path(['row', 'original', 'fromBankAccount', 'bankName']);

  const toBankAccountCell = path(['row', 'original', 'toBankAccount', 'bankName']);

  const renderCell = (cell) => {
    const id = path(['column', 'id'])(cell);

    switch (id) {
      case 'status': return statusIcon(cell);
      case 'displayAmount': return totalFundedCell(cell);
      case 'amount': return totalFundedCell(cell);
      case 'paymentDate': return dateCell(cell);
      case 'fromBankAccount': return fromBankAccountCell(cell);
      case 'toBankAccount': return toBankAccountCell(cell);
      default: return cell.render('Cell');
    }
  };

  const renderColumnOrderIcon = (column) => {
    if (column.isSorted && column.isSortedDesc) return <Icon name="caret-down" />;
    if (column.isSorted && !column.isSortedDesc) return <Icon name="caret-up" />;
    return null;
  };

  return (
    <div className="table-custom table-responsive table-custom--financials">
      <div className="table-custom__head">
        <table className="table table-striped mb-0" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="text-tuna text-small font-weight-normal"
                  >
                    <span className="align-middle">{column.render('Header')}</span>
                    <span className="text-xsmaller text-commet align-middle ml-2">
                      {renderColumnOrderIcon(column)}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </table>
      </div>
      <div className="table-custom__body">
        <table className="table table-striped mb-0" {...getTableProps()}>
          <tbody {...getTableBodyProps()}>
            {rows.map(
              (row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      const attrs = {
                        ...(cell.column.hide && { 'data-private': true }),
                      };

                      return (
                        <td {...attrs} {...cell.getCellProps()}>
                          {renderCell(cell)}
                        </td>
                      );
                    })}
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
