import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';

import Layout from 'components/Layout';
import { Input } from 'components/Fields';
import Button from 'components/Button';
import Icon from 'components/Icon';

import { createPasswordForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';

const initialValues = {
  password: '',
  rePassword: '',
};

const CreatePassword = ({
  resetPassToken,
  setNewPassword,
  startSpinner,
  stopSpinner,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (!resetPassToken) {
      history.push('/login');
    }
  }, []);

  const onFailure = (error) => {
    const message = getErrorMessage(error);
    stopSpinner();
    toast.error(message);
    history.push('/login');
  };

  const onSuccess = ({ value }) => {
    stopSpinner();
    if (value && value.token) {
      history.push('/');
    }
    if (value && value.twoFactorToken) {
      history.push('/verification-code');
    }
  };

  const onSubmit = (data) => {
    startSpinner();
    setNewPassword({
      newPassword: data.password,
      resetPassToken,
    }).then(onSuccess).catch(onFailure);
  };

  return (
    <Layout noLoggedIn>
      <div className="min-vh-100 d-flex flex-column justify-content-center text-center">
        <div>
          <Icon name="padlock" className="provider-padlock text-dodger-blue mb-5" />
          <h1 className="h4 font-weight-normal letter-spacing mb-4">Set Password</h1>
          <p className="text-tuna letter-spacing mb-5">Please create your new strong password</p>
          <div className="provider-form">
            <Formik
              initialValues={initialValues}
              validationSchema={createPasswordForm}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-5">
                    <Field id="password" type="password" name="password" label="Password" component={Input} />
                    <Field id="rePassword" type="password" name="rePassword" label="Re-Enter Password" component={Input} />
                  </div>
                  <Button type="primary" className="btn-block" isSubmit disabled={isSubmitting}>Continue</Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreatePassword;
