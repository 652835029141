import React from 'react';
import NumberFormat from 'react-number-format';

const NumberInput = ({
  id, label, className, disabled, name, setField, value,
}) => (
  <>
    <NumberFormat
      id={id}
      name={name}
      placeholder={label}
      className={className}
      onValueChange={(values) => {
        const { formattedValue } = values;
        setField(name, formattedValue);
      }}
      disabled={disabled}
      value={value}
    />
    {label && <label htmlFor={id} className="form-control-placeholder">{label}</label>}
  </>
);

export default NumberInput;
