import { connect } from 'react-redux';

import { setNewPassword } from 'actions/user';
import { startSpinner, stopSpinner } from 'actions/spinner';
import CreatePassword from './CreatePassword';

const mapStateToProps = ({
  user: {
    resetPassToken,
  },
}) => ({
  resetPassToken,
});

const mapDispatchToProps = {
  setNewPassword,
  startSpinner,
  stopSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePassword);
