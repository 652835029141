import React from 'react';
import ReactTooltip from 'react-tooltip';

import Icon from 'components/Icon';

const ActionMenu = ({
  patientInviteId,
  status,
  setCurrentPatientInviteId,
  setIsOpenConfirmModal,
  prolongInvite,
  setIsOpen,
  invite,
  setCurrentInvite,
}) => {
  const onExpire = () => {
    setCurrentPatientInviteId(patientInviteId);
    setIsOpenConfirmModal(true);
  };

  const onProlong = () => prolongInvite(patientInviteId)();

  const onEdit = () => {
    setCurrentPatientInviteId(patientInviteId);
    setCurrentInvite(invite);
    setIsOpen(true);
  };

  return (
    <div className="d-inline-block">
      <span data-tip data-for={`actionMenu_${patientInviteId}`} data-event="click">
        <Icon name="dots" className="text-silver clickable" />
      </span>
      <ReactTooltip id={`actionMenu_${patientInviteId}`} effect="solid" type="light" border clickable globalEventOff="click">
        <ul className="list-unstyled mb-0">
          <li className="mb-1">
            <button
              type="button"
              className="border-0 p-0 bg-transparent"
              onClick={onProlong}
            >
              Resend invitation
            </button>

          </li>
          {status === 'SENT_TO_PATIENT' && (
            <li className="mb-1">
              <button
                type="button"
                className="border-0 p-0 bg-transparent"
                onClick={onEdit}
              >
                Edit invitation
              </button>
            </li>
          )}
          <li>
            <button
              type="button"
              className="border-0 p-0 bg-transparent"
              onClick={onExpire}
            >
              Expire invitation
            </button>
          </li>
        </ul>
      </ReactTooltip>
    </div>
  );
};

export default ActionMenu;
