import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * PrivateRoute lets you protect a route, only allowing logged-in users with (optional) specific
 * roles to be able to access it.
 *
 * Example usage:
 *
 * ```jsx
 * <PrivateRoute exact path="/manage" roles={['ADMIN', 'MANAGER']} children={<ManageDashboard />} />
 * ```
 * @param {element} children - the component to render in case user passes the auth checks
 * @param {string[]} [roles=[]] - roles to allow to access the route. empty = all roles are allowed
 * @param {string[]} [denyRoles=[]] - roles to deny access to the route. by default no roles denied
 * @param {string[]} [userTypes=[]] - user types to allow to access the route.
 * @param {string} role - current role of user
 * @param {string} userType - current userType of user
 * @param {boolean} isLoggedIn - flag that says if user is logged in
 * @returns Route thats wraps logic to check user auth and return a redirect or the child component
 */
const PrivateRoute = ({
  children,
  roles = [],
  denyRoles = [],
  userTypes = [],
  role,
  userType,
  isLoggedIn,
  ...rest
}) => {
  const location = useLocation();
  const userRoleDenied = denyRoles.length ? denyRoles.includes(role) : false;
  const userHasRequiredRole = roles.length ? roles.includes(role) : true;
  const userHasRequiredType = userTypes.length ? userTypes.includes(userType) : true;

  return (
    <Route
      {...rest}
      children={(() => {
        if (!isLoggedIn) {
          return (
            <Redirect to={{
              pathname: '/login',
              state: { from: location },
            }}
            />
          );
        }
        if (userRoleDenied || !userHasRequiredRole || !userHasRequiredType) {
          return (<Redirect to={{ pathname: '/forbidden' }} />);
        }

        return children;
      })}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  denyRoles: PropTypes.arrayOf(PropTypes.string),
  userTypes: PropTypes.arrayOf(PropTypes.string),
  role: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

PrivateRoute.defaultProps = {
  denyRoles: [],
  roles: [],
  userTypes: [],
};

export default PrivateRoute;
