import { createReducer } from '@reduxjs/toolkit';

import {
  setToken,
  getToken,
  setRefreshToken,
  getRefreshToken,
} from 'libs/authenticationService';
import { PENDING, FULFILLED, REJECTED } from 'libs/promiseStatuses';

import {
  login,
  loginAdmin,
  setNewPassword,
  exchangeAuthCode,
  verificationCode,
  getUser,
  updatePassword,
  refreshToken,
  logout,
  getOrganizationProviders,
  getProviderPermissions,
} from 'actions/user';

const getLoginActionReducer = (loginAction) => ({
  [String(loginAction.pending)]: (state) => ({
    ...state,
  }),
  [String(loginAction.fulfilled)]: (state, action) => {
    const { token, refreshToken } = action.payload;
    // the case when we do not have MFA for provider users
    // we send valid tokens right after auth code exchange
    if (token) {
      setToken(token);
      setRefreshToken(refreshToken);
    }

    return {
      ...state,
      ...action.payload,
    };
  },
  [String(loginAction.rejected)]: (state) => ({
    ...state,
  }),
});

const initialState = {
  token: getToken() || null,
  refreshToken: getRefreshToken() || null,
  refreshTokenStatus: null,
  email: null,
  providers: null,
  providerPermissions: [],
};

export default createReducer(initialState, {
  ...getLoginActionReducer(login),
  ...getLoginActionReducer(loginAdmin),
  ...getLoginActionReducer(setNewPassword),
  ...getLoginActionReducer(exchangeAuthCode),

  [String(verificationCode.pending)]: (state) => ({
    ...state,
  }),
  [String(verificationCode.fulfilled)]: (state, action) => {
    const { token, refreshToken } = action.payload;
    setToken(token);
    setRefreshToken(refreshToken);

    return {
      ...state,
      ...action.payload,
    };
  },
  [String(verificationCode.rejected)]: (state) => ({
    ...state,
  }),

  [String(getUser.pending)]: (state) => ({
    ...state,
  }),
  [String(getUser.fulfilled)]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [String(getUser.rejected)]: (state) => ({
    ...state,
  }),

  [String(updatePassword.pending)]: (state) => ({
    ...state,
  }),
  [String(updatePassword.fulfilled)]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [String(updatePassword.rejected)]: (state) => ({
    ...state,
  }),

  [String(refreshToken.pending)]: (state) => ({
    refreshTokenStatus: PENDING,
    ...state,
  }),
  [String(refreshToken.fulfilled)]: (state, action) => {
    const { token, refreshToken } = action.payload;
    setToken(token);
    setRefreshToken(refreshToken);

    return {
      refreshTokenStatus: FULFILLED,
      ...state,
      ...action.payload,
    };
  },
  [String(refreshToken.rejected)]: (state) => ({
    refreshTokenStatus: REJECTED,
    ...state,
  }),

  [String(getOrganizationProviders.pending)]: (state) => ({
    ...state,
  }),
  [String(getOrganizationProviders.fulfilled)]: (state, action) => ({
    ...state,
    providers: action.payload.providers,
  }),
  [String(getOrganizationProviders.rejected)]: (state) => ({
    ...state,
  }),

  [String(getProviderPermissions.pending)]: (state) => ({
    ...state,
  }),
  [String(getProviderPermissions.fulfilled)]: (state, action) => ({
    ...state,
    providerPermissions: action.payload,
  }),
  [String(getProviderPermissions.rejected)]: (state) => ({
    ...state,
  }),

  [String(logout)]: () => ({
    token: null,
    refreshToken: null,
  }),
});
