import React from 'react';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import ReactJson from 'react-json-view';

import { StateLessModal } from 'components/MainModal';
import Icon from 'components/Icon';

const PatientInfoModal = ({ isOpen, setIsOpen, vaultPatientResponse }) => (
  <StateLessModal
    isOpen={isOpen}
    contentLabel="Decrypt Modal"
    shouldCloseOnEsc
    shouldCloseOnOverlayClick
    onCloseHandler={() => setIsOpen(false)}
  >
    <div className="decrypt-modal">
      <button type="button" className="main-modal__close" onClick={() => setIsOpen(false)}>
        <Icon name="equis" />
      </button>
      <div className="decrypt-modal__content">
        <Tabs className="tabs d-flex flex-column">
          <TabList className="tabs__tab-list w-auto mb-4">
            {vaultPatientResponse.map((_, index) => (
              <Tab className="tabs__tab" selectedClassName="is-active" key={`patient_${index + 1}`}>
                Patient
                {' '}
                {index + 1}
              </Tab>
            ))}
          </TabList>
          {vaultPatientResponse.map((patient) => (
            <TabPanel className="tabs__tab-panel flex-fill text-left" selectedClassName="is-active">
              <ReactJson
                src={patient}
                name={null}
                enableClipboard={false}
                displayDataTypes={false}
                quotesOnKeys={false}
              />
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </div>
  </StateLessModal>
);

export default PatientInfoModal;
