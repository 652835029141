import { connect } from 'react-redux';
import {
  equals, path,
} from 'ramda';

import {
  getProviderReports,
  downloadProviderPaymentsCSV,
} from 'actions/provider';
import { startSpinner, stopSpinner } from 'actions/spinner';

import getCSVData from 'utils/getCSVData';
import getRole from 'utils/getRole';

import {
  reportCategories,
  reportData,
  reportPatientCount,
  reportTotalAmountEnrolled,
  reportTotalAmountPreFunded,
  dataFinancials,
} from 'helpers/mocks';

import Reports from './Reports';

const mapStateToProps = ({ user: { email }, provider: { reports, csvStatus } }) => {
  const isDemoUser = equals(email, 'demo@provider.com');
  const csvData = getCSVData(dataFinancials);
  const { trend, trendData, trendCategories } = reports;

  const patientCount = isDemoUser ? reportPatientCount : path(['patientCount'])(reports);
  const totalAmountEnrolled = isDemoUser ? reportTotalAmountEnrolled : path(['totalAmountEnrolled'])(reports);
  const totalAmountPreFunded = isDemoUser ? reportTotalAmountPreFunded : path(['totalAmountPreFunded'])(reports);
  const role = getRole();

  return {
    patientCount,
    totalAmountEnrolled,
    totalAmountPreFunded,
    trendCategories: isDemoUser ? reportCategories : trendCategories,
    trendData: isDemoUser ? reportData : trendData,
    trend,
    role,
    email,
    isDemoUser,
    csvStatus,
    csvData,
  };
};

const mapDispatchToProps = {
  getProviderReports,
  downloadProviderPaymentsCSV,
  startSpinner,
  stopSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reports);
