import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { path, isEmpty, prop } from 'ramda';

import Button from 'components/Button';

import { PROVIDER_ID_NAME, apiCall } from 'libs/authenticationService';
import { GET } from 'libs/httpMethods';
import { GET_PROVIDER_WEBSITE } from 'libs/apiUrls';

import getSubdomain from 'utils/getSubdomain';
import getOrganizationId from 'utils/getOrganizationId';
import getErrorMessage from 'utils/getErrorMessage';

// dropdown for showing providers of an organization for a logged-in org user
const SelectOrgProviderForm = ({
  getOrganizationProviders,
  providers,
  startSpinner,
  stopSpinner,
}) => {
  const history = useHistory();
  const subdomain = getSubdomain(window);

  const onSubmitSuccess = async (response) => {
    const currentProviderWebsite = await apiCall(GET, GET_PROVIDER_WEBSITE(subdomain)).then(prop('data'));

    const providerId = prop('providerId')(currentProviderWebsite) || path(['value', 'providers', '0', 'providerId'])(response);

    const currentProvider = sessionStorage.getItem('providerId');

    if (!currentProvider) {
      sessionStorage.setItem(PROVIDER_ID_NAME, providerId);
    }

    stopSpinner();
  };

  const onSubmitFailure = (error) => {
    const message = getErrorMessage(error);
    stopSpinner();
    toast.error(message);
  };

  const onSubmit = ({ providerId }) => {
    sessionStorage.setItem(PROVIDER_ID_NAME, providerId);
    history.go(0);
  };

  const organizationId = getOrganizationId();

  const initialValues = {
    providerId: sessionStorage.getItem(PROVIDER_ID_NAME) || '',
  };

  useEffect(() => {
    if (organizationId) {
      startSpinner();

      getOrganizationProviders(organizationId)
        .then(onSubmitSuccess)
        .catch(onSubmitFailure);
    }
  }, []);

  const blackListedSubdomains = ['magruder'];

  if (blackListedSubdomains.includes(subdomain)) return null;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, values, dirty }) => {
        const isDisabled = !dirty || isEmpty(values.providerId);

        return (
          <Form className="input-group w-auto d-flex">
            <Field as="select" name="providerId" className="custom-select custom-select-sm provider-header__select">
              {providers && providers.map((item) => (
                <option
                  key={item.providerId}
                  value={item.providerId}
                >
                  {item.providerName}
                </option>
              ))}
            </Field>
            <div className="input-group-append">
              <Button
                type="primary"
                className="btn-sm border-0"
                disabled={isDisabled || isSubmitting}
                isSubmit
              >
                Select
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SelectOrgProviderForm;
